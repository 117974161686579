import React, {useEffect, useMemo, useState} from 'react'
import {Address, Client, formatAddress, getAddressLink} from "../../types";
import {deleteClient, getClients} from "../../api/dataService";
import {Navigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import { ColumnDef, Row } from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Button, ButtonGroup, Modal} from "react-bootstrap"
import CreateClient from "../CreateClient";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {checkIsAllowed, isLoadingSpinner} from "../../util/Helpers";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";


const parentStyle = {
    padding: '2rem',
}


function ManageClientsPage() {
    const [clientList, setClientList] = useState<Client[]>([])
    const user = useSelector((state: RootState) => state.user)
    const [isLoading, setIsLoading] = useState(true)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<Client> | null>(null);
    const [clientToUpdate, setClientToUpdate] = useState<Row<Client> | undefined>()
    useEffect(() => {
        Promise.all([getClients()])
            .then(([clientResponse]) => {
                if('redirectTo' in clientResponse){
                    return(<Navigate to={clientResponse.redirectTo}/>)
                }
                if(Array.isArray(clientResponse)){
                    setClientList(clientResponse)
                }
                if(clientList) setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, []);

    const columns = useMemo<ColumnDef<Client, any>[]>(
        () => [
            {
                accessorFn: row => `${row.client_name}`,
                id: 'client_name',
                header: 'Client Name',
                enableColumnFilter: false
            },
            {
                accessorFn: row => `${row.client_po}`,
                id: 'client_po',
                header: 'PO #',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.contact.contact_name,
                id: 'contact_name',
                header: 'Contact Name',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.contact.contact_phone_number,
                id: 'contact_phone',
                header: () => 'Contact Phone',
                enableColumnFilter: false
            },
            {
                id: 'address',
                header: 'Client Address',
                enableColumnFilter: false,
                cell: ({row}) => (
                    <a href={getAddressLink((row.original.address as Address))} rel={"noopener"}>
                        {row.original.address.label? row.original.address.label:(formatAddress(row.original.address as Address))}
                    </a>
                )
            },
            {
                id: 'actions',
                header: () => 'Actions',
                cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                        {checkIsAllowed(user.groups, ['Ownership_Group','Management','Sales'])? (
                            <ButtonGroup className={'btn-group-sm'}>
                                <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}> Update </Button>
                                <Button className={'btn-danger'} onClick={() => handleDeleteRow(row)}> Delete </Button>
                            </ButtonGroup>
                        ):("")}
                    </div>
                )
            }
        ],
        []
    )
    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setClientToUpdate(undefined)
    }
    const handleShowCreate = () => setShowCreateModal(true)
    const handleOpenDetails = (row: Row<Client>) => {
        setClientToUpdate(row)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<Client>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            deleteClient(selectedRowToDelete.original.id.toString()).then(r => {
                if(r && !Array.isArray(r)){
                    if(r.status >= 200 || r.status <= 300){
                        const dataCopy = [...clientList]
                        dataCopy.splice(selectedRowToDelete.index, 1)
                        setClientList(dataCopy)
                    }
                }
            })
            setShowDeleteModal(false);
        }
    };

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setClientList([created_obj,...clientList])
        }
        setShowCreateModal(false)
    }

    const handleUpdate = (updated_obj?: Client) => {
        if(clientToUpdate){
            if(updated_obj){
                const dataCopy = [...clientList]
                dataCopy.splice(clientToUpdate.index, 1)
                setClientList([updated_obj, ...dataCopy])
            }
        }

    }


    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Client Directory"}>
                <SearchableTable
                    columns={columns}
                    data={clientList}
                    globalSearch={true}
                    actionParam={
                        checkIsAllowed(user.groups, ['Ownership_Group','Management','Sales'])?
                            (<Button onClick={handleShowCreate}>Add Client</Button>):undefined
                    }
                />
            </StackedContentContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{clientToUpdate? "Update Client": "Create Client"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateClient
                        closeModal={handleCloseCreate}
                        clientToUpdate={clientToUpdate? clientToUpdate.original : undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageClientsPage