import React from "react";
import {Outlet} from "react-router-dom";
import Navigation from "./components/Navbar";
// Ensures navbar always appears for authenticated users
function App() {
    return (
        <>
            <Navigation />
            <Outlet />
        </>
    );
}
export default App;