import React from "react";
import Container from "react-bootstrap/Container";
import CreateAddress from "../CreateAddress";
import {ParentContainerStyle} from "../../types";


const CreateAddressPage: React.FC = () => {
    return(
        <Container fluid style={ParentContainerStyle}>
            <CreateAddress/>
        </Container>
    )
}


export default CreateAddressPage