import React, {useRef, useState} from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {PartialVehicle, Vehicle} from "../types";
import * as Yup from 'yup'
import { postVehicle, updateVehicle} from "../api/dataService";
import {handlePostError} from "../util/Helpers";
import Container from "react-bootstrap/Container";
import {Alert} from "react-bootstrap";
import {ErrorMessage, Field, Formik} from "formik";
interface CreateVehicleProps {
    vehicleToUpdate? : PartialVehicle
    closeModal?: (values?: PartialVehicle) => void
    onCreate?: (createdObject: PartialVehicle) => void
    onUpdate?: (updatedObject: Vehicle) => void
}

const CreateVehicle: React.FC<CreateVehicleProps> = (props) => {
    const isUpdating = !!props.vehicleToUpdate
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [showSuccess, setShowSuccess] = useState(false)
    const formikRef = useRef<any>(null)
    const validationSchema = Yup.object().shape({
        make: Yup.string().required('Vehicle Make is required'),
        model: Yup.string().required('Vehicle Model is Required'),
    })
    const initialValues = props.vehicleToUpdate || {
        make: '',
        model: '',
        vin: '',
        notes:''
    }


    const handleSubmit = async(values: PartialVehicle) => {
        try {
            let response = undefined
            if(isUpdating){
                response = await updateVehicle(props.vehicleToUpdate?.id? props.vehicleToUpdate.id.toString(): '',values)
            }
            else {
                response = await postVehicle(values)
            }

            if (response && !Array.isArray(response)) {
                setErrorMessages([])
                setShowSuccess(true)
                if (formikRef.current) formikRef.current.resetForm()
                if (props.closeModal) props.closeModal()
                if (props.onCreate) props.onCreate(response.data)
                if (isUpdating && props.onUpdate) props.onUpdate(response)

            }
        }
        catch (error) {
            setErrorMessages(handlePostError(error, 'Vehicle'))
        }
    }

    return (
        <Container>
            <Row className={"justify-content-center"}>
                {errorMessages.length > 0 && (
                    <Alert variant={'danger'}>
                        <ul>
                            {errorMessages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        Vehicle created successfully!
                    </Alert>
                )}
            </Row>
            <Row className={'justify-content-center'}>
                <Formik
                    enableReinitialize={true}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <Form onSubmit={formik.handleSubmit}>
                            <Row className={'my-3'}>
                                <Col>
                                    <Form.Group controlId="make">
                                        <Form.Label className={'fw-bold'}>Vehicle Make</Form.Label>
                                        <Field
                                            as={Form.Control}
                                            name={'make'}
                                            type={'text'}
                                            placeholder={'Toyota'}
                                        />
                                        <ErrorMessage name={'make'} component={'div'} className={'text-danger'}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="model">
                                        <Form.Label className={'fw-bold'}>Vehicle Model</Form.Label>
                                        <Field
                                            as={Form.Control}
                                            name={'model'}
                                            type={'text'}
                                            placeholder={'Corolla'}
                                        />
                                        <ErrorMessage name={'model'} component={'div'} className={'text-danger'}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col>
                                    <Form.Group controlId="vin">
                                        <Form.Label className={'fw-bold'}>VIN</Form.Label>
                                        <Field
                                            as={Form.Control}
                                            name={'vin'}
                                            type={'text'}
                                            placeholder={'Enter VIN...'}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col>
                                    <Form.Group controlId={"notes"}>
                                        <Form.Label className={'fw-bold'}>Vehicle Notes:</Form.Label>
                                        <Field
                                            style={{ height: '100px' }}
                                            name={'notes'}
                                            component={'textarea'}
                                            className={'form-control'}
                                            placeholder={'Add any additional details here...'}
                                            as={Form.Control}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={'mb-3 justify-content-center '} xs={5}>
                                <Button type={"submit"} disabled={formik.isSubmitting}>Submit</Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Row>
        </Container>
    );
}

export default CreateVehicle;