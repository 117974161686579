import React, {useEffect, useState} from 'react';
import {Form, Col, Button, ButtonGroup} from 'react-bootstrap';
import {
    ChargeRateUnitArray,
    ChargeRateUnitType,
    Resource,
    PartialTimeSheet,
    PartialWorkEvent,
    getDefaultBillingRate, PartialEventResourceUse, EventResourceUse
} from "../types";
import Row from "react-bootstrap/Row";
import {getResources, getResourceUses} from "../api/dataService";
import {Navigate} from "react-router-dom";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import {calculateEquipmentUseCost, exposeEquipmentUseCostCalc, roundTo} from "../util/Helpers";
import {isNaN} from "formik";

interface ResourceRowProps {
    index: number;
    onRemove: () => void;
    resourceList: Resource[]
    resource: any
    onChange: (updatedResource: PartialEventResourceUse) => void;
    workEvent: PartialWorkEvent
    isQuoting?:boolean
}

const ResourceRow: React.FC<ResourceRowProps> = ({resourceList, index, onRemove, resource, onChange, workEvent, isQuoting=true}) => {
    const [selectedResource,setSelectedResource] = useState<Resource | undefined>(resource.resource? resource.resource as Resource : undefined)
    const [selectedUnit, setSelectedUnit] = useState<ChargeRateUnitType>(resource.unit? resource.unit:'day')
    const [currentQuantity, setCurrentQuantity] = useState(resource.number_reserved? resource.number_reserved : 0)
    const [durationTime, setDurationTime] = useState<string | number> (resource.duration? resource.duration:0)
    const [unitRate, setUnitRate] = useState<string | number>(resource.rate_override_rate? resource.rate_override_rate: 0)
    const [showCalculations, setShowCalculations] = useState(false)
    const [disableDuration, setDisableDuration] = useState(false)

    useEffect(() => {
        if(isNaN(currentQuantity)){
            resource.number_reserved = 0
        }else{
            resource.number_reserved = currentQuantity;
        }
        resource.resource = selectedResource;
        resource.unit = selectedUnit;
        let enteredRate = parseFloat(unitRate.toString())
        if(isNaN(enteredRate)){
            resource.rate_override_rate = 0
        }else{
            resource.rate_override_rate = enteredRate
        }
        let dur = parseFloat(durationTime.toString())
        if(isNaN(dur)) {
            resource.duration = 0
        } else {
            resource.duration = dur
        }

        onChange(resource)
    }, [currentQuantity, selectedResource, selectedUnit, unitRate, durationTime]);

    useEffect(() => {
        tryUpdateRate()
    }, [selectedResource,selectedUnit]);

    const tryUpdateRate = () => {
        if (selectedResource && selectedUnit) {
            if(resource.do_not_override){
                resource.do_not_override = false
                return
            }
            const retrieved_rate = getDefaultBillingRate(selectedResource, selectedUnit);

            setUnitRate(retrieved_rate ? retrieved_rate : 0)
        }
    };

    return (
        <>
            <Row className="mb-3 align-items-center">
                <Form.Group as={Col} controlId={`resource_id-${index}`}>
                    <Form.Label>Resource</Form.Label>
                    <Select
                        name={`resource[${index}].resource_id`}
                        options={resourceList.map((entry) => ({
                            label: entry.resource_name,
                            value: entry.id,
                        })).sort((a, b) => a.label.localeCompare(b.label))}
                        onChange={(selectedOptions) => {
                            if (selectedOptions) {
                                let selectedOption = resourceList.find(
                                    (resource) => resource.id === selectedOptions.value
                                );
                                setSelectedResource(selectedOption);
                                tryUpdateRate();
                            }
                        }}
                        defaultValue={
                        selectedResource? {
                            label:selectedResource.resource_name,
                            value: selectedResource.id
                            } : undefined
                        }
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} controlId={`amount-${index}`}>
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                        name={`resource[${index}].amount`}
                        type="number"
                        value={currentQuantity}
                        onChange={(e) => {
                            const newValue = parseFloat(e.target.value);
                            if (!isNaN(newValue)) {
                                setCurrentQuantity(newValue);
                            }
                            else {
                                setCurrentQuantity(NaN);
                            }
                        }}
                    />
                </Form.Group>
                {selectedUnit !== 'unit'? (
                    <Form.Group as={Col} controlId={`duration-${index}`} >
                        <Form.Label>Duration</Form.Label>
                        <Form.Control
                            name={`resource[${index}].duration`}
                            type="number"
                            value={durationTime}
                            onChange={(e) => {
                                const newValue = e.target.value;
                                const validValue = /^-?\d*\.?\d*$/.test(newValue) ? newValue : durationTime;
                                setDurationTime(validValue);
                            }}
                        />
                    </Form.Group>
                    ):(<></>)
                }
                <Form.Group as={Col} controlId={`duration-unit-${index}`}>
                    <Form.Label>Rate Unit</Form.Label>
                    <Select
                        name={`resource[${index}].duration-unit`}
                        options={ChargeRateUnitArray.map((entry) => ({
                            label: entry,
                            value: entry,
                        })).sort((a, b) => a.label.localeCompare(b.label))}
                        value={{
                            label: selectedUnit.toString(),
                            value: selectedUnit.toString(),
                        }}
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                setSelectedUnit(selectedOption.value as ChargeRateUnitType);
                                tryUpdateRate();
                            }
                        }}
                    />
                </Form.Group>
                <Form.Group as={Col} controlId={`rate-${index}`} className={isQuoting? 'visible':'invisible'}>
                    <Form.Label>Charge Rate</Form.Label>
                    <Form.Control
                        name={`resource[${index}].rate`}
                        type="number"
                        min={0} // TODO: negative charge rate feature: -Number.MAX_VALUE
                        step={0.01}
                        value={unitRate}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            const validValue = /^-?\d*\.?\d*$/.test(newValue) ? newValue : unitRate;
                            setUnitRate(validValue);
                        }}
                        // defaultValue={resource.rate_override_rate? resource.rate_override_rate : 0}
                    />
                </Form.Group>
            </Row>
            <Row className={`mb-3 align-items-center`}>
                <Col className={isQuoting? 'visible':'invisible'}>
                    <strong>
                        Line Total: ${roundTo(calculateEquipmentUseCost(resource),2)}
                    </strong>

                    <p className={showCalculations? 'visible':'invisible'}>
                        {exposeEquipmentUseCostCalc(resource)}
                    </p>
                </Col>
                <Col xs={"auto"} className={'align-self-end'}>
                    <ButtonGroup>
                        <Button className={isQuoting? 'visible':'invisible'} variant={"secondary"} onClick={ () => setShowCalculations(!showCalculations)}>
                            {!showCalculations? "Show":"Hide"} Calculations
                        </Button>
                        <Button variant="danger" onClick={onRemove}>
                            Remove
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </>
    );
};


interface EventResourcesProps {
    resourceList?: Resource[]
    workevent: PartialWorkEvent // update when done testing
    eventResources?: EventResourceUse[]
    closeModal?: (values? : PartialTimeSheet) => void
    onChange: (updatedResourceData: PartialEventResourceUse[]) => void
    isQuoting?: boolean
}

const AddEventResources: React.FC<EventResourcesProps> = (props) => {
    const initialResource = {
        resource: undefined,
        number_reserved: 0,
        rate_override_rate: 0,
        duration: 0,
        unit: 'day' as ChargeRateUnitType
    }
    const [resourceList, setResourceList] = useState<Resource[]>()
    const [existingResourcesList,setExistingResourcesList] = useState<EventResourceUse[]>(props.eventResources ? props.eventResources : [])
    const [resourceData, setResourceData] = useState<any[]>([])
    const [totalCost, setTotalCost] = useState(0)

    useEffect(() => {
        if(!props.resourceList){
            Promise.all([getResources()])
                .then(([resourceResponse]) => {
                    if ('redirectTo' in resourceResponse) return(<Navigate to={resourceResponse.redirectTo}/>)
                    else (setResourceList(Array.isArray(resourceResponse)? resourceResponse: []))
                })
                .catch(error => {
                    console.error("Error Fetching Data: ", error)
                })
        } else{
            setResourceList(props.resourceList)
        }
    },[props.resourceList])

    useEffect(() => {
        let currentTotal = 0

        resourceData.forEach((resource)=> {
            currentTotal+=calculateEquipmentUseCost(resource)
        })
        setTotalCost(currentTotal)
    }, [resourceData]);

    useEffect(() => {
        if(props.workevent.id){
            Promise.all([getResourceUses(undefined, `?event=${props.workevent.id}`)])
                .then(([usageResponse])=>{
                    if ('redirectTo' in usageResponse) return(<Navigate to={usageResponse.redirectTo}/>)
                    else (setExistingResourcesList(Array.isArray(usageResponse)? usageResponse: []))
                })
        }
    }, [props.workevent.id]);

    useEffect(() => {
        existingResourcesList.forEach((elem) => {
            let mapped_resource = elem.resource
            let mapped_num = elem.number_reserved
            let rate_override = elem.rate_override_rate
            let unit = elem.unit
            let dur = elem.duration
            let do_not_override = true
            const new_obj = {
                    resource: mapped_resource,
                    number_reserved: mapped_num,
                    rate_override_rate:  rate_override,
                    unit: unit,
                    do_not_override: do_not_override,
                    duration: dur
            }
            handleAddExistingResource(new_obj)
        })
    }, [existingResourcesList]);

    const handleAddExistingResource = (existingResource:any) => {
        setResourceData((prevResourceData) => [...prevResourceData, existingResource]);
        props.onChange(resourceData)
    }
    const handleAddResource = () => {
        setResourceData((prevResourceData) => [...prevResourceData, initialResource]);
        props.onChange(resourceData)
    }


    // TODO: Fix bug with list removal.
    const handleRemoveResource = (indexToRemove:number) => {
        const newResourceData = resourceData.filter((_, index) => index !== indexToRemove)
        setResourceData(newResourceData)
        props.onChange(newResourceData)
    }

    return (
        <Container>
            <Row className={`mb-3 align-items-center`}>
                <Col>
                    <Button
                        variant="primary"
                        onClick={handleAddResource}
                        className={'justify-self-start'}
                    >
                        Add Resource
                    </Button>
                </Col>
                <Col xs={"auto"} className={`justify-self-end ${props.isQuoting? 'visible':'invisible'}`}>
                    <strong>Event Total: ${roundTo(totalCost,2)}</strong>
                </Col>
            </Row>
            <hr/>
            {resourceData.map((resource, index) => (
                <ResourceRow
                    resourceList={resourceList? resourceList:[]}
                    key={index} // This needs to be updated
                    index={index}
                    resource={resource}
                    onChange={(updatedResource) => {
                        const updatedResourceData = [...resourceData];
                        updatedResourceData[index] = updatedResource;
                        setResourceData(updatedResourceData)
                        props.onChange(updatedResourceData);
                    }}
                    onRemove={()=>handleRemoveResource(index)}
                    workEvent={props.workevent}
                    isQuoting={props.isQuoting}
                />
            ))}
        </Container>
    );
};

export default AddEventResources;