import React, {useState} from "react";
import {QuoteStatus, WorkOrder, WorkOrderActions, WorkOrderStatus} from "../types";
import {Button, ButtonGroup} from "react-bootstrap";
import {Spinner} from "react-bootstrap";
import {getWorkOrderPDF, postWOAction} from "../api/dataService";
import {Row} from "@tanstack/react-table";

interface WorkOrderActionButtonProps {
    workorder:  Row<WorkOrder>,
    onUpdate?: (updatedObject: WorkOrder, previousObject: Row<WorkOrder>)  => void
}

const WorkOrderActionButtons: React.FC<WorkOrderActionButtonProps> = (props: WorkOrderActionButtonProps) => {

    const [isLoading, setLoading] = useState<boolean>(false)

    const handleWorkOrderAction = async (workorder_id: string, action: WorkOrderActions) => {
        if(action === WorkOrderActions.save) {
            setLoading(true)
            await getWorkOrderPDF(workorder_id)
            setLoading(false)
        } else {
            setLoading(true)
            const response = await postWOAction(workorder_id, action)
            setLoading(false)

            if(props.onUpdate) {
                props.onUpdate(response.workorder, props.workorder)
            }

            if (response && !Array.isArray(response)) {
                setLoading(false)
            }
        }
    }

    let buttonsToRender;

    switch (props.workorder.original.workorder_status) {
        case WorkOrderStatus.open:
            buttonsToRender = (
                <div className="d-flex justify-content-center">
                    <ButtonGroup className="btn-group-sm">
                        <Button onClick={() => handleWorkOrderAction(props.workorder.original.id, WorkOrderActions.activated)} className="btn-success">Activate</Button>
                    </ButtonGroup>
                </div>
            );
            break;

        case WorkOrderStatus.activated:
            buttonsToRender = (
                <div className="d-flex justify-content-center">
                    <ButtonGroup className="btn-group-sm">
                        <Button className="btn-warning" style={{whiteSpace: 'nowrap'}} onClick={() => handleWorkOrderAction(props.workorder.original.id, WorkOrderActions.closed)}> Prep Invoice</Button>
                    </ButtonGroup>
                </div>
            )
            break;

        case WorkOrderStatus.closed:
            buttonsToRender = (
                <div className="d-flex justify-content-center">
                    <ButtonGroup className="btn-group-sm">
                        <Button className="btn-warning" onClick={() => handleWorkOrderAction(props.workorder.original.id,WorkOrderActions.invoice)}>Invoice</Button>
                    </ButtonGroup>
                </div>
            )
            break;

        case WorkOrderStatus.complete:
            buttonsToRender = (            <ButtonGroup className="btn-group-sm">
                <Button style={{whiteSpace: 'nowrap'}} onClick={() => handleWorkOrderAction(props.workorder.original.id,WorkOrderActions.save)}>Download PDF</Button></ButtonGroup>)
            break;
        default:
            buttonsToRender = (
                <div className="d-flex justify-content-center">
                    No Actions
                </div>
            )
    }
    return isLoading ? <center><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></center> : buttonsToRender

    }

export default WorkOrderActionButtons;