import React, {useEffect, useMemo, useState} from 'react'
import {User} from "../../types";
import { deleteUser, getUsers} from "../../api/dataService";
import {Navigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import { ColumnDef, Row } from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Col, Button, Modal, Row as BRow, ButtonGroup} from "react-bootstrap"
import CreateEmployee from "../CreateEmployee";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {checkIsAllowed, isLoadingSpinner} from "../../util/Helpers";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";


const parentStyle = {
    padding: '2rem',
}

function ManageEmployeesPage() {
    const [employeeList, setEmployeeList] = useState<User[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<User> | null>(null);
    const [employeeToUpdate, setEmployeeToUpdate] = useState<Row<User> | undefined>()
    const user = useSelector((state: RootState) => state.user)
    useEffect(() => {
        Promise.all([getUsers(undefined)])
        .then(([userResponse]) => {
            if('redirectTo' in userResponse){
                return(<Navigate to={userResponse.redirectTo}/>)
            }
            if(Array.isArray(userResponse)){
                setEmployeeList(userResponse)
            }
            if(employeeList) setIsLoading(false);
        })
        .catch(error => {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        });
    }, []);

    const columns = useMemo<ColumnDef<User, any>[]>(
        () => [
            {
                accessorFn: row => `${row.first_name} ${row.last_name}`,
                id: 'full_name',
                header:'Employee Name',
                enableColumnFilter: true
            },
            {
                accessorFn: row => row.mobile_number,
                id: 'Mobile_Number',
                header:'Phone',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.email,
                id: 'email',
                header:'Email Address',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.is_active,
                id: 'is_active',
                header:'Active',
                enableColumnFilter: false
            },
            {
                id: 'actions',
                header: () => 'Actions',
                cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                        {(checkIsAllowed(user.groups, ['Ownership_Group', 'Management']))?
                            (<ButtonGroup className="btn-group-sm">
                                <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}>Update</Button>
                                {row.original.is_active?
                                    (<Button className={'btn-danger'} onClick={() => handleDeleteRow(row)}>Delete</Button>):(<></>)
                                }
                            </ButtonGroup>):(<></>)}
                    </div>
                )
            }
        ],
        []
    )
    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setEmployeeToUpdate(undefined)
    }
    const handleShowCreate = () => setShowCreateModal(true)
    const handleOpenDetails = (row: Row<User>) => {
        console.log(row)
        setEmployeeToUpdate(row)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<User>) => {
        // const event = row.original
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            deleteUser(selectedRowToDelete.original.id.toString(), selectedRowToDelete.original)
                .then((r) => {
                    handleUpdate(r)
                })
            setShowDeleteModal(false);
        }
    };

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setEmployeeList([created_obj,...employeeList])
        }
        setShowCreateModal(false)
    }
    const handleUpdate = (updated_obj?: User) => {
        console.log("update")
        if(employeeToUpdate){
            if(updated_obj){
                const dataCopy = [...employeeList]
                dataCopy.splice(employeeToUpdate.index, 1)
                setEmployeeList([updated_obj, ...dataCopy])
                setShowCreateModal(false)
            }
        }
        else if(selectedRowToDelete){
            if(updated_obj){
                const dataCopy = [...employeeList]
                dataCopy.splice(selectedRowToDelete.index, 1)
                setEmployeeList([updated_obj, ...dataCopy])
                setShowCreateModal(false)
            }
        }
    }

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Employee Directory"}>
                <SearchableTable
                    columns={columns}
                    data={employeeList}
                    globalSearch={true}
                    actionParam={
                        checkIsAllowed(user.groups, ['Ownership_Group','Management'])?
                            (<BRow>
                                <Col>
                                    <Button onClick={handleShowCreate}>Add Employee</Button>
                                </Col>
                            </BRow>) : undefined
                    }
                />
            </StackedContentContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{employeeToUpdate? "Update Employee": "Create Employee"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateEmployee
                        closeModal={handleCloseCreate}
                        employeeToUpdate={employeeToUpdate? employeeToUpdate.original : undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageEmployeesPage