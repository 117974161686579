
import React from "react";
import { Outlet } from "react-router-dom";
import AuthNavbar from "./navbar/AuthNavbar";
const Auth = () => {
    return (
        <>
            <AuthNavbar />
            <Outlet />
        </>
    );
}
export default Auth;