import React, {useMemo, useState} from 'react'
import {
    Column,
    ColumnDef, ColumnFiltersState, FilterFn,
    flexRender,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel, getSortedRowModel,
    Table as ReactTable,
    useReactTable
} from "@tanstack/react-table";
import { rankItem } from '@tanstack/match-sorter-utils'
import {Offcanvas, Pagination, Table as BTable} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {exportTableData} from "../util/DataExports";
import Container from "react-bootstrap/Container";
import DatePicker from "react-datepicker";
import FilterCheckboxes from "./FilterCheckboxes";
import Select from "react-select";

interface SearchableTableProps {
    columns: ColumnDef<any,any>[]
    data: any[]
    globalSearch?: boolean
    actionParam?: any
    customFooter?: any
    hideFilters?: boolean
}

const SearchableTable: React.FC<SearchableTableProps> = (props) => {
    const [globalFilter, setGlobalFilter] = useState('')
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [exportAs, setExportAs] = useState<string>('csv')
    // Filter Parameters
    const [statusSet, setStatusSet] = useState<Set<string>>(new Set())
    const [startDate, setStartDate] = useState<Date>()
    const [endDate, setEndDate] = useState<Date>()
    const [showFilters, setShowFilters] = useState(false)
    // const [isActive, setIsActive] = useState(false)
    const globalFilterFn: FilterFn<any> = (row, columnId, value, addMeta) => {
        if (Array.isArray(value)) {
            if (value.length === 0) return true;
            return value.includes(row.getValue(columnId));
        }
        // Rank the item
        const itemRank = rankItem(row.getValue(columnId), value)

        // Store the ranking info
        addMeta(itemRank)

        // Return if the item should be filtered in/out
        return itemRank.passed
    }


    const table = useReactTable({
        data: props.data,
        columns: props.columns,
        filterFns: {
            global: globalFilterFn,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: globalFilterFn,
        enableGlobalFilter: true,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    })

    const handleCloseFilters = () => setShowFilters(false);
    const handleShowFilters = () => setShowFilters(true)

    const resetFilters = () => {
        setGlobalFilter('')
        table.resetColumnFilters()
        statusSet.clear()
        setStartDate(undefined)
        setEndDate(undefined)
    }

    function handleStatusCheck(e: Event, column:any) {
        const target = e.target as HTMLInputElement;
        const checked = target.checked;
        const name = target.name;
        if (checked) {
            statusSet.add(name);
        } else {
            statusSet.delete(name);
        }
        column.setFilterValue(Array.from(statusSet))
    }


    return (
        <div>
            <Row className={'my-3'}>
                <Col>
                    {props.globalSearch? (
                        <DebouncedInput
                            type={'text'}
                            value={globalFilter ?? ''}
                            onChange={value => setGlobalFilter(String(value))}
                            placeholder="Search all columns..."
                            className={'form-control'}
                        />):(<></>
                    )}
                </Col>
                <Col className={"d-flex justify-content-end"}>
                    <div>
                        {props.actionParam}
                    </div>
                    {props.hideFilters?
                        <></> :
                        <Button variant="secondary" onClick={handleShowFilters} className={"mx-3"}>Filters</Button>
                    }

                </Col>
            </Row>

            <BTable striped bordered hover responsive size="md">
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder
                                    ? null : (
                                        <div>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {/*{header.column.getCanFilter() ? (*/}
                                            {/*    <div>*/}
                                            {/*        <Filter column={header.column} table={table}/>*/}
                                            {/*    </div>*/}
                                            {/*): null }*/}
                                        </div>
                                    )
                                }
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody>
                <>
                    {table.getRowModel().rows.length === 0 ? (
                            <tr>
                                <td colSpan={props.columns.length}>
                                    No data...
                                </td>
                            </tr>
                        ) :
                        (<></>)}

                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </>
                </tbody>
                {props.customFooter? props.customFooter : <></>}
            </BTable>
            {!(table.getPageCount() > 1) ? (''):(
                <Row>
                    <Pagination>
                        <Col className={"d-flex align-items-center mx-3"}>
                            <Pagination.First
                                onClick={()=> table.setPageIndex(0)}
                                disabled={!table.getCanPreviousPage()}
                            />
                            <Pagination.Prev
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            />

                            {(() => {
                                let currentPage = table.getState().pagination.pageIndex;
                                let totalPages = table.getPageCount();
                                const paginationNumbers = [];

                                for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                                    if (i < 0 || i >= totalPages) {
                                        continue;
                                    }
                                    paginationNumbers.push(
                                        <Pagination.Item
                                            key={i}
                                            active={i === currentPage}
                                            onClick={() => table.setPageIndex(i)}
                                        >
                                            {i + 1}
                                        </Pagination.Item>
                                    );
                                }

                                return paginationNumbers;
                            })()}


                            <Pagination.Next
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            />
                            <Pagination.Last
                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                disabled={!table.getCanNextPage()}
                            />
                        </Col>
                        <Col className={"d-flex align-items-end flex-row"}>
                            <Container className="d-flex justify-content-end">
                                <span className="mx-3 my-auto">
                                    <strong>Total: {table.getPrePaginationRowModel().rows.length}</strong>
                                </span>

                                <div className="text-center border-right mx-3">
                                    <Form.Select
                                                 value={table.getState().pagination.pageSize}
                                                 onChange={e => {
                                                     table.setPageSize(Number(e.target.value))
                                                 }}
                                    >
                                        {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Container>
                        </Col>
                    </Pagination>
                </Row>
            )}
            <Row>
                <Col className={"d-flex align-items-end flex-column"}>
                    {/*<Form.Select className={"w-auto"}
                                 onChange={e => {
                                     setExportAs(e.target.value)
                                 }}

                    >
                        <option>
                            Select Export Type (Default CSV)
                        </option>
                        <option value={'csv'}>
                            Export CSV
                        </option>
                        <option value={'xlsx'}>
                            Export XLSX
                        </option>
                    </Form.Select>
                    */}
                    <Button
                        onClick={()=> exportTableData(table, 'data', true, exportAs) }
                    >
                        Export Table
                    </Button>

                </Col>
            </Row>
            <Offcanvas show={showFilters} onHide={handleCloseFilters} >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filters</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {table.getFlatHeaders().map((header) => {
                            if (header.column.id.includes('status')) {
                                return (
                                    <details open key={`status_filter_${header.column.id}`}>
                                        <summary className={"fw-bold"}>
                                            {header.column.columnDef.header as string}
                                        </summary>
                                        <FilterCheckboxes table={table} column={header.column} handleCheck={handleStatusCheck}/>
                                    </details>
                                )
                            }
                            else if (header.column.id.includes('start_date')){
                                return (
                                    <div key={"start_date_filter"}>
                                        <details open >
                                            <summary className={"fw-bold"}>
                                                Start Date
                                            </summary>
                                            <div className={'my-3'}>
                                                <DatePicker
                                                    selected={startDate}
                                                    className={'datetime-select form-control'}
                                                    placeholderText={new Date().toDateString()}
                                                    dateFormat={"MMMM d yyyy"}
                                                    onChange={(date: Date) => {
                                                        setStartDate(date)
                                                        header.column.setFilterValue(date)
                                                    }}
                                                />
                                            </div>
                                        </details>
                                    </div>
                                )
                            }
                            else if (header.column.id.includes('end_date')){
                                return (
                                    <div key={"end_date_filter"}>
                                        <details open>
                                            <summary className={"fw-bold"}>
                                                End Date
                                            </summary>
                                            <div className={'my-3'}>
                                                <DatePicker
                                                    selected={endDate}
                                                    className={'datetime-select form-control'}
                                                    placeholderText={new Date().toDateString()}
                                                    dateFormat={"MMMM d yyyy"}
                                                    onChange={(date: Date) => {
                                                        setEndDate(date)
                                                        header.column.setFilterValue(date)
                                                    }}
                                                />
                                            </div>
                                        </details>
                                    </div>
                                )
                            }
                            else if (header.column.getCanFilter()){

                                return(
                                    <div key={`filter_${header.column.id}`}>
                                        <details open>
                                            <summary className={"fw-bold"}>
                                                {header.column.columnDef.header?.toString()}
                                            </summary>
                                            <div className={'my-3'}>
                                                <FilterInput table={table} column={header.column}/>
                                            </div>
                                        </details>
                                    </div>)
                            }

                            else{
                                return null
                            }

                        })
                    }
                    <Button
                        className={'mt-3'}
                        key={"reset"}
                        onClick={()=> {
                            resetFilters()
                            setShowFilters(false)
                        }}
                    >
                        Reset
                    </Button>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
};

function FilterInput({column, table,}: {
    column: Column<any, any>
    table: ReactTable<any>
}) {
    const sortedUniqueValues = useMemo(() => {
        return Array.from(column.getFacetedUniqueValues().keys()).sort()
    }, [column]) // May cause infinite loop


    return (
        <div>
            <Select
                name={column.id}
                options={sortedUniqueValues.map(entry => ({
                    label: entry,
                    value: entry,
                }))}
                value={
                    column.getFilterValue()?
                    {
                        label: column.getFilterValue(),
                        value: column.getFilterValue()
                    } : {
                        label: `Search...(${column.getFacetedUniqueValues().size})`,
                        value: ''
                        }
                }
                placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
                onChange={(selected: any) => {
                    column.setFilterValue(selected.value)
                }}
            />
        </div>
    )
}


function DebouncedInput({
                            value: initialValue,
                            onChange,
                            debounce = 500,
                            ...props
                        }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}


export default SearchableTable;
