import React from "react";
import Container from "react-bootstrap/Container";
import CreateTimeEntry from "../CreateTimeEntry";
import {ParentContainerStyle} from "../../types";


const CreateTimeEntryPage: React.FC = () => {
    return(
        <Container fluid style={ParentContainerStyle}>
            <CreateTimeEntry/>
        </Container>
    )
}


export default CreateTimeEntryPage