import React from "react";
import Container from "react-bootstrap/Container";
import CreateContact from "../CreateContact";
import {ParentContainerStyle} from "../../types";


const CreateContactPage: React.FC = () => {
    return(
        <Container fluid style={ParentContainerStyle}>
            <CreateContact/>
        </Container>
)
}


export default CreateContactPage