import React, {useEffect, useMemo, useState} from 'react'
import { JobType} from "../../types";
import {deleteJobType, getJobTypes} from "../../api/dataService";
import {Navigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import { ColumnDef, Row } from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Button, ButtonGroup, Modal} from "react-bootstrap"
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {isLoadingSpinner} from "../../util/Helpers";
import CreateJobType from "../CreateJobType";


const parentStyle = {
    padding: '2rem',
}


function ManageJobTypesPage() {
    const [jobTypeList, setJobTypeList] = useState<JobType[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<JobType> | null>(null);
    const [jobTypeToUpdate, setJobTypeToUpdate] = useState<Row<JobType> | undefined>()
    useEffect(() => {
        Promise.all([getJobTypes()])
            .then(([jobTypeResponse]) => {
                if('redirectTo' in jobTypeResponse){
                    return(<Navigate to={jobTypeResponse.redirectTo}/>)
                }
                if(Array.isArray(jobTypeResponse)){
                    setJobTypeList(jobTypeResponse)
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, []);

    const columns = useMemo<ColumnDef<JobType, any>[]>(
        () => [
            {
                accessorFn: row => `${row.name}`,
                id: 'jobtype_name',
                header: 'Name',
                enableColumnFilter: true
            },
            {
                id: 'actions',
                header: () => 'Actions',
                cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className={'btn-group-sm'}>
                            <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}> Update </Button>
                            <Button className={'btn-danger'} onClick={() => handleDeleteRow(row)}> Delete </Button>
                        </ButtonGroup>
                    </div>
                )
            }
        ],
        []
    )
    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setJobTypeToUpdate(undefined)
    }
    const handleShowCreate = () => setShowCreateModal(true)
    const handleOpenDetails = (row: Row<JobType>) => {
        setJobTypeToUpdate(row)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<JobType>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            deleteJobType(selectedRowToDelete.original.id.toString()).then(r => {
                if(r && !Array.isArray(r)){
                    if(r.status >= 200 || r.status <= 300){
                        const dataCopy = [...jobTypeList]
                        dataCopy.splice(selectedRowToDelete.index, 1)
                        setJobTypeList(dataCopy)
                    }
                }

            })
            setShowDeleteModal(false);
        }

    }

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setJobTypeList([created_obj,...jobTypeList])
        }
        setShowCreateModal(false)
    }
    const handleUpdate = (updated_obj?: JobType) => {
        if(jobTypeToUpdate){
            if(updated_obj){
                const dataCopy = [...jobTypeList]
                dataCopy.splice(jobTypeToUpdate.index, 1)
                setJobTypeList([updated_obj, ...dataCopy])
            }
        }

    }
    if(isLoading){
        return(isLoadingSpinner())
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Job Type Directory"}>
                <SearchableTable
                    columns={columns}
                    data={jobTypeList}
                    globalSearch={true}
                    actionParam={
                        <Button onClick={handleShowCreate}>Add Job Type</Button>
                    }
                />
            </StackedContentContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{jobTypeToUpdate? "Update Job Type": "Create Job Type"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateJobType
                        closeModal={handleCloseCreate}
                        jobTypeToUpdate={jobTypeToUpdate? jobTypeToUpdate.original : undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageJobTypesPage