// noinspection SpellCheckingInspection

import {ColumnDef, Table} from "@tanstack/react-table";

interface FilterCheckboxProps {
    table: Table<any>
    column: ColumnDef<any, any>
    handleCheck: (e:any, col:any) => void
    // checkedSet: Set<any> TODO: Implement this to fix filter state override on modal close
}

type Facet = {
    name: string,
    value: number
}
type FacetVals = {
    top5: Facet[];
    next20: Facet[];
    hasMore: boolean;
};


// fetches & orders the values for the column based on number of rows containing this value
function getTopFiveFacets(table: Table<any>, columnId?: string): FacetVals {
    if (!columnId) return { top5: [], next20: [], hasMore: false };
    const column = table.getColumn(columnId);
    if (!column) return { top5: [], next20: [], hasMore: false };

    const facets = column.getFacetedUniqueValues();
    const facetsArr = Array.from(facets, ([name, value]) => ({ name, value }));

    const sortedFacets = facetsArr.sort((a, b) => {
        return b.value - a.value;
    });

    const top5 = sortedFacets.slice(0, 5);
    const next20 = sortedFacets.slice(5, 25);
    const hasMore = sortedFacets.length > 25;

    return { top5, next20, hasMore };
}

// returns checkbox filter array
const FilterCheckboxes = (props: FilterCheckboxProps) => {
    let facetVals = getTopFiveFacets(props.table, props.column.id);
    return (
        <div>
            {facetVals.top5.map((top5, index) => (
                <div key={index}>
                    <label className="checkbox my-1">
                        <input type="checkbox"
                               onChange={(e) => props.handleCheck(e, props.column)}
                               name={top5.name}
                        />
                        {top5.name} ({top5.value})
                    </label>
                </div>
            ))}

            {facetVals.next20.length > 0 && (
                <div key={'next-20'}>
                    <details>
                        <summary>More</summary>
                        <div>
                            {facetVals.next20.map((next20, index) => (
                                <div key={index}>
                                    <label className="checkbox">
                                        <input type="checkbox" onChange={(e) => props.handleCheck(e, props.column)} name={next20.name} />
                                        {next20.name} ({next20.value})
                                    </label>
                                </div>
                            ))}
                            {facetVals.hasMore && <span>More values not displayed...</span>}
                        </div>
                    </details>
                </div>
            )}
        </div>
    )
}

export default FilterCheckboxes