import React, {useEffect, useRef, useState} from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {Contact, PartialClient, Address, Client} from "../types";
import * as Yup from 'yup'
import {getAddresses, getContacts, postClient, updateClient} from "../api/dataService";
import {handlePostError, isLoadingSpinner} from "../util/Helpers";
import Container from "react-bootstrap/Container";
import {Alert, Modal} from "react-bootstrap";
import {ErrorMessage, Field, Formik} from "formik";
import {Navigate} from "react-router-dom";
import Select from "react-select";
import CreateAddress from "./CreateAddress";
import CreateContact from "./CreateContact";
interface CreateClientProps {
    clientToUpdate? : PartialClient
    closeModal?: ()=>void
    onCreate?: (createdObject: PartialClient) => void
    onUpdate?: (updatedObject: Client) => void
}

const CreateClient: React.FC<CreateClientProps> = (props) => {
    const isUpdating = !!props.clientToUpdate
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [showSuccess, setShowSuccess] = useState(false)
    const [contactList, setContactList] = useState<Contact[]> ([])
    const [addressList, setAddressList] = useState<Address[]> ([])
    const [showCreateContact, setShowCreateContact] = useState(false)
    const [showCreateAddress, setShowCreateAddress] = useState(false)
    const formikRef = useRef<any>(null)
    const contactSelectRef = useRef<any>(null)
    const addressSelectRef = useRef<any>(null)
    const validationSchema = Yup.object().shape({
        client_name: Yup.string().required('Client Name is required'),
        client_po: Yup.string(),
        contact: Yup.string().required('Client Contact is required'),
        address: Yup.string().required('Address is required'),
    })
    const initialValues = props.clientToUpdate || {
        client_name: '',
        client_po: '',
        contact: undefined,
        address: undefined,
    }

    useEffect(() => {
        Promise.all([getContacts(), getAddresses()])
            .then(([contactsResponse, addressResponse]) => {
                if ('redirectTo' in contactsResponse) return(<Navigate to={contactsResponse.redirectTo}/>)
                else (setContactList(Array.isArray(contactsResponse)? contactsResponse: []))
                if ('redirectTo' in addressResponse) return(<Navigate to={addressResponse.redirectTo}/>)
                else (setAddressList(Array.isArray(addressResponse)? addressResponse: []))

                if(contactList && addressList){
                    setIsLoading( false)
                }
            })
            .catch(error => {
                console.error("Error Fetching Data: ", error)
            })
    },[])

    useEffect(() => {
        if(isUpdating){
            if(formikRef.current && props.clientToUpdate){
                formikRef.current.setFieldValue('contact', props.clientToUpdate.contact?.id)
                formikRef.current.setFieldValue('address', props.clientToUpdate.address?.id)
            }
        }
    }, [isUpdating, props.clientToUpdate]);

    const handleCloseCreateContact = () => {
        setShowCreateContact(false)
    }

    const handleShowCreateAddress = () => {
        setShowCreateContact(false)
        setShowCreateAddress(true)
    }

    const handleShowCreateContact = () => {
        setShowCreateContact(false)
        setShowCreateContact(true)
    }

    const handleCreateAddress = (created_obj? : any) => {
        setShowCreateAddress(false)
        if(created_obj){
            setAddressList([created_obj,...addressList])
            formikRef.current?.setFieldValue('address', created_obj? created_obj.id:'')
            addressSelectRef.current?.setValue({
                label: created_obj.label? (created_obj.label):(created_obj.street),
                value: created_obj.id
            })
        }
    }

    const handleCreateContact = (created_obj? : any) => {
        setShowCreateContact(false)
        if(created_obj){
            setContactList([created_obj, ...contactList])
        }
    }

    const closeCreateAddress = () => {
        setShowCreateAddress(false)
    }

    const closeCreateContact = () => {
        setShowCreateContact(false)
    }

    const handleSubmit = async(values: PartialClient) => {
        try {
            let response = undefined
            if(isUpdating){
                // let data: any = values
                // data.address = (values.address as Address).id
                // data.contact = (values.contact as Contact).id
                response = await updateClient(props.clientToUpdate?.id? props.clientToUpdate.id.toString(): '',values)
            }
            else {
                response = await postClient(values)
            }
            if (response && !Array.isArray(response)) {
                setErrorMessages([])
                setShowSuccess(true)
                if (formikRef.current) formikRef.current.resetForm()
                if (contactSelectRef.current) contactSelectRef.current.clearValue()
                if (addressSelectRef.current) addressSelectRef.current.clearValue()
                if (props.onCreate && !isUpdating) {
                    let dataToReturn = response.data
                    dataToReturn.address = addressList.find(address=> address.id === values.address)
                    dataToReturn.contact = contactList.find(contact => contact.id === values.contact)
                    props.onCreate(dataToReturn)
                }
                if(isUpdating && props.onUpdate) {
                    let dataToReturn = response
                    dataToReturn.address = addressList.find(address=> address.id === values.address)
                    dataToReturn.contact = contactList.find(contact => contact.id === values.contact)
                    props.onUpdate(dataToReturn)

                }
            }
        }
        catch (error) {
            setErrorMessages(handlePostError(error, 'Address'))
        }
    }

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <Container>
            <Row className={"justify-content-center"}>
                {errorMessages.length > 0 && (
                    <Alert variant={'danger'}>
                        <ul>
                            {errorMessages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        Client created successfully!
                    </Alert>
                )}
            </Row>
            <Row className={'justify-content-center'}>
                <Formik
                    enableReinitialize={true}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group as={Row} className="mb-3" controlId="client_name">
                                <Form.Label column className={'fw-bold'} sm={2}>
                                    Client Name
                                </Form.Label>
                                <Col sm={10}>
                                    <Field name={'client_name'} type={'text'} placeholder={"Suncor Energy"} as={Form.Control} />
                                    <ErrorMessage name={'client_name'} component={'div'} className={'text-danger'}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="client_po">
                                <Form.Label column className={'fw-bold'} sm={2}>
                                    Client PO
                                </Form.Label>
                                <Col sm={10}>
                                    <Field name={'client_po'} type={'text'} placeholder={"Client PO"} as={Form.Control} />
                                    <ErrorMessage name={'client_po'} component={'div'} className={'text-danger'}/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="contact">
                                <Form.Label column className={'fw-bold'} xs={2}>
                                    Contact
                                </Form.Label>
                                <Col>
                                    <Select
                                        ref={contactSelectRef}
                                        name="contact"
                                        defaultValue={initialValues.contact? ({
                                            label: initialValues.contact.contact_name,
                                            value: initialValues.contact.id
                                        }):undefined}
                                        options={contactList.map(entry => ({
                                            label: entry.contact_name,
                                            value: entry.id
                                        })).sort((a, b) => a.label.localeCompare(b.label))}
                                        onChange={(selectedOptions) => {
                                            formik.setFieldValue('contact', selectedOptions? selectedOptions.value:'')
                                        }}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <Button onClick={handleShowCreateContact}>Add New Contact</Button>
                                </Col>
                                <ErrorMessage name="contact" component="div" className="text-danger" />
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="address">
                                <Form.Label column className={'fw-bold'} xs={2}>
                                    Address
                                </Form.Label>
                                <Col>
                                    <Select
                                        ref={contactSelectRef}
                                        name="address"
                                        defaultValue={initialValues.address? ({
                                            label: initialValues.address.label? (initialValues.address.label):(initialValues.address.street),
                                            value: initialValues.address.id
                                        }):undefined}
                                        options={addressList.map(entry => ({
                                            label: entry.label? `${entry.label} - ${entry.street}`:entry.street,
                                            value: entry.id
                                        })).sort((a, b) => a.label.localeCompare(b.label))}
                                        onChange={(selectedOptions) => {
                                            formik.setFieldValue('address', selectedOptions? selectedOptions.value:'')
                                        }}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <Button onClick={handleShowCreateAddress}>Add New Address</Button>
                                </Col>
                                <ErrorMessage name="address" component="div" className="text-danger" />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Col>
                                    <Button type="submit" disabled={formik.isSubmitting}>Submit</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Row>

            <Modal show={showCreateContact} onHide={handleCloseCreateContact} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateContact closeModal={closeCreateContact} onCreate={handleCreateContact}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreateContact}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showCreateAddress} onHide={closeCreateAddress} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateAddress closeModal={closeCreateAddress} onCreate={handleCreateAddress}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeCreateAddress}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>

    );
}

export default CreateClient;