import React from "react";
import Container from "react-bootstrap/Container";
import {ParentContainerStyle} from "../../types";
import DispatchCalendar from "../DispatchCalendar";

export const DispatchPage: React.FC = () => {
    return(
        <Container style={ParentContainerStyle}>
            <h2>Dispatch Calendar</h2>
            <DispatchCalendar/>
        </Container>
    )
}