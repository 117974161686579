import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import {
    Quote,
    QuoteStatus,
    TimeSheet,
    WorkEvent,
    WorkOrder,
    WorkOrderStatus
} from "../../types";
import {getEvents, getQuotes, getTimesheets, getUserQuotes, getWorkOrders} from "../../api/dataService";
import {Navigate} from "react-router-dom";
import {Accordion, Badge, Spinner, Stack} from "react-bootstrap";
import AccordionBody from "react-bootstrap/AccordionBody";
import NavigationCard from "../NavigationCard";
import {checkIsAllowed, getStatusCount, isLoadingSpinner, returnEventList} from "../../util/Helpers";
import WeekTimesheets from "../WeekTimesheets";
import {startOfToday, startOfTomorrow} from "date-fns";
import Container from "react-bootstrap/Container";
import {DispatchPage} from "./DispatchPage";


const parentStyle = {
    padding: '2rem',
}



const Home: React.FC = () => {
    const userRoles: string[] = useSelector((state:RootState) => state.user.groups);
    let componentToRender = null
    // Choose which home page to display based on user roles.
    if (checkIsAllowed(userRoles, ['Ownership_Group','Management'])) {
        componentToRender = <Ownership/>
    }
    else if (checkIsAllowed(userRoles, ['Sales'])) {
        componentToRender = <Sales/>
    }
    else if (checkIsAllowed(userRoles, ['Dispatch'])) {
        componentToRender = <DispatchPage/>
    }
    else if (checkIsAllowed(userRoles, ['Office_Admin'])) {
        componentToRender = <OfficeAdmin/>
    }
    else if(checkIsAllowed(userRoles, ['Supervisor'])) {
        componentToRender = <Supervisor/>
    }
    else if(checkIsAllowed(userRoles, ['Driver', 'Pusher'])) {
        componentToRender = <Labourer/>
    }

    if (componentToRender) {
        return <div style={parentStyle}>{componentToRender}</div>;
    } else {
        return <Navigate to={'/auth/login'} />;
    }

}
export default Home;



// Driver, Labourer, Supervisor
const Labourer: React.FC = () => {
    const user = useSelector((state:RootState) => state.user);
    const [timesheetData, setTimesheetData] = useState<TimeSheet[]>([])
    const [eventData, setEventData] = useState<WorkEvent[]>([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        Promise.all([getEvents(undefined, `?employeeuser__username=${user.username}&start_date_after=${startOfToday().toLocaleDateString()}&start_date_before=${startOfTomorrow().toLocaleDateString()}`), getTimesheets()])
            .then(([eventResponse, timeSheetResponse]) => {
                if('redirectTo' in eventResponse){
                    return(<Navigate to={eventResponse.redirectTo}/>)
                }
                if('redirectTo' in timeSheetResponse){
                    return <Navigate to={timeSheetResponse.redirectTo}/>
                }
                if(Array.isArray(eventResponse)){
                    setEventData(eventResponse.filter((entry) => (entry.status === 'in progress'))
                )
                }
                if(Array.isArray(timeSheetResponse)){



                    setTimesheetData(timeSheetResponse)
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, [user]);

    if (isLoading || !eventData || !timesheetData) {
        return (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    }
    return (
        <Accordion defaultActiveKey={"0"}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Today's Tasks</Accordion.Header>
                <AccordionBody>
                    {returnEventList(eventData)}
                </AccordionBody>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Weeks Timesheets</Accordion.Header>
                <Accordion.Body>
                    <WeekTimesheets/>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}


const Ownership: React.FC = () => {
    const [quoteList, setQuoteList] = useState<Quote[]>([])
    const [eventList, setEventList] = useState<WorkEvent[]>([])
    const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        Promise.all([
            getQuotes(undefined),
            getEvents(undefined, `?start_date_after=${startOfToday().toLocaleDateString()}&start_date_before=${startOfTomorrow().toLocaleDateString()}&status=in+progress`),
            getWorkOrders(undefined, `?status=${WorkOrderStatus.open}`)])
            .then(([quoteResponse, eventResponse, workOrderResponse])=> {
                if('redirectTo' in quoteResponse) {return(<Navigate to={quoteResponse.redirectTo}/>)}
                if('redirectTo' in eventResponse) {return(<Navigate to={eventResponse.redirectTo}/>)}
                if('redirectTo' in workOrderResponse) {return(<Navigate to={workOrderResponse.redirectTo}/>)}
                if(Array.isArray(quoteResponse)) {setQuoteList(quoteResponse)}
                if(Array.isArray(eventResponse)) {setEventList(eventResponse)}
                if(Array.isArray(workOrderResponse)) {setWorkOrderList(workOrderResponse)}
                setIsLoading(false)
            })
    }, []);

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return(
        <Accordion defaultActiveKey={'0'}>
            <Accordion.Item eventKey="0">
                <Accordion.Header className="d-flex">
                    <div className={"text-nowrap"}>
                        Quotes For Review
                    </div>
                    <Container className="d-flex justify-content-end">
                        <div className="text-center mx-3" key={'open'}>
                            <Badge bg="primary">{getStatusCount(quoteList, QuoteStatus.opened, 'quote')} Open</Badge>
                        </div>
                        <div className="text-center mx-3" key={'await_internal'}>
                            <Badge bg="success">{getStatusCount(quoteList, QuoteStatus.pushed, 'quote')} Awaiting Internal Approval</Badge>
                        </div>
                        <div className="text-center border-right mx-3" key={'await_external'}>
                            <Badge bg="warning" text="dark">{getStatusCount(quoteList, QuoteStatus.submitted, 'quote')} Awaiting External Approval</Badge>
                        </div>
                    </Container>
                </Accordion.Header>
                <AccordionBody>
                    <Stack gap={3}>
                        {
                            getStatusCount(quoteList, QuoteStatus.pushed, 'quote') === 0 ? (
                                <div>No Quotes Requiring Review.</div>
                            ) : (
                                quoteList.map((entry, index) => (
                                    entry.quote_status === QuoteStatus.pushed ? (
                                        <NavigationCard
                                            title={`${(entry.id)} - ${entry.billing_client}`}
                                            subtitle={`Quote Amount: $${entry.quoted_number}`}
                                            body={
                                                `Notes: ${entry.job_notes? entry.job_notes:'No Notes'}`
                                            }
                                            subtext={`Rev. ${entry.revision_number}`}
                                            link_destination={`/quotes/${entry.id}`}
                                            link_state={entry}
                                            id = {index.toString()}
                                            key = {`quote-${index}`} // Use index as the key if no unique ID is available
                                        />

                                    ) : (
                                        <></>
                                    )

                                ))
                            )}
                    </Stack>
                </AccordionBody>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header className="d-flex">
                    <div className={"text-nowrap"}>
                        Active Work Orders
                    </div>
                    <Container className="d-flex justify-content-end">
                        <div className="text-center mx-3" key={'wo-open'}>
                            <Badge bg="primary">{getStatusCount(workOrderList, WorkOrderStatus.open, 'workorder')} Open</Badge>
                        </div>
                        <div className="text-center mx-3" key={'wo-active'}>
                            <Badge bg="success">{getStatusCount(workOrderList, WorkOrderStatus.activated, 'workorder')} Active</Badge>
                        </div>
                    </Container>
                </Accordion.Header>
                <AccordionBody>
                    <Stack gap={3}>
                        {
                            getStatusCount(workOrderList, WorkOrderStatus.activated, 'workorder') === 0 ? (
                                <div>No Active Work Orders.</div>
                            ) : (
                                workOrderList.map((entry, index) => (
                                    entry.workorder_status === WorkOrderStatus.activated ? (
                                        <NavigationCard
                                            title={`${(entry.id)} - ${entry.billing_client}`}
                                            subtitle={entry.jobsite_client}
                                            body={
                                                `Notes: ${entry.job_notes? entry.job_notes:'No Notes'}`
                                            }
                                            subtext={`Rev. ${entry.revision_number}`}
                                            link_destination={`/workorders/${entry.id}`}
                                            link_state={entry}
                                            id = {index.toString()}
                                            key = {`wo-${index}`}
                                        />

                                    ) : (
                                        <></>
                                    )

                                ))
                            )}
                    </Stack>
                </AccordionBody>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header className={"d-flex"}>
                    <div className={"text-nowrap"}>
                        Events In-Progress
                    </div>
                    <Container className="d-flex justify-content-end">
                        <div className="text-center mx-3" key={'scheduled-event'}>
                            <Badge bg="success">{getStatusCount(eventList, 'in progress', 'event')} Scheduled</Badge>
                        </div>
                    </Container>
                </Accordion.Header>
                <Accordion.Body>
                    {returnEventList(eventList)}
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>Entered Time</Accordion.Header>
                <Accordion.Body>
                    <WeekTimesheets/>
                </Accordion.Body>
            </Accordion.Item>

        </Accordion>
    )
}

const Supervisor: React.FC = () => {
    return (
        <Labourer/>
    )
}

const Sales: React.FC = () => {
    const user = useSelector((state:RootState) => state.user)
    const [savedQuoteList, setSavedQuoteList] = useState<Quote[]>([])
    const [pushedQuoteList, setPushedQuoteList] = useState<Quote[]>([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        Promise.all([
            getQuotes(undefined), getUserQuotes(undefined,`?user=${user.userid}`)])
            .then(([quoteResponse, userQuoteResponse])=> {
                if('redirectTo' in quoteResponse) {return(<Navigate to={quoteResponse.redirectTo}/>)}
                if('redirectTo' in userQuoteResponse) {return(<Navigate to={userQuoteResponse.redirectTo}/>)}
                if(Array.isArray(quoteResponse) && Array.isArray(userQuoteResponse)) {
                    // const filteredArray = array.filter(element => idsToInclude.includes(element.id));
                    setSavedQuoteList(
                        quoteResponse.filter((entry) =>
                            (entry.quote_status === QuoteStatus.opened)
                                && (userQuoteResponse.some(element=> element.quote === entry.id))
                        )
                    )
                    setPushedQuoteList(
                        quoteResponse.filter((entry) =>
                            (entry.quote_status === QuoteStatus.pushed)
                                && (userQuoteResponse.some(element=> element.quote === entry.id))
                        )
                    )
                }
                setIsLoading(false)
            })
    }, []);

    if (isLoading) {
        return (isLoadingSpinner())
    }

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header className="d-flex">
                    <div className={"text-nowrap"}>
                        Your Saved Quotes
                    </div>
                    <Container className="d-flex justify-content-end">
                        <div className="text-center mx-3">
                            <Badge bg="primary">{savedQuoteList.length} Saved Quotes</Badge>
                        </div>
                    </Container>
                </Accordion.Header>
                <AccordionBody>
                    <Stack gap={3}>
                        {
                            savedQuoteList.length === 0 ? (
                                <div>No Saved Quotes.</div>
                            ) : (
                                savedQuoteList.map((entry, index) => (
                                    entry.quote_status === QuoteStatus.opened ? (
                                        <NavigationCard
                                            title={`${(entry.id)} - ${entry.billing_client}`}
                                            subtitle={`Quote Amount: $${entry.quoted_number}`}
                                            body={
                                                `Notes: ${entry.job_notes? entry.job_notes:'No Notes'}`
                                            }
                                            subtext={`Rev. ${entry.revision_number}`}
                                            link_destination={`/quotes/${entry.id}`}
                                            link_state={entry}
                                            id = {index.toString()}
                                            key = {`quote-${index}`} // Use index as the key if no unique ID is available
                                        />
                                    ) : (
                                        <></>
                                    )

                                ))
                            )}
                    </Stack>
                </AccordionBody>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header className="d-flex">
                    <div className={"text-nowrap"}>
                        Your Quotes Awaiting Review
                    </div>
                    <Container className="d-flex justify-content-end">
                        <div className="text-center mx-3">
                            <Badge bg="primary">{pushedQuoteList.length} Quotes Awaiting Review</Badge>
                        </div>
                    </Container>
                </Accordion.Header>
                <AccordionBody>
                    <Stack gap={3}>
                        {
                            pushedQuoteList.length === 0 ? (
                                <div>No Pushed Quotes.</div>
                            ) : (
                                pushedQuoteList.map((entry, index) => (
                                    entry.quote_status === QuoteStatus.pushed ? (
                                        <NavigationCard
                                            title={`${(entry.id)} - ${entry.billing_client}`}
                                            subtitle={`Quote Amount: $${entry.quoted_number}`}
                                            body={
                                                `Notes: ${entry.job_notes? entry.job_notes:'No Notes'}`
                                            }
                                            subtext={`Rev. ${entry.revision_number}`}
                                            link_destination={`/quotes/${entry.id}`}
                                            link_state={entry}
                                            id = {index.toString()}
                                            key = {`quote-${index}`} // Use index as the key if no unique ID is available
                                        />
                                    ) : (
                                        <></>
                                    )

                                ))
                            )}
                    </Stack>
                </AccordionBody>
            </Accordion.Item>
        </Accordion>
    )
}

const OfficeAdmin: React.FC = () => {
    const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        Promise.all([getWorkOrders(undefined,`?workorder_status=${WorkOrderStatus.open}`)])
            .then(([workOrderResponse])=>{
                if('redirectTo' in workOrderResponse) { return(<Navigate to={workOrderResponse.redirectTo}/>)}
                if(Array.isArray(workOrderResponse)) { setWorkOrderList(workOrderResponse) }
                setIsLoading(false)
            })
    }, []);

    if (isLoading) {
        return (isLoadingSpinner())
    }

    return (
        <Accordion defaultActiveKey={'0'}>
            <Accordion.Item eventKey="0">
                <Accordion.Header className="d-flex">
                    <div className={"text-nowrap"}>
                        Open Work Orders
                    </div>
                    <Container className="d-flex justify-content-end">
                        <div className="text-center mx-3">
                            <Badge bg="primary">{} Open Work Orders</Badge>
                        </div>
                    </Container>
                </Accordion.Header>
                <AccordionBody>
                    <Stack gap={3}>
                        {
                            workOrderList.length === 0 ? (
                                <div>No Open Work Orders.</div>
                            ) : (
                                workOrderList.map((entry, index) => (
                                    entry.workorder_status === WorkOrderStatus.open ? (
                                        <NavigationCard
                                            title={`${(entry.id)} - ${entry.billing_client}`}
                                            subtitle={entry.jobsite_client}
                                            body={
                                                `Notes: ${entry.job_notes? entry.job_notes:'No Notes'}`
                                            }
                                            subtext={`Rev. ${entry.revision_number}`}
                                            link_destination={`/workorders/${entry.id}`}
                                            link_state={entry}
                                            id = {index.toString()}
                                            key = {`wo-${index}`}
                                        />

                                    ) : (
                                        <></>
                                    )

                                ))
                            )}
                    </Stack>
                </AccordionBody>
            </Accordion.Item>
        </Accordion>
    )
}
