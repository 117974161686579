import React, {useEffect, useMemo, useState} from 'react'
import {Address, getAddressLink} from "../../types";
import {deleteAddress, getAddresses} from "../../api/dataService";
import {Navigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import {ColumnDef, Row} from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Button, ButtonGroup, Modal} from "react-bootstrap"
import CreateAddress from "../CreateAddress";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {isLoadingSpinner} from "../../util/Helpers";


const parentStyle = {
    padding: '2rem',
}


function ManageAddressesPage() {
    const [addressList, setAddressList] = useState<Address[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<Address> | null>(null);
    const [addressToUpdate, setAddressToUpdate] = useState<Row<Address> | undefined>()
    useEffect(() => {
        Promise.all([getAddresses()])
            .then(([addressResponse]) => {
                if('redirectTo' in addressResponse){
                    return(<Navigate to={addressResponse.redirectTo}/>)
                }
                if(Array.isArray(addressResponse)){
                    setAddressList(addressResponse)
                }
                if(addressList) setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, []);

    const columns = useMemo<ColumnDef<Address, any>[]>(
        () => [
            {
                accessorFn: row => `${row.label}`,
                id: 'address_label',
                header: 'Custom Label',
                enableColumnFilter: true
            },
            {
                accessorFn: row => `${row.street}`,
                id: 'street',
                header: 'Street',
                enableColumnFilter: true
            },
            {
                accessorFn: row => row.city,
                id: 'city',
                header: 'City',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.province,
                id: 'province',
                header: () => 'Province / State',
                enableColumnFilter: false
            },
            {
                id: 'postal_code',
                header: 'Postal/Zip Code',
                enableColumnFilter: false,
                accessorFn: row => row.postal_code
            },
            {
                id: 'country',
                header: 'Country',
                enableColumnFilter: false,
                accessorFn: row => row.country
            },
            {
                id: 'link',
                header: 'Link',
                enableColumnFilter: false,
                cell: ({row}) => (
                    <a href={getAddressLink(row.original)} rel={"noopener"}>Link</a>
                )
            },
            {
                id: 'actions',
                header: () => 'Actions',
                cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className={'btn-group-sm'}>
                            <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}> Update </Button>
                            <Button className={'btn-danger'} onClick={() => handleDeleteRow(row)}> Delete </Button>
                        </ButtonGroup>
                    </div>
                )
            }
        ],
        []
    )
    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setAddressToUpdate(undefined)
    }
    const handleShowCreate = () => setShowCreateModal(true)
    const handleOpenDetails = (row: Row<Address>) => {
        setAddressToUpdate(row)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<Address>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };
    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            console.log("Deleting row:", selectedRowToDelete);
            deleteAddress(selectedRowToDelete.original.id.toString()).then(r =>
                {
                    if(r && !Array.isArray(r)){
                        if(r.status >= 200 || r.status <= 300){
                            const dataCopy = [...addressList]
                            dataCopy.splice(selectedRowToDelete.index, 1)
                            setAddressList(dataCopy)
                        }
                    }

                    console.log('RESULT', r)
                }
            )
            setShowDeleteModal(false);
        }
    };

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setAddressList([created_obj,...addressList])
        }
        setShowCreateModal(false)
    }

    const handleUpdate = (updated_obj?: Address) => {
        if(addressToUpdate){
            if(updated_obj){
                const dataCopy = [...addressList]
                dataCopy.splice(addressToUpdate.index, 1)
                setAddressList([updated_obj, ...dataCopy])
            }
        }

    }

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Address Directory"}>
                <SearchableTable
                    columns={columns}
                    data={addressList}
                    globalSearch={true}
                    actionParam={
                        <Button onClick={handleShowCreate}>Add Address</Button>
                    }
                />
            </StackedContentContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{addressToUpdate? "Update Address": "Create Address"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateAddress
                        closeModal={handleCloseCreate}
                        addressToUpdate={addressToUpdate? addressToUpdate.original : undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageAddressesPage