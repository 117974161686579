import {ActionResponse, QuoteAction, QuoteStatus, UserRoles} from "../types";
import {Button, ButtonGroup} from "react-bootstrap";
import React, {useState} from "react";
import {checkIsAllowed, getQuoteStatusFromAction, handlePostError} from "../util/Helpers";
import {useSelector} from "react-redux";
import {RootState} from "../redux/reduxTypes";
import {getQuotePDF, postQuoteAction} from "../api/dataService";
import {useNavigate} from "react-router-dom";
import {Spinner} from "react-bootstrap";

interface QuoteActionButtonProps {
    quote_id: string,
    quote_status: QuoteStatus,
}
const QuoteActionButtons: React.FC<QuoteActionButtonProps> = (props: QuoteActionButtonProps) => {
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [isLoading, setLoading] = useState<boolean>(false)
    const user = useSelector((state: RootState) => state.user)
    const userGroups = user.groups
    const navigate = useNavigate()


    const handleQuoteAction = async (quote_ID: string, quoteAction: QuoteAction) => {
        try {
            if(quoteAction === QuoteAction.save) {

                await getQuotePDF(quote_ID)

            } else {

                setLoading(true)

                const response = await postQuoteAction(quote_ID,quoteAction)

                let QuoteActionResponse = response as ActionResponse

                if (QuoteActionResponse && QuoteActionResponse.success) {

                    setLoading(false)

                    navigate(`/quotes/${getQuoteStatusFromAction(quoteAction) ? getQuoteStatusFromAction(quoteAction) : QuoteStatus.none}`)

                }
            }
        } catch (error) {
            setErrorMessages(handlePostError(error, 'QuoteAction'))
        }
    }

    let buttonsToRender;
    switch (props.quote_status) {
        case QuoteStatus.pushed:
            if(checkIsAllowed(userGroups,[UserRoles.management, UserRoles.ownership])) {
                buttonsToRender = (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className="btn-group-sm">
                            <Button onClick={() => handleQuoteAction(props.quote_id, QuoteAction.submit)}
                                    className="btn-success"
                                    disabled={isLoading}>Submit</Button>
                        </ButtonGroup>
                        <Button onClick={() => handleQuoteAction(props.quote_id, QuoteAction.reject)} className="btn-sm btn-danger">Reject</Button>

                    </div>
                );
            } else {
                buttonsToRender = (<div className="d-flex justify-content-center">

                        <ButtonGroup className=" btn-group-sm">
                            <Button
                                onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}
                                className="btn-primary"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                Download PDF
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }
            break;
        case QuoteStatus.revision:
            if(checkIsAllowed(userGroups,[UserRoles.sales,UserRoles.management, UserRoles.ownership])){
                buttonsToRender = (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className="btn-group-sm">
                            <Button className="btn-success"
                                    onClick={() => handleQuoteAction(props.quote_id, QuoteAction.submit)}
                                    disabled={isLoading}>Submit
                            </Button>
                        </ButtonGroup>
                        <Button onClick={() => handleQuoteAction(props.quote_id, QuoteAction.reject)} className="btn-sm btn-danger">Reject</Button>

                    </div>
                )
            } else {
                buttonsToRender = (<div className="d-flex justify-content-center">
                        <ButtonGroup className=" btn-group-sm">
                            <Button
                                onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}
                                className="btn-primary"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                Download PDF
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }
            break;

        case QuoteStatus.opened:
            if (checkIsAllowed(userGroups, [UserRoles.sales,UserRoles.management,UserRoles.ownership])) {
                buttonsToRender = (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className="btn-group-sm">
                            <Button className="btn-primary"
                                    onClick={() => handleQuoteAction(props.quote_id,QuoteAction.push)}
                                    disabled={isLoading}>Push</Button>
                        </ButtonGroup>
                    </div>
                )
            } else {
                buttonsToRender = (<div className="d-flex justify-content-center">

                        <ButtonGroup className=" btn-group-sm">
                            <Button
                                onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}
                                className="btn-primary"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                Download PDF
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }
            break;

        case QuoteStatus.submitted:
            if(checkIsAllowed(userGroups,[UserRoles.ownership,UserRoles.management])) {
                buttonsToRender = (
                    <div className="d-flex flex-column justify-content-center">
                        <ButtonGroup className=" btn-group-sm">
                            <Button className="btn-success"
                                    onClick={() => handleQuoteAction(props.quote_id,QuoteAction.accept)}
                                    disabled={isLoading}>Accept</Button>
                            <Button onClick={() => handleQuoteAction(props.quote_id, QuoteAction.reject)}
                                    className="btn-sm btn-danger"
                                    disabled={isLoading}>Reject</Button>
                        </ButtonGroup>
                        <ButtonGroup className="btn-group-sm p-2">
                            <Button
                                onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}
                                className="btn-primary"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                Download PDF
                            </Button>
                        </ButtonGroup>

                    </div>
                )
            } else if (checkIsAllowed(userGroups,[UserRoles.sales])) {
                buttonsToRender = (<div className="d-flex justify-content-center">
                        <ButtonGroup className=" btn-group-sm">
                            <Button onClick={() => handleQuoteAction(props.quote_id, QuoteAction.reject)}
                                    className="btn-sm btn-danger"
                                    disabled={isLoading}>Reject</Button>
                            <Button
                                onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}
                                className="btn-primary"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                Download PDF
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            } else {
                buttonsToRender = (<div className="d-flex justify-content-center">
                    <ButtonGroup className=" btn-group-sm">
                        <Button
                            onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}
                            className="btn-primary"
                            style={{whiteSpace: 'nowrap'}}
                        >
                            Download PDF
                        </Button>
                    </ButtonGroup>
                </div>)
            }
            break;

        case QuoteStatus.accepted:
            if (checkIsAllowed(userGroups, [UserRoles.ownership, UserRoles.management])) {
                buttonsToRender = (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className=" btn-group-sm">
                            <Button
                                onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}
                                className="btn-primary"
                                style={{whiteSpace: 'nowrap'}}
                            >
                                Download PDF
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            } else {
                buttonsToRender = (<div className="d-flex justify-content-center">

                        <ButtonGroup className=" btn-group-sm">
                            <Button
                                onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}
                                className="btn-primary"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                Download PDF
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }

            break;

        case QuoteStatus.rejected:
            if(checkIsAllowed(userGroups,[UserRoles.ownership,UserRoles.management, UserRoles.sales])) {
                buttonsToRender = (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className=" btn-group-sm">
                            {/*TODO: */}
                            <Button
                                onClick={() => handleQuoteAction(props.quote_id, QuoteAction.re_open)}
                                className="btn-warning"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={isLoading}>
                                 Re-Open
                            </Button>
                            <Button
                                onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}

                                className="btn-primary"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                Download PDF
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            } else {
                buttonsToRender = (<div className="d-flex justify-content-center">

                        <ButtonGroup className=" btn-group-sm">
                            <Button
                                onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}
                                className="btn-primary"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                Download PDF
                            </Button>
                        </ButtonGroup>
                    </div>
                )
            }

            break;

        default:
            buttonsToRender = (
                <div className="d-flex justify-content-center">
                    <ButtonGroup className=" btn-group-sm">
                        <Button
                            onClick={() => handleQuoteAction(props.quote_id, QuoteAction.save)}
                            className="btn-primary"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            Download PDF
                        </Button>
                    </ButtonGroup>
                </div>
            )
    }
    return isLoading ? <center><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></center> : buttonsToRender
}


export default QuoteActionButtons;