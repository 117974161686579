// noinspection SpellCheckingInspection

import React, {useEffect, useRef, useState} from "react"
import {
    getEvents,
    getUsers, getUsersEvents,
    getVehicles, postDriverTimesheet,
    postTimesheet,
    updateDriverTimeEntry,
    updateTimeEntry
} from "../api/dataService"
import {
    DriverTimeSheet,
    InitialDriverTimesheetObject,
    InspectionVehicleConditionArray, InspectionVehicleLeakArray,
    PartialDriverTimeSheet,
    PartialTimeSheet,
    PartialUser,
    PartialWorkEvent, UserRoles, Vehicle,
    WorkOrder
} from "../types"
import {checkIsAllowed, handlePostError} from "../util/Helpers"
import {Alert, Button, Col, Form, InputGroup, Row, Tab, Tabs} from "react-bootstrap"
import * as Yup from 'yup'
import {ErrorMessage, Field, Formik} from "formik"
import Container from "react-bootstrap/Container"
import Select from "react-select"
import {format} from "date-fns"
import DatePicker from "react-datepicker"
import {useSelector} from "react-redux"
import {RootState} from "../redux/reduxTypes"
import {Navigate } from "react-router-dom"


interface addTimeProps {
    formik: any,
    eventSelectRef: any
    employeeSelectRef: any
    props: CreateTimeEntryProps
    allowedToUpdate: boolean
    isUpdating: boolean
    employeeList: PartialUser[]
    eventList: PartialWorkEvent[]
    vehicleList: Vehicle[]
    disableSubmit?: boolean
}

const AddTime: React.FC<addTimeProps> = ({
 formik, props, employeeSelectRef, employeeList, eventSelectRef, allowedToUpdate, isUpdating, eventList, disableSubmit=false
}) => {
    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Form.Label className={'fw-bold'}>Employee</Form.Label>
                    <Select
                        ref={employeeSelectRef}
                        name="employee"
                        defaultValue={
                            props.timeEntryToUpdate?.employee? (
                                    {
                                        label: `${props.timeEntryToUpdate.employee.first_name} ${props.timeEntryToUpdate.employee.last_name}`,
                                        value: props.timeEntryToUpdate.employee.id
                                    }
                                ) :
                                (props.selectedEmployee? (
                                    {
                                        label: `${props.selectedEmployee.first_name} ${props.selectedEmployee.last_name}`,
                                        value: props.selectedEmployee.id
                                    }
                                ):undefined)
                        }
                        options={employeeList.map(entry => ({
                            label: `${entry.first_name} ${entry.last_name}`,
                            value: entry.id,
                        })).sort((a, b) => a.label.localeCompare(b.label))}
                        isClearable={false}
                        onChange={(selectedOptions) => {
                            formik.setFieldValue('employee', selectedOptions? selectedOptions.value:'')
                        }}
                        isDisabled={!!(props.timeEntryToUpdate?.employee || (props.selectedEmployee) || (!allowedToUpdate))}
                    />
                    <ErrorMessage name="employee" component="div" className="text-danger" />
                </Col>
                <Col>
                    <Form.Label className={'fw-bold'}>Event</Form.Label>
                    <Select
                        ref={eventSelectRef}
                        name="event"
                        defaultValue={
                            props.timeEntryToUpdate?.event? (
                                    {
                                        label: `${(props.timeEntryToUpdate.event.work_order as WorkOrder)?.billing_client} - ${props.timeEntryToUpdate.event.event_name}`,
                                        value: props.timeEntryToUpdate.event.id
                                    }
                                ) :
                                (props.selectedEvent? (
                                    {
                                        label: `${(props.selectedEvent.work_order as WorkOrder)?.billing_client} - ${props.selectedEvent.event_name}`,
                                        value: props.selectedEvent.id
                                    }
                                ):undefined)
                        }
                        options={eventList.map(entry => ({
                            label: `${(entry.work_order as WorkOrder)?.billing_client} - ${entry.event_name}`,
                            value: entry.id,
                        })).sort((a, b) => a.label.localeCompare(b.label))}
                        isClearable={false}
                        onChange={(selectedOptions) => {
                            formik.setFieldValue('event', selectedOptions? selectedOptions.value:'')
                        }}
                        isDisabled={!!(props.timeEntryToUpdate?.employee || props.selectedEvent)}
                    />
                    <ErrorMessage name="event" component="div" className="text-danger" />
                </Col>
            </Row>
            <Row className="mb-3 justify-content-center text-center">
                <Col xs={5}>
                    <Form.Label className={'fw-bold'}>Start Date / Time: </Form.Label>
                    <DatePicker
                        id="start_date"
                        className={'datetime-select form-control'}
                        dateFormat={"MMMM d, h:mm aa"}
                        showTimeSelect
                        placeholderText={'mm/dd/yyyy hh:mm'}
                        timeIntervals={15}
                        selected={ formik.values.start_date? new Date(formik.values.start_date) : null }
                        onChange={(date: Date) => {
                            if (date) {
                                const dateString = format(date, 'yyyy-MM-dd HH:mm:ss')
                                formik.setFieldValue('start_date', dateString)
                                if(formik.values.end_date && date > new Date(formik.values.end_date)){
                                    formik.setFieldValue('end_date', '')
                                }
                            }
                        }}
                    />
                    <ErrorMessage name="start_date" component="div" className="text-danger" />
                </Col>
                <Col xs={5}>
                    <Form.Label className={'fw-bold'}>End Date / Time: </Form.Label>
                    <DatePicker
                        className={'datetime-select form-control'}
                        wrapperClassName={'w-full'}
                        id="end_date"
                        placeholderText={'mm/dd/yyyy hh:mm'}
                        dateFormat={"MMMM d, h:mm aa"}
                        showTimeSelect
                        timeIntervals={15}
                        selected={ formik.values.end_date? new Date(formik.values.end_date) : null}
                        onChange={(date: Date) => {
                            if (date) {
                                const dateString = format(date, 'yyyy-MM-dd HH:mm:ss')
                                formik.setFieldValue('end_date', dateString)
                            }
                        }}
                        filterTime={(time: Date) => {
                            if(formik.values.start_date){
                                const start = new Date(formik.values.start_date)
                                return time.getTime() > start.getTime()
                            }
                            else {return false}
                        }}
                        filterDate={(date: Date) => {
                            if(formik.values.start_date){
                                const start = new Date(formik.values.start_date)
                                if(date.toLocaleDateString() === start.toLocaleDateString()) return true
                                return date >= start
                            }
                            else {return false}
                        }}
                    />
                    <ErrorMessage name="start_date" component="div" className="text-danger" />
                </Col>
            </Row>
            <Row className={'mb-3'}>
                <Form.Label className={'fw-bold'}>Shift Notes:</Form.Label>
                <Field style={{ height: '100px' }} name={'shift_note'} component={'textarea'} className={'form-control'} placeholder={'Add Notes Here'} as={Form.Control}/>
            </Row>

            {!disableSubmit && (
                <Row className={'justify-content-center'} xs={5}>
                    <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
                        {isUpdating? 'Update':'Submit'}
                    </Button>
                </Row>
            )}

        </>
    )
}

const DriverAddTime: React.FC<addTimeProps> = ({
 formik, props, employeeSelectRef, employeeList, eventSelectRef, allowedToUpdate, isUpdating, eventList, vehicleList
}) => {

    const [inspectionCompletedChecked, setInspectionCompletedChecked] = useState(false)
    const vehicleSelectRef = useRef<any>(null)
    const fluidSelectRef = useRef<any>(null)
    const [componentsRequiringAttention, setComponentsRequiringAttention] = useState([])
    const [toDestHour, setToDestHour] = useState<string>(
        props.driverTimeEntryToUpdate?.drive_time_to_location? props.driverTimeEntryToUpdate.drive_time_to_location.split(":")[0]:'00'
    )
    const [toDestMin, setToDestMin] = useState<string>(
        props.driverTimeEntryToUpdate?.drive_time_to_location? props.driverTimeEntryToUpdate.drive_time_to_location.split(":")[1]:'00'
    )
    const [timeToDestination, setTimeToDestination] = useState<string>(
        `${toDestHour}:${toDestMin}`
    )
    const [fromDestHour, setFromDestHour] = useState(
        props.driverTimeEntryToUpdate?.drive_time_from_location? props.driverTimeEntryToUpdate.drive_time_from_location.split(":")[0]:'00'
    )
    const [fromDestMin, setFromDestMin] = useState(
        props.driverTimeEntryToUpdate?.drive_time_from_location? props.driverTimeEntryToUpdate.drive_time_from_location.split(":")[1]:'00'
    )
    const [timeFromDestination, setTimeFromDestination] = useState<string>(
        `${fromDestHour}:${fromDestMin}`
    )
    const [offTruckHour, setOffTruckHour] = useState(
        props.driverTimeEntryToUpdate?.off_truck_time? props.driverTimeEntryToUpdate.off_truck_time.split(":")[0]:'00'
    )
    const [offTruckMin, setOffTruckMin] = useState(
        props.driverTimeEntryToUpdate?.off_truck_time? props.driverTimeEntryToUpdate.off_truck_time.split(":")[1]:'00'
    )
    const [timeOffTruck, setTimeOffTruck] = useState<string>(
        `${offTruckHour}:${offTruckMin}`
    )
    const [odoReading, setOdoReading] = useState<string>(formik.values.pre_job_inspection_odometer? formik.values.pre_job_inspection_odometer:'')


    const updateAttentionList = (fieldName: boolean, label: string) => {
        setComponentsRequiringAttention((prevList:any) => {
            if(fieldName && label){
                return [...prevList, label]
            }else {
                return prevList.filter((item:any) => item!==label)
            }
        })
    }


    // Update time
    useEffect(() => {
        let newTime = `${toDestHour.padStart(2,'0')}:${toDestMin.padStart(2,'0')}`
        setTimeToDestination(newTime)
        formik.setFieldValue('drive_time_to_location',newTime)
    }, [toDestHour, toDestMin]);

    useEffect(() => {
        let newTime = `${fromDestHour.padStart(2,'0')}:${fromDestMin.padStart(2,'0')}`
        setTimeFromDestination(newTime)
        formik.setFieldValue('drive_time_from_location', newTime)
    }, [fromDestHour, fromDestMin]);

    useEffect(() => {
        let newTime = `${offTruckHour.padStart(2,'0')}:${offTruckMin.padStart(2,'0')}`
        setTimeOffTruck(newTime)
        formik.setFieldValue('off_truck_time', newTime)
    }, [offTruckHour, offTruckMin]);



    return(
        <Tabs defaultActiveKey="time" id="driver-timesheet" className="mb-3" justify>
            <Tab eventKey="time" title="Time & Details">
                <Row>
                    {AddTime({
                        formik: formik,
                        eventSelectRef: eventSelectRef,
                        employeeSelectRef: employeeSelectRef,
                        props: props,
                        allowedToUpdate:allowedToUpdate,
                        isUpdating: isUpdating,
                        employeeList: employeeList,
                        eventList: eventList,
                        disableSubmit: true,
                        vehicleList: []
                    })}
                </Row>
            </Tab>
            <Tab eventKey={"vehicle"} title={"Vehicle & Route Details"}>
                <Row className={"mb-3"}>
                    <Col>
                        <Form.Label className={'mx-4'}>Route Start Date: </Form.Label>
                        <DatePicker
                            id="route_start_date"
                            name={"route_start_date"}
                            className={'datetime-select form-control'}
                            dateFormat={"MMMM d, h:mm aa"}
                            showTimeSelect
                            placeholderText={'mm/dd/yyyy hh:mm'}
                            timeIntervals={15}
                            selected={formik.values.route_start_date ? new Date(formik.values.route_start_date) : null }
                            onChange={(date: Date) => {
                                if (date) {
                                    const dateString = format(date, 'yyyy-MM-dd HH:mm:ss')
                                    formik.setFieldValue('route_start_date', dateString)
                                }
                            }}
                        />
                    </Col>
                    <Form.Group as={Col} controlId="vehicleSelect">
                        <Form.Label>Vehicle</Form.Label>
                        <Select
                            ref={vehicleSelectRef}
                            name="vehicle"
                            defaultValue={
                                props.driverTimeEntryToUpdate?.vehicle? (
                                    {
                                        label: `${props.driverTimeEntryToUpdate.vehicle.make} ${props.driverTimeEntryToUpdate.vehicle.model} - ${props.driverTimeEntryToUpdate.vehicle.vin}`,
                                        value: props.driverTimeEntryToUpdate.vehicle.id
                                    }
                                ) : undefined}
                            options={vehicleList.map(entry => ({
                                label: `${entry.make} ${entry.model} - ${entry.vin}`,
                                value: entry.id,
                            })).sort((a, b) => a.label.localeCompare(b.label))}
                            isClearable={false}
                            onChange={(selectedOptions) => {
                                formik.setFieldValue('vehicle', selectedOptions? selectedOptions.value:'')
                            }}
                            isDisabled={!!(props.driverTimeEntryToUpdate?.vehicle)} // this could be wrong
                        />
                    </Form.Group>
                </Row>
                <Row className={"mb-3"}>

                    <Form.Group as={Col} controlId="pre_job_inspection_odometer">
                        <Form.Label>Odometer Reading</Form.Label>
                        <Field
                            name={'pre_job_inspection_odometer'}
                            placeholder={'200000'}
                            as={Form.Control}
                            value={odoReading}
                            onChange={(e:any) => {
                                const newValue = parseFloat(e.target.value)
                                if(!isNaN(newValue)) {
                                    setOdoReading(newValue.toString())
                                    formik.setFieldValue('pre_job_inspection_odometer', newValue.toString())
                                }
                                else {
                                    setOdoReading('')
                                }
                            }}
                        />
                    </Form.Group>
                </Row>
                <Row className={"mb-3"}>
                    <Row>
                        <Form.Label htmlFor="basic-url">Drive Time To Location</Form.Label>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="number"
                                    min={0}
                                    max={24}
                                    id="to_hours"
                                    aria-describedby="to_hours"
                                    value={toDestHour}
                                    onChange={(e) => {
                                        const newValue = parseFloat(e.target.value)
                                        if(!isNaN(newValue) && newValue <= 24 && newValue >= 0) {
                                            setToDestHour(newValue.toString())
                                        }
                                        else {
                                            setToDestHour('00')
                                        }
                                    }}
                                />
                                <InputGroup.Text id="to_hours">
                                    Hours
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    id="to_minutes"
                                    aria-describedby="to_minutes"
                                    type="number"
                                    min={0}
                                    max={59}
                                    value={toDestMin}
                                    onChange={(e) => {
                                        const newValue = parseFloat(e.target.value)
                                        if(!isNaN(newValue) && newValue <= 59 && newValue >= 0) {
                                            setToDestMin(newValue.toString())
                                        }
                                        else {
                                            setToDestMin('00')
                                        }
                                    }}
                                />
                                <InputGroup.Text id="to_minutes">
                                    Minutes
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                </Row>
                <Row className={"mb-3"}>
                    <Row>
                        <Form.Label htmlFor="basic-url">Drive Time From Location</Form.Label>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    id="from_hours"
                                    aria-describedby="from_hours"
                                    type="number"
                                    min={0}
                                    max={24}
                                    value={fromDestHour}
                                    onChange={(e) => {
                                        const newValue = parseFloat(e.target.value)
                                        if(!isNaN(newValue) && newValue <= 24 && newValue >= 0) {
                                            setFromDestHour(newValue.toString())
                                        }
                                        else {
                                            setFromDestHour('00')
                                        }
                                    }}
                                />
                                <InputGroup.Text id="from_hours">
                                    Hours
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    id="from_minutes"
                                    aria-describedby="from_minutes"
                                    type="number"
                                    min={0}
                                    max={59}
                                    value={fromDestMin}
                                    onChange={(e) => {
                                        const newValue = parseFloat(e.target.value)
                                        if(!isNaN(newValue) && newValue <= 59 && newValue >= 0) {
                                            setFromDestMin(newValue.toString())
                                        }
                                        else {
                                            setFromDestMin('00')
                                        }
                                    }}
                                />
                                <InputGroup.Text id="from_minutes">
                                    Minutes
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                </Row>
                <Row className={"mb-3"}>
                    <Row>
                        <Form.Label htmlFor="basic-url">Time Off Truck</Form.Label>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    id="off_hours"
                                    aria-describedby="off_hours"
                                    type="number"
                                    min={0}
                                    max={24}
                                    value={offTruckHour}
                                    onChange={(e) => {
                                        const newValue = parseFloat(e.target.value)
                                        if(!isNaN(newValue) && newValue <= 59 && newValue >= 0) {
                                            setOffTruckHour(newValue.toString())
                                        }
                                        else {
                                            setOffTruckHour('00')
                                        }
                                    }}
                                />
                                <InputGroup.Text id="off_hours">
                                    Hours
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    id="off_minutes"
                                    aria-describedby="off_minutes"
                                    type="number"
                                    min={0}
                                    max={59}
                                    value={offTruckMin}
                                    onChange={(e) => {
                                        const newValue = parseFloat(e.target.value)
                                        if(!isNaN(newValue) && newValue <= 59 && newValue >= 0) {
                                            setOffTruckMin(newValue.toString())
                                        }
                                        else {
                                            setOffTruckMin('00')
                                        }
                                    }}
                                />
                                <InputGroup.Text id="off_minutes">
                                    Minutes
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>
                </Row>
            </Tab>
            <Tab eventKey="inspection" title="Inspection Details">

                <Row className={"mx-3"}>
                    <Col>
                        <Form.Label className={"fw-bold"}>Fluid Leaks</Form.Label>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_leaks_underneath_vehicle">
                            <Select
                                placeholder={"Fluid Leaks"}
                                ref={fluidSelectRef}
                                name="pre_job_inspection_leaks_underneath_vehicle"
                                defaultValue={
                                    props.driverTimeEntryToUpdate?.pre_job_inspection_leaks_underneath_vehicle? (
                                        {
                                            label: `${props.driverTimeEntryToUpdate.pre_job_inspection_leaks_underneath_vehicle}`,
                                            value: props.driverTimeEntryToUpdate.pre_job_inspection_leaks_underneath_vehicle.toString()
                                        }
                                    ) : {label:'none', value: 'none'}}
                                options={InspectionVehicleLeakArray.map(entry => ({
                                    label: entry,
                                    value: entry,
                                })).sort((a, b) => a.label.localeCompare(b.label))}

                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        formik.setFieldValue('pre_job_inspection_leaks_underneath_vehicle', selectedOption.value)
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <hr/>
                <p className="fw-bold">Fluid Levels</p>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_oil">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_oil"
                                as={Form.Check}
                                label="Oil Needs Attention"
                                onChange={(e: any) => {
                                    formik.setFieldValue('pre_job_inspection_oil', e.target.checked)
                                    updateAttentionList(
                                        e.target.checked,
                                        'Oil Needs Attention'
                                    )
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_antifreeze">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_antifreeze"
                                as={Form.Check}
                                label="Antifreeze Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_antifreeze', e.target.checked)
                                    updateAttentionList(
                                        e.target.checked,
                                        'Antifreeze Needs Attention'
                                    )
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_power_steering">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_power_steering"
                                as={Form.Check}
                                label="Power Steering Fluid Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_power_steering', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Power Steering Fluid Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_brake">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_brake"
                                as={Form.Check}
                                label="Brake Fluid Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_brake', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Brake Fluid Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_windshield_washer">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_windshield_washer"
                                as={Form.Check}
                                label="Washer Fluid Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_windshield_washer', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Washer Fluid Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <hr />
                <p className="fw-bold">Exterior Inspection</p>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_tires_proper_inflation">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_tires_proper_inflation"
                                as={Form.Check}
                                label="Tires Need Inflation"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_tires_proper_inflation', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Tires Need Inflation')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_tires_adaquate_tread">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_tires_adaquate_tread"
                                as={Form.Check}
                                label="Tire Tread Needs Attention"
                                onChange={(e:any) => {
                                    // Backend typo requires fix
                                    formik.setFieldValue('pre_job_inspection_tires_adaquate_tread', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Tire Tread Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_headlights">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_headlights"
                                as={Form.Check}
                                label="Headlights Need Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_headlights', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Headlights Need Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_brakelights">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_brakelights"
                                as={Form.Check}
                                label="Brakelights Need Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_brakelights', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Brakelights Need Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_turnlights">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_turnlights"
                                as={Form.Check}
                                label="Turn Signals Need Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_turnlights', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Turn Signals Need Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_hazzardlights">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_hazzardlights"
                                as={Form.Check}
                                label="Hazard Lights Need Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_hazzardlights', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Hazard Lights Need Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_firstaidkit">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_firstaidkit"
                                as={Form.Check}
                                label="First Aid Kit Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_firstaidkit', e.target.checked)
                                    updateAttentionList(e.target.checked, 'First Aid Kit Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_fireextinguisher">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_fireextinguisher"
                                as={Form.Check}
                                label="Fire Extinguisher Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_fireextinguisher', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Fire Extinguisher Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_reflectors">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_reflectors"
                                as={Form.Check}
                                label="Reflectors Need Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_reflectors', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Reflectors Need Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_reflectivetriangles">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_reflectivetriangles"
                                as={Form.Check}
                                label="Reflective Triangles Missing"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_reflectivetriangles', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Reflective Triangles Missing')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_air_system_inspected_and_bled">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_air_system_inspected_and_bled"
                                as={Form.Check}
                                label="Air System Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_air_system_inspected_and_bled', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Air System Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_frame_and_suspension_system">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_frame_and_suspension_system"
                                as={Form.Check}
                                label="Frame / Suspension Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_frame_and_suspension_system', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Frame / Suspension Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <hr/>
                <p className="fw-bold">Cab & Safety Equipment Inspection</p>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_fuel_system">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_fuel_system"
                                as={Form.Check}
                                label="Fuel System Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_fuel_system', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Fuel System Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_temperature">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_temperature"
                                as={Form.Check}
                                label="Temperature Sensor Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_temperature', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Temperature Sensor Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_seat_belts">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_seat_belts"
                                as={Form.Check}
                                label="Seatbelt Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_seat_belts', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Seatbelt Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_two_way_radio_in_working_order">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_two_way_radio_in_working_order"
                                as={Form.Check}
                                label="Two Way Radio Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_two_way_radio_in_working_order', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Two Way Radio Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_noise">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_noise"
                                as={Form.Check}
                                label="Vehicle Makes Abnormal Noise"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_noise', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Vehicle Makes Abnormal Noise')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_dashboard_warning_light">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_dashboard_warning_light"
                                as={Form.Check}
                                label="Has Dashboard Warning Light"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_dashboard_warning_light', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Has Dashboard Warning Light')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_windshield_wipers">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_windshield_wipers"
                                as={Form.Check}
                                label="Windshield Wipers Need Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_windshield_wipers', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Windshield Wipers Need Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_heater_and_defroster">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_heater_and_defroster"
                                as={Form.Check}
                                label="Heater / Defroster Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_heater_and_defroster', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Heater / Defroster Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_mirrors">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_mirrors"
                                as={Form.Check}
                                label="Mirrors Need Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_mirrors', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Mirrors Need Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_windshield_and_perimeter_windows">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_windshield_and_perimeter_windows"
                                as={Form.Check}
                                label="Windshield / Perimeter Windows Need Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_windshield_and_perimeter_windows', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Windshield / Perimeter Windows Need Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="pre_job_inspection_horn">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_horn"
                                as={Form.Check}
                                label="Horn Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_horn', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Horn Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="pre_job_inspection_exhaust_system">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_exhaust_system"
                                as={Form.Check}
                                label="Exhaust Needs Attention"
                                onChange={(e:any) => {
                                    formik.setFieldValue('pre_job_inspection_exhaust_system', e.target.checked)
                                    updateAttentionList(e.target.checked, 'Exhaust Needs Attention')
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="safety" title="Safety">
                <p className="fw-bold">Safety Checklist</p>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="emergency_contact_uptodate">
                            <Field
                                type="checkbox"
                                name="pre_job_inspection_emergency_contact_info_upto_date"
                                as={Form.Check}
                                label="Emergency Contact Up to Date"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="ppe_worn">
                            <Field
                                type="checkbox"
                                name="pre_job_safety_proper_ppe_worn"
                                as={Form.Check}
                                label="Proper PPE Worn"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="truck_safety_discussed">
                            <Field
                                type="checkbox"
                                name="pre_job_safety_standard_truck_safety_discussed"
                                as={Form.Check}
                                label="Truck Safety Discussed"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="liftgate_operation_discussed">
                            <Field
                                type="checkbox"
                                name="pre_job_safety_standard_liftgate_operation_discussed"
                                as={Form.Check}
                                label="Liftgate Operation Discussed"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="loading_dock_and_building_discussed">
                            <Field
                                type="checkbox"
                                name="pre_job_safety_standard_loading_dock_and_building_discussed"
                                as={Form.Check}
                                label="Loading Dock & Building Safety Discussed"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="equipment">
                            <Field
                                type="checkbox"
                                name="pre_job_safety_standard_equipment_and_secure_strapment"
                                as={Form.Check}
                                label="Equipment & Securement Straps Inspected & Working"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Form.Group controlId="safety_comments">
                        <Row className={'mb-3'}>
                            <Form.Label className={'fw-bold'}>Safety Comments:</Form.Label>
                            <Field
                                style={{ height: '100px' }}
                                name={'pre_job_safety_comments'}
                                component={'textarea'}
                                className={'form-control'}
                                placeholder={'Add any comments regarding safety here...'}
                                as={Form.Control}
                            />
                        </Row>
                    </Form.Group>

                </Row>
            </Tab>
            <Tab eventKey="review" title="Review">
                <Row className="mb-3">
                    <Col>
                        <p>Components Requiring Attention</p>
                        <ul>
                            {
                                componentsRequiringAttention.length <= 0? (<li>No Components Require Attention</li>):(<></>)
                            }
                            {componentsRequiringAttention.map((component, index) => (
                                <li key={index}>{component}</li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Form.Label className={'fw-bold'}>Vehicle Inspection Comments:</Form.Label>
                    <Field
                        style={{ height: '100px' }}
                        name={'pre_job_inspection_comments'}
                        component={'textarea'}
                        className={'form-control'}
                        placeholder={'Add any additional details here'}
                        as={Form.Control}
                    />
                </Row>
                <hr/>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="vehicleSelect">
                        <Form.Label>Vehicle Condition Following Inspection</Form.Label>
                        <Select
                            name={'pre_job_inspection_vehicle_condition_following_inspection'}
                            options={InspectionVehicleConditionArray.map((entry) => ({
                                label: entry,
                                value: entry,
                            })).sort((a, b) => a.label.localeCompare(b.label))}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    formik.setFieldValue('pre_job_inspection_vehicle_condition_following_inspection', selectedOption.value)
                                }
                            }}
                            defaultValue={
                                props.driverTimeEntryToUpdate?.pre_job_inspection_vehicle_condition_following_inspection? (
                                    {
                                        label: `${props.driverTimeEntryToUpdate.pre_job_inspection_vehicle_condition_following_inspection}`,
                                        value: props.driverTimeEntryToUpdate.pre_job_inspection_vehicle_condition_following_inspection.toString()
                                    }
                                ) : undefined}
                        />
                    </Form.Group>
                </Row>
                <hr/>
                <Row className="mb-3">
                    <Form.Group controlId="information_is_true">
                        <Form.Check
                            type="checkbox"
                            label="I Declare that the vehicle shown above has been inspected in accordance with the applicable requirement of schedule 1 and 2 or jurisdiction legislation."
                            onChange={()=> setInspectionCompletedChecked(!inspectionCompletedChecked)}
                        />
                    </Form.Group>
                </Row>
                <Row className={'justify-content-center mb-3'} xs={5}>
                    <Button variant="primary" type="submit" disabled={!inspectionCompletedChecked || formik.isSubmitting}>
                        Submit
                    </Button>
                </Row>
            </Tab>
        </Tabs>
    )
}

interface CreateTimeEntryProps {
    selectedEmployee? : PartialUser
    selectedEvent? : PartialWorkEvent
    timeEntryToUpdate? : PartialTimeSheet
    driverTimeEntryToUpdate? : PartialDriverTimeSheet
    closeModal?: (values? : PartialTimeSheet) => void
    onCreate?: (createdObject: PartialTimeSheet) => void
    onUpdate?: (updatedObject: any) => void
    renderDriver?:boolean
}

const CreateTimeEntry: React.FC<CreateTimeEntryProps> = (props) => {
    const user = useSelector((state: RootState) => state.user)
    const allowedToUpdate = (checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management, UserRoles.officeAdmin]))
    const renderDriver = (props.renderDriver || props.driverTimeEntryToUpdate || (user.groups as string[]).includes('Driver'))
    const isUpdating = !!(props.timeEntryToUpdate || props.driverTimeEntryToUpdate)
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [showSuccess, setShowSuccess] = useState(false)
    const formikRef = useRef<any>(null)
    const employeeSelectRef = useRef<any> (null)
    const eventSelectRef = useRef<any> (null)
    const [employeeList, setEmployeeList] = useState<PartialUser[]>([])
    const [eventList, setEventList] = useState<PartialWorkEvent[]>([])
    const [vehicleList, setVehicleList] = useState<Vehicle[]>([])
    const validationSchema =
        renderDriver? Yup.object().shape({
            start_date: Yup.date().required('Start Date is required.'),
            end_date: Yup.date().required('End Date is required.'),
            employee: Yup.string().required('A time entry must be associated with an employee.'),
            event: Yup.string().required('A time entry must be associated with an event'),
            route_start_date: Yup.date().required('Route Start Date is required.'),
            pre_job_inspection_odometer: Yup.number().required('Odometer Reading Is Required')
        }) :
        Yup.object().shape({
            start_date: Yup.date().required('Start Date is required.'),
            end_date: Yup.date().required('End Date is required.'),
            employee: Yup.string().required('A time entry must be associated with an employee.'),
            event: Yup.string().required('A time entry must be associated with an event'),
        })
    const initialValues= props.driverTimeEntryToUpdate || props.timeEntryToUpdate || (
        renderDriver? InitialDriverTimesheetObject : {
            shift_note: '',
            start_date:'',
            end_date: '',
            employee: props.selectedEmployee? props.selectedEmployee: undefined,
            event: props.selectedEvent? props.selectedEvent: undefined
        }
    )

    useEffect(() => {
        if(allowedToUpdate){
            Promise.all([
                getUsers(undefined,'?is_active=true'),
                getEvents(undefined, '?workorder_status=open')
            ]).then(
                ([userResponse, eventResponse]) => {
                    if ('redirectTo' in userResponse) return(<Navigate to={userResponse.redirectTo}/>)
                    else (setEmployeeList(Array.isArray(userResponse)? userResponse: []))
                    if ('redirectTo' in eventResponse) return(<Navigate to={eventResponse.redirectTo}/>)
                    else (setEventList(Array.isArray(eventResponse)? eventResponse: []))
                })
                .catch(error => {
                    console.error("Error Fetching Data: ", error)
                })
        }
        else{
            if(user && user.userid)
            Promise.all([getUsers(user.userid), getEvents(), getUsersEvents(`?user=${user.userid}`)])
                .then(([activeUserResponse, eventResponse, userEventResponse])=> {
                    if('redirectTo' in activeUserResponse) return (<Navigate to={activeUserResponse.redirectTo}/>)
                    if('redirectTo' in eventResponse) return (<Navigate to={eventResponse.redirectTo}/>)
                    if('redirectTo' in userEventResponse) return (<Navigate to={userEventResponse.redirectTo}/>)
                    else {
                        if(!Array.isArray(activeUserResponse)){
                            setEmployeeList([activeUserResponse])

                            if(employeeSelectRef.current) employeeSelectRef.current.setValue({
                                label: `${activeUserResponse.first_name} ${activeUserResponse.last_name}`,
                                value: activeUserResponse.id
                            })
                            if(Array.isArray(eventResponse) && Array.isArray(userEventResponse)){
                                setEventList(eventResponse.filter((eventItem) => userEventResponse.some((elem) => (elem.event === eventItem.id && eventItem.status==='in progress'))))
                            }

                        }
                    }

                        // setActiveUser(!Array.isArray(activeUserResponse)? activeUserResponse: undefined)
                })
        }
    }, [allowedToUpdate, user])
    useEffect(() => {
        if(renderDriver){
            Promise.all([getVehicles()])
                .then(([vehicleResponse])=> {
                    if('redirectTo' in vehicleResponse) return (<Navigate to={vehicleResponse.redirectTo}/>)
                    else setVehicleList(Array.isArray(vehicleResponse)? vehicleResponse:[])
                })
        }
    }, [renderDriver])
    useEffect(() => {
        if(props.selectedEmployee){
            formikRef.current.setFieldValue('employee', props.selectedEmployee.id)
        }
        if(props.selectedEvent){
            formikRef.current.setFieldValue('event', props.selectedEvent.id)
        }
    }, [props.selectedEmployee, props.selectedEvent]);
    useEffect(() => {
        if(isUpdating){
            formikRef.current.setFieldValue('employee', props.timeEntryToUpdate?.employee?.id)
            formikRef.current.setFieldValue('event', props.timeEntryToUpdate?.event?.id)
        }

    }, [isUpdating,props.timeEntryToUpdate?.event, props.timeEntryToUpdate?.employee]);


    const handleSubmit = async(values: PartialTimeSheet | PartialDriverTimeSheet) => {
        try {
            let response = undefined
            // values.start_date = getCorrectedDate(values.start_date? values.start_date:'').toString()
            // values.end_date = getCorrectedDate(values.end_date? values.end_date:'').toString()


            if(isUpdating){
                let data: any = values
                // data.event = values.event?.id
                // data.employee = values.employee?.id
                if(renderDriver) {
                    data.vehicle = (values as DriverTimeSheet).vehicle.id
                }

                response = renderDriver?
                    await updateDriverTimeEntry(props.driverTimeEntryToUpdate?.id? props.driverTimeEntryToUpdate.id.toString(): '',data) :
                    await updateTimeEntry(props.timeEntryToUpdate?.id? props.timeEntryToUpdate.id.toString(): '',data)
            }
            else {
                let data: any = values
                response = renderDriver?
                    await postDriverTimesheet(data) :
                    await postTimesheet(data)
            }
            if (response && !Array.isArray(response)) {
                setErrorMessages([])
                setShowSuccess(true)
                if (formikRef.current) formikRef.current.resetForm()
                // if (employeeSelectRef.current) employeeSelectRef.current.clearValue()
                if (props.closeModal) props.closeModal()
                if (!isUpdating && props.onCreate) {
                    let dataToReturn = response.data
                    dataToReturn.employee = employeeList.find(employee => employee.id === values.employee)
                    dataToReturn.event = eventList.find(event => event.id === values.event)
                    if(renderDriver){
                        dataToReturn.vehicle = vehicleList.find(vehicle => vehicle.id === dataToReturn.vehicle)
                    }
                    props.onCreate(dataToReturn)
                }
                if(isUpdating && props.onUpdate){
                    let dataToReturn = response
                    dataToReturn.employee = employeeList.find(employee => employee.id === values.employee)
                    dataToReturn.event = eventList.find(event => event.id === values.event)
                    if(renderDriver){
                        dataToReturn.vehicle = vehicleList.find(vehicle => vehicle.id === dataToReturn.vehicle)
                    }
                    console.log("data to return,",dataToReturn)
                    props.onUpdate(dataToReturn)
                }
            }
        }
        catch (error) {
            setErrorMessages(handlePostError(error, 'Address'))
        }
    }


    return (
        <Container>
            <Row>
                {errorMessages.length > 0 && (
                    <Alert variant="danger">
                        <ul>
                            {errorMessages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        Time Entry Successfully Added!
                    </Alert>
                )}
            </Row>
            <Row>
                <Formik
                    enableReinitialize={true}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                
                >
                    {formik => (
                        <Form onSubmit={formik.handleSubmit}>
                            {renderDriver?
                                (DriverAddTime({
                                    formik: formik,
                                    eventSelectRef: eventSelectRef,
                                    employeeSelectRef: employeeSelectRef,
                                    props: props,
                                    allowedToUpdate:allowedToUpdate,
                                    isUpdating: isUpdating,
                                    employeeList: employeeList,
                                    eventList: eventList,
                                    vehicleList: vehicleList
                                })) :
                                (AddTime({
                                    formik: formik,
                                    eventSelectRef: eventSelectRef,
                                    employeeSelectRef: employeeSelectRef,
                                    props: props,
                                    allowedToUpdate:allowedToUpdate,
                                    isUpdating: isUpdating,
                                    employeeList: employeeList,
                                    eventList: eventList,
                                    vehicleList: []
                                }))
                            }
                        </Form>
                    )}
                </Formik>

            </Row>
        </Container>
    )
}


export default CreateTimeEntry
