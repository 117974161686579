import React, {useRef, useState} from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {Contact, PartialContact} from "../types";
import * as Yup from 'yup'
import {postContact, updateContact} from "../api/dataService";
import {handlePostError} from "../util/Helpers";
import Container from "react-bootstrap/Container";
import {Alert} from "react-bootstrap";
import {ErrorMessage, Field, Formik} from "formik";
interface CreateContactProps {
    contactToUpdate? : PartialContact
    closeModal?: (values?: PartialContact) => void
    onCreate?: (createdObject: PartialContact) => void
    onUpdate?: (updatedObject: Contact) => void
}

const CreateContact: React.FC<CreateContactProps> = (props) => {
    const isUpdating = !!props.contactToUpdate
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [showSuccess, setShowSuccess] = useState(false)
    const formikRef = useRef<any>(null)
    const validationSchema = Yup.object().shape({
        contact_name: Yup.string().required('Contact Name is required'),
        contact_phone_number: Yup.string().matches(/^(?:\+\d{1,3})?(?:[-.()\s]*\d{1,}){8,}$/, 'Invalid mobile number format').required('Mobile Number is required'),
        contact_phone_extension: Yup.string(),
        contact_email: Yup.string().email('Please enter a valid email'),
    })
    const initialValues = props.contactToUpdate || {
        contact_name: '',
        contact_phone_number: '',
        contact_phone_extension: '',
        contact_email: '',
    }


    const handleSubmit = async(values: PartialContact) => {
        try {
            let response = undefined
            if(isUpdating){
                response = await updateContact(props.contactToUpdate?.id? props.contactToUpdate.id.toString(): '',values)
            }
            else {
                response = await postContact(values)
            }
            if (response && !Array.isArray(response)) {
                setErrorMessages([])
                setShowSuccess(true)
                if (formikRef.current) formikRef.current.resetForm()
                if (props.closeModal) props.closeModal()
                if (props.onCreate) props.onCreate(response.data)
                if (isUpdating && props.onUpdate) props.onUpdate(response)

            }
        }
        catch (error) {
            setErrorMessages(handlePostError(error, 'Address'))
        }
    }

    return (
        <Container>
            <Row className={"justify-content-center"}>
                {errorMessages.length > 0 && (
                    <Alert variant={'danger'}>
                        <ul>
                            {errorMessages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        Contact created successfully!
                    </Alert>
                )}
            </Row>
            <Row className={'justify-content-center'}>
                <Formik
                    enableReinitialize={true}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group className="mb-3" controlId="contact_name">
                                <Form.Label className={'fw-bold'}>Contact Name</Form.Label>
                                <Field name={'contact_name'} type={'text'} placeholder={''} as={Form.Control}/>
                                <ErrorMessage name={'contact_name'} component={'div'} className={'text-danger'}/>
                            </Form.Group>
                            <Row className="mb-3">
                                <Form.Group as={Col} xs={8} controlId="contact_email">
                                    <Form.Label className={'fw-bold'}>Email</Form.Label>
                                    <Field
                                        name={'contact_email'}
                                        type={'email'}
                                        placeholder={''}
                                        as={Form.Control}
                                    />
                                    <ErrorMessage name={'contact_email'} component={'div'} className={'text-danger'}/>
                                </Form.Group>


                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="contact_phone_number">
                                    <Form.Label className={'fw-bold'}>Phone</Form.Label>
                                    <Field name={'contact_phone_number'} type={'text'} placeholder={'XXX-XXX-XXXX'} as={Form.Control}/>
                                    <ErrorMessage name={'contact_phone_number'} component={'div'} className={'text-danger'}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="contact_phone_extension">
                                    <Form.Label className={'fw-bold'}>Extension</Form.Label>
                                    <Field name={'contact_phone_extension'} type={'text'} placeholder={''} as={Form.Control}/>
                                    <ErrorMessage name={'contact_phone_extension'} component={'div'} className={'text-danger'}/>
                                </Form.Group>
                            </Row>
                            <Row className={'justify-content-center'} xs={5}>
                                <Button disabled={formik.isSubmitting} type={"submit"}>Submit</Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Row>
        </Container>
    );
}

export default CreateContact;