import React, {useEffect, useMemo, useState} from 'react'
import {TimeSheet, UserRoles, WorkEvent, WorkOrder} from "../../types";
import {deleteTimeSheet, getTimesheets, getWorkOrders} from "../../api/dataService";
import {Navigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import {ColumnDef, Row} from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {checkIsAllowed, getDateTimeString, getElapsedHours, isLoadingSpinner} from "../../util/Helpers";
import {Button, ButtonGroup, Modal} from "react-bootstrap"
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import CreateTimeEntry from "../CreateTimeEntry";
import {endDateFilter, startDateFilter} from "../../util/CustomFilters";
import ExportTimecard from "../ExportTimecard";


const parentStyle = {
    padding: '2rem',
}


function ManageTimeEntriesPage() {
    const [timesheetList, setTimesheetList] = useState<TimeSheet[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([])
    const [showExportModal, setShowExportModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToUpdate, setSelectedRowToUpdate] = useState<Row<TimeSheet> | null>(null)
    const [selectedRowToDelete, setSelectedRowToDelete] =  useState<Row<TimeSheet> | null>(null)
    const user = useSelector((state: RootState) => state.user)
    const filters = checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management,
        UserRoles.officeAdmin, UserRoles.driver, UserRoles.supervisor, UserRoles.sales, UserRoles.dispatch]) ? '':`?employee=${user.userid}`


    useEffect(() => {
        Promise.all([getTimesheets(undefined, filters), getWorkOrders()])
            .then(([timesheetResponse, workOrderResponse]) => {
                if('redirectTo' in timesheetResponse){
                    return(<Navigate to={timesheetResponse.redirectTo}/>)
                }
                if(Array.isArray(timesheetResponse)){
                    setTimesheetList(timesheetResponse)
                }
                if('redirectTo' in workOrderResponse){
                    return(<Navigate to={workOrderResponse.redirectTo}/>)
                }
                if(Array.isArray(workOrderResponse)){
                    setWorkOrderList(workOrderResponse)
                }
                if(timesheetList){
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const columns = useMemo<ColumnDef<TimeSheet, any>[]>(
        () => [
            {
                accessorFn: row => `${row.employee.first_name} ${row.employee.last_name}`,
                id: 'full_name',
                header: 'Employee Name',
                enableColumnFilter: true
            },
            {
                cell: ({row}) => <a href={`/events/${row.original.event.id}`}>{row.original.event.event_name}</a>,
                accessorFn: row => row.event.event_name,
                id: 'event_name',
                header: 'Event Name',
                enableColumnFilter: false
            },
            {
                accessorFn: row => (row.event as WorkEvent).status,
                id: 'event_status',
                header: 'Event Status',
                enableColumnFilter: true,
                filterFn: "arrIncludesSome"
            },
            {
                accessorFn: row => (workOrderList.find((workOrder) => workOrder.id === (row.event as WorkEvent).work_order))?.billing_client,
                id: 'client_name',
                header: 'Client Name',
                enableColumnFilter: true,
            },
            {
                cell: ({row}) => {
                    if((row.original.event as WorkEvent).work_order) {
                        let wo_id = (row.original.event as WorkEvent).work_order
                        return <div className="text-center">
                            <a href={`/workorders/${(row.original.event as WorkEvent).work_order}`}>WO #{ wo_id ? wo_id.toString(): "N/A" }</a>
                        </div>
                    }
                    else{
                        return (<p className={'text-center'}>N/A</p>)
                    }
                },
                accessorFn: row => row.event.work_order? row.event.work_order : 'N/A',
                id: 'resource_workorder',
                header: 'Work Order',
                enableColumnFilter: true
            },
            {
                accessorFn: row => `${getDateTimeString(row.start_date)}`,
                id: 'start_date',
                header: 'Clock In',
                enableColumnFilter: true,
                filterFn:startDateFilter
            },
            {
                accessorFn: row => `${getDateTimeString(row.end_date)}`,
                id: 'end_date',
                header: 'Clock Out',
                enableColumnFilter: true,
                filterFn:endDateFilter
            },
            {
                accessorFn: row => `${getElapsedHours(row.start_date,row.end_date)}`,
                id: 'total_hours',
                header: 'Worked Hours',
                enableColumnFilter: false
            },
            {
                accessorFn: row => `${row.status}`,
                id: 'time_status',
                header: 'Time Entry Status',
                enableColumnFilter: true,
                filterFn: "arrIncludesSome"
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                        {(
                            (row.original.status !== 'closed' && row.original.employee.id === user.userid)
                            || checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management, UserRoles.officeAdmin]))?
                            (<ButtonGroup className={'btn-group-sm'}>
                                <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}>View</Button>
                                <Button className={'btn-danger'} onClick={() => handleDeleteRow(row)}>Delete</Button>
                            </ButtonGroup>):(<></>)}
                    </div>
                )
            }
        ],
        [workOrderList]
    )


    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setSelectedRowToDelete(null)
        setSelectedRowToUpdate(null)
    }


    const handleShowCreate = () => setShowCreateModal(true)
    const handleOpenDetails = (row: Row<TimeSheet>) => {
        setSelectedRowToUpdate(row)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<TimeSheet>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            // Perform the deletion logic here
            deleteTimeSheet(selectedRowToDelete.original.id.toString()).then(r => {
                if(r && !Array.isArray(r)){
                    if(r.status >= 200 || r.status <= 300){
                        const dataCopy = [...timesheetList]
                        dataCopy.splice(selectedRowToDelete.index, 1)
                        setTimesheetList(dataCopy)
                    }
                }
            })
            // Close the delete modal
            setShowDeleteModal(false);
        }
    };

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setTimesheetList([created_obj,...timesheetList])
        }
        setShowCreateModal(false)
    }

    const handleUpdate = (updated_obj?: TimeSheet) => {
        if(selectedRowToUpdate){
            if(updated_obj){
                const dataCopy = [...timesheetList]
                dataCopy.splice(selectedRowToUpdate.index, 1)
                setTimesheetList([updated_obj, ...dataCopy])
            }
        }

    }

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Timesheet Directory"}>
                <SearchableTable
                    columns={columns}
                    data={timesheetList}
                    globalSearch={true}
                    actionParam={
                        checkIsAllowed(user.groups, ['Ownership_Group', 'Management', 'Office_Admin'])? // are these groups correct?
                        (
                            <ButtonGroup>
                                <Button variant={'success'} onClick={()=> {setShowExportModal(true)}}>Export Timesheet</Button>
                                <Button onClick={handleShowCreate}>Manual Time Entry</Button>
                            </ButtonGroup>
                        ):undefined
                    }

                />
            </StackedContentContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateTimeEntry
                        timeEntryToUpdate={selectedRowToUpdate? selectedRowToUpdate.original : undefined}
                        closeModal={handleCloseCreate}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showExportModal} onHide={() => {setShowExportModal(false)}} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Export Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ExportTimecard endpoint={'generaltimesheet-csv'} workorderList={workOrderList}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setShowExportModal(false)}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageTimeEntriesPage