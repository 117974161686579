import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../redux/reduxTypes";
import {Address, DriverTimeSheet, formatAddress, getAddressLink, TimeSheet, WorkEvent, WorkOrder} from "../types";
import {getEvents, getTimesheets} from "../api/dataService";
import {Navigate} from "react-router-dom";
import {Accordion, Alert, Button, Modal, Stack} from "react-bootstrap";
import AccordionBody from "react-bootstrap/AccordionBody";
import WeekTimesheets from "./WeekTimesheets";
import {getDateTimeString, getTimeString, isLoadingSpinner} from "../util/Helpers";
import CreateTimeEntry from "./CreateTimeEntry";
import NavigationCard from "./NavigationCard";

const EventAndTimeList: React.FC = () => {
    const user = useSelector((state:RootState) => state.user);
    const [timesheetData, setTimesheetData] = useState<TimeSheet[] | DriverTimeSheet[]>([])
    const [selectedEvent,setSelectedEvent] = useState<WorkEvent>()
    const [showModal, setShowModal] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [eventData, setEventData] = useState<WorkEvent[]>([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        Promise.all([getEvents(undefined, `?employeeuser__username=${user.username}&status=in+progress`),
            getTimesheets(undefined, `?employee=${user.userid}`)])
            .then(([eventResponse, timeSheetResponse]) => {
                if('redirectTo' in eventResponse) return(<Navigate to={eventResponse.redirectTo}/>)
                if('redirectTo' in timeSheetResponse) return <Navigate to={timeSheetResponse.redirectTo}/>
                if(Array.isArray(eventResponse)){
                    setEventData(eventResponse.filter((entry) => (entry.status === 'in progress')))
                }
                if(Array.isArray(timeSheetResponse)){
                    setTimesheetData(timeSheetResponse)
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, [user]);

    const handleCloseModal = () => {
        setShowModal(false)
        setSelectedEvent(undefined)
    }

    const handleOpenDetails = (work_event: any) => {
        setSelectedEvent(work_event)
        setShowModal(true)
    }
    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setShowSuccess(true)
        }
        // Show success
        setShowModal(false)
    }


    if (isLoading || !eventData || !timesheetData) {
        return (
            isLoadingSpinner()
        );
    }
    return (
        <>
            {showSuccess && (
                <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                    <Alert.Heading>Success!</Alert.Heading>
                    <p>Time Successfully submitted!</p>
                </Alert>
                )
            }
            <h2 className="text-center">Event & Time Info</h2>
            <Accordion defaultActiveKey={"0"}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>My Tasks</Accordion.Header>
                    <AccordionBody>
                        <Stack gap={3}>
                            {
                                eventData.length === 0 ? (
                                    <div>You have nothing scheduled.</div>
                                ) : (
                                    eventData.map((entry, index) => (
                                        entry.work_order ? (
                                            <NavigationCard
                                                title={`${(entry.work_order as WorkOrder).billing_client} - ${entry.event_name}`}
                                                subtitle={`${getTimeString(entry.start_date)} - ${getTimeString(entry.end_date)}`}
                                                body={
                                                    entry.origin_address? (<a href={getAddressLink(entry.origin_address as Address)}>{formatAddress(entry.origin_address as Address)}</a>): "No Address Details"
                                                }
                                                subtext={entry.event_description}
                                                link_destination={`/events/${entry.id}`}
                                                additional_buttons={
                                                    (
                                                        <Button onClick={() => handleOpenDetails(entry)}>Add Time</Button>
                                                    )
                                                }
                                                id = {index.toString()}
                                                key = {`event-${index}`}
                                            />

                                        ) : (
                                            <NavigationCard
                                                title={`${entry.event_name}`}
                                                subtitle={`${getDateTimeString(entry.start_date)} - ${getDateTimeString(entry.end_date)}`}
                                                body={
                                                    (entry.origin_address)?
                                                        (<a href={getAddressLink(entry.origin_address as Address)} rel={"noopener"}>{formatAddress(entry.origin_address as Address)}</a>)
                                                        : `${entry.event_description}`
                                                }
                                                subtext={entry.event_notes}
                                                additional_buttons={
                                                    (
                                                        <Button variant="success" className={'mx-3'} onClick={() => handleOpenDetails(entry)}>Add Time</Button>
                                                    )
                                                }
                                                link_destination={`/events/${entry.id}`}
                                                id = {index.toString()}
                                                key = {`event-${index}`}
                                            />
                                        )

                                    ))
                                )}
                        </Stack>
                    </AccordionBody>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Weeks Timesheets</Accordion.Header>
                    <Accordion.Body>
                        <WeekTimesheets/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Modal show={showModal} onHide={handleCloseModal} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateTimeEntry
                        selectedEvent={selectedEvent}
                        onCreate={handleCreate}

                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EventAndTimeList