import React, {useEffect, useState} from "react";
import {TimeSheet} from "../types";
import {getTimesheets} from "../api/dataService";
import NavigationCard from "./NavigationCard";
import {Stack} from "react-bootstrap";
import {getTimesheetDict} from "../util/Helpers";
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../redux/reduxTypes";


const WeekTimesheets: React.FC = () => {

    const pastDayInterval = 7
    const user = useSelector((state: RootState) => state.user)
    const filters = ['Ownership_Group', 'Management', 'Office_Admin', 'Supervisor'].some((role) =>
        (user.groups as string []).includes(role)) ? '':`?employee=${user.userid}`
    const [timesheetData, setTimesheetData] = useState<TimeSheet[]>([])
    useEffect(() => {
        Promise.all([getTimesheets(undefined, filters)])
            .then(([ timesheetResponse]) => {
                if('redirectTo' in timesheetResponse) {
                    return <Navigate to={timesheetResponse.redirectTo}/>
                }
                if(Array.isArray(timesheetResponse)) {
                    setTimesheetData(timesheetResponse)
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, [user]);


    const timesheetsByDay = getTimesheetDict(new Date(),pastDayInterval)

    timesheetData.forEach((value:TimeSheet,index:number) => {
        const timeEntryDate = new Date(value.start_date);
        const dayKey = `${timeEntryDate.toLocaleDateString()}`;

        if(timesheetsByDay[dayKey]){
            timesheetsByDay[dayKey].push(value)
        }

    })

    return (
        <Stack>
            {Object.keys(timesheetsByDay).length === 0 ? (
                <div>No Timesheet Entries</div>
            ) : (
                Object.entries(timesheetsByDay).map(([dayKey, timesheets], index) => (
                    <NavigationCard
                        title={`${dayKey}`}
                        subtitle={`${timesheets.length} recorded time entries`}
                        body=""
                        subtext=""
                        link_destination={`/timesheets/${index}`}
                        link_state={timesheets}
                        id={""} // Use entryIndex as the key if no unique ID is available
                        key={index}
                    />
                ))
            )}
        </Stack>
    );
}

export default WeekTimesheets