import axios from 'axios'
import Cookies from 'js-cookie'
import {extractErrorMessages} from "../util/Helpers";

export const api = axios.create({
    baseURL: 'https://classicoffice.tangentservices.com/backend/',
    withCredentials: true,
})


// Exposes requests sent
api.interceptors.request.use(
    config => {
        console.log('Request:', config.method, config.url, config.data);
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    config => {
        console.log('Response:', config.data, config.status, config.headers);
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const handleCSRF = async () => {
    try {
        const csrfRequest = await api.get('api-auth/login/')
        if (csrfRequest.status === 200) {
            const parser = new DOMParser()
            const doc = parser.parseFromString(csrfRequest.data, 'text/html')
            const csrfElement = doc.querySelector('input[name="csrfmiddlewaretoken"]')
            return csrfElement ? csrfElement.value : '';
        }
        return ''
    } catch (error) {
        console.error(error)
        return ''
    }
}

export const fetch = async (endpoint, id, filters) => {
    try {
        const response  = await api.get(
            `${endpoint}/${id? id+'/':''}${filters? filters:''}`
        )
        if (response.status === 200 || response.status === 201 ){

            if (Array.isArray(response.data.results) && response.data.results.length > 0){
                return response.data.results
            } else {
                return response.data
            }
        }
        else if (response.status === 403){
            return {
                redirectTo: "/home/",
                message: "Insufficient Permissions."
            }
        }

    } catch (error) {
        throw error
    }
}


export const fetchFile = (endpoint, id, filters) => {
    // Construct the URL
    window.open(`${api.defaults.baseURL}${endpoint}/${id}${filters? '/'+filters:''}`, '_blank', 'noopener,noreferrer');

};

export const fetchSafetySheet = (id) => {
    // Construct the URL
    window.open(`${api.defaults.baseURL}workorder/${id}/workorder_safetysheet_filled`, '_blank', 'noopener,noreferrer');

};
export const postData = async (endpoint, data) => {
    // Get new CSRF Token for post
    const csrf = await handleCSRF()
    api.defaults.headers['X-CSRFToken'] = csrf;

    // Add CSRF Token into data payload
    data.csrfmiddlewaretoken = csrf
    const jsonString = JSON.stringify(data);

    try {
        const response = await api.post(
            `${endpoint}/`,
            jsonString, {
                headers: {
                    'X-CSRFToken': csrf,
                    "Content-Type": "application/json",
                }
            }
            )
        if (response.status >= 200 && response.status < 300) {
            // Success!
            return response
        } else if(response.status >= 400 && response.status <= 500){
            // See CreateEmployee for an example in handling using this.
            return extractErrorMessages(response.data);
        }
        else {
            return null
        }
    } catch (error) {
        throw error
    }}

export const postAction = async (endpoint, id, action) => {
    // Get new CSRF Token for post
    const csrf = await handleCSRF()
    api.defaults.headers['X-CSRFToken'] = csrf;
    const data = {}
    // Add CSRF Token into data payload
    data.csrfmiddlewaretoken = csrf
    const jsonString = JSON.stringify(data);

    try {
        const response = await api.post(
            `${endpoint}/${id}/${action}/`,
            jsonString, {
                headers: {
                    'X-CSRFToken': csrf,
                    "Content-Type": "application/json",
                }
            }
        )
        if (response.status >= 200 && response.status < 300) {
            // Success!
            return response.data
        } else if(response.status >= 400 && response.status <= 500){
            return extractErrorMessages(response.data);
        }
        else {
            return null
        }
    } catch (error) {
        throw error
    }}

export const uploadFileUpdate = async (endpoint, id, formData) => {
    // Get new CSRF Token for post
    const csrf = await handleCSRF();
    api.defaults.headers['X-CSRFToken'] = csrf;
    try {
        const response = await api.patch(`${endpoint}/${id}/`, formData, {
            headers: {
                'X-CSRFToken': csrf,
                "Content-Type": "multipart/form-data"
            },
        });

        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else if (response.status >= 400 && response.status <= 500) {
            throw new Error("Failed to upload data.");
        } else {
            return null;
        }
    } catch (error) {
        throw error;
    }
};


export const uploadFile = async (endpoint, formData) => {
    // Get new CSRF Token for post
    const csrf = await handleCSRF();
    api.defaults.headers['X-CSRFToken'] = csrf;
    try {
        const response = await api.post(`${endpoint}/`, formData, {
            headers: {
                'X-CSRFToken': csrf,
                "Content-Type": "multipart/form-data"
            },
        });

        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else if (response.status >= 400 && response.status <= 500) {
            throw new Error("Failed to upload data.");
        } else {
            return null;
        }
    } catch (error) {
        throw error;
    }
};

export const update = async (endpoint,id,data) => {
    try{
        const csrf = await handleCSRF()
        api.defaults.headers['X-CSRFToken'] = csrf;

        // Add CSRF Token into data payload
        data.csrfmiddlewaretoken = csrf

        const response = await api.patch(`${endpoint}/${id}/`,data)

        if (response.status === 200 || response.status === 201 ){

            return response.data
        }
        else if (response.status === 403){
            return {
                redirectTo: "/home/",
                message: "Insufficient Permissions."
            }
        }

    } catch (error) {
        throw error
    }
}

export const deleteObject = async (endpoint, id) => {
    try {
        const csrf = await handleCSRF()
        api.defaults.headers['X-CSRFToken'] = csrf
        const response = await api.delete(`${endpoint}/${id}/`)
        if (response.status >= 200 && response.status < 300) {
            // Success!
            return response
        } else if(response.status >= 400 && response.status <= 500){
            return extractErrorMessages(response.data);
        }
        else {
            return null
        }
    } catch (error) {
        return extractErrorMessages(error.data)
        // console.error(error)
    }
}

export const login = async (username, password) => {
    try {
        const csrf = await handleCSRF()
        api.defaults.headers['X-CSRFToken'] = csrf;

        const response = await api.post('api-auth/login/',
            {username, password, csrfmiddlewaretoken: csrf},
            {
                headers:{'X-CSRFToken': csrf,
                "Content-Type": "multipart/form-data"},
            }
        )

        if(typeof response.data === 'string' && response.data.includes('html') ){
            const parser = new DOMParser()
            const doc = parser.parseFromString(response.data, 'text/html')
            const errorElement = doc.querySelector('.well.text-error')
            if(errorElement){
                const errorMessage = errorElement.textContent.trim()
                throw new Error(errorMessage)
            }
            else {
                throw new Error("Unknown Login Error")
            }
        }
        else{
            const userId = response.data['user_id']
            const groups = response.data['groups'].map((group) => group.name)
            return [userId, groups]
        }
    } catch (error) {
        throw new Error(error)
    }
}

export const logoutUser = async () => {
    try{
        const response = await api.get('api-auth/logout')
        Cookies.remove('sessionid')
        console.log("LOGOUT", response.data)
    }catch (error) {
        throw new Error("Error: " + error)
    }
}


