import React from "react";
import Container from "react-bootstrap/Container";
import CreateClient from "../CreateClient";
import {ParentContainerStyle} from "../../types";

const CreateEventPage: React.FC = () => {
    return(
        <Container fluid style={ParentContainerStyle}>
            <CreateClient/>
        </Container>
    )
}


export default CreateEventPage