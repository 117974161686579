import { Container, Spinner } from "react-bootstrap";
import {EventResourceUse, ParentContainerStyle, TimeSheet, WorkEvent} from "../../types";
import React, {useEffect, useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import {getEvents, getResourceUses, getTimesheets} from "../../api/dataService";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import StackedContentContainer from "../ContentContainer";
import ViewEvent from "../ViewEvent";



const ViewEventPage = () => {
    const {id} = useParams<{ id: string }>();
    const user = useSelector((state: RootState) => state.user)
    const [currentEvent, setCurrentEvent] = useState<WorkEvent>()
    const [resourcesOnEvent, setResourcesOnEvent] = useState<EventResourceUse[]>([])
    const [timesheetsOnEvent, setTimesheetsOnEvent] = useState<TimeSheet[]>([])
    useEffect(() => {
        let timesheet_filters = ['Ownership_Group','Office_Admin','Dispatch'].some((role) =>
            (user.groups as string []).includes(role)) ? `?event=${id}`:`?employee=${user.userid}&event=${id}`
        Promise.all([getEvents(id),getTimesheets(undefined, timesheet_filters),getResourceUses(undefined, `?event=${id}`)])
            .then(([eventResponse, timesheetResponse, resourceResponse]) => {
                if('redirectTo' in eventResponse) {return (<Navigate to={eventResponse.redirectTo}/>)}
                if('redirectTo' in timesheetResponse) {return (<Navigate to={timesheetResponse.redirectTo}/>)}
                if('redirectTo' in resourceResponse) {return (<Navigate to={resourceResponse.redirectTo}/>)}

                if (eventResponse && !Array.isArray(eventResponse)){
                    setCurrentEvent(eventResponse)
                }
                if(timesheetResponse && Array.isArray(timesheetResponse)){
                    setTimesheetsOnEvent(timesheetResponse)
                }

                if(resourceResponse && Array.isArray(resourceResponse)){
                    setResourcesOnEvent(resourceResponse)
                }

            })
    }, [id, user]);


    return (

        <Container style={ParentContainerStyle}>
            {currentEvent ?
                (<ViewEvent eventToView={currentEvent} resourcesOnEvent={resourcesOnEvent}  timeEntries={timesheetsOnEvent}/>)
                :
                (
                    <StackedContentContainer>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </StackedContentContainer>
                )}
        </Container>
    )
}


export default ViewEventPage;