import React, {useEffect, useMemo, useState} from 'react'
import {Contact} from "../../types";
import {deleteContact, getContacts} from "../../api/dataService";
import {Navigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import { ColumnDef, Row } from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Button, ButtonGroup, Modal} from "react-bootstrap"
import CreateContact from "../CreateContact";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {isLoadingSpinner} from "../../util/Helpers";


const parentStyle = {
    padding: '2rem',
}


function ManageContactsPage() {
    const [contactList, setContactList] = useState<Contact[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<Contact> | null>(null);
    const [contactToUpdate, setContactToUpdate] = useState<Row<Contact> | undefined>()
    useEffect(() => {
        Promise.all([getContacts()])
            .then(([contactResponse]) => {
                if('redirectTo' in contactResponse){
                    return(<Navigate to={contactResponse.redirectTo}/>)
                }
                if(Array.isArray(contactResponse)){
                    setContactList(contactResponse)
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, []);

    const columns = useMemo<ColumnDef<Contact, any>[]>(
        () => [
            {
                accessorFn: row => `${row.contact_name}`,
                id: 'contact_name',
                header: 'Name',
                enableColumnFilter: true
            },
            {
                accessorFn: row => `${row.contact_phone_number}`,
                id: 'contact_phone_number',
                header: 'Phone Number',
                enableColumnFilter: false
            },
            {
                accessorFn: row => `${row.contact_phone_extension}`,
                id: 'contact_phone_extension',
                header: 'Phone Extension',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.contact_email,
                id: 'contact_email',
                header: 'Email',
                enableColumnFilter: false
            },
            {
                id: 'actions',
                header: () => 'Actions',
                cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className={'btn-group-sm'}>
                            <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}> Update </Button>
                            <Button className={'btn-danger'} onClick={() => handleDeleteRow(row)}> Delete </Button>
                        </ButtonGroup>
                    </div>
                )
            }
        ],
        []
    )
    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setContactToUpdate(undefined)
    }
    const handleShowCreate = () => setShowCreateModal(true)
    const handleOpenDetails = (row: Row<Contact>) => {
        setContactToUpdate(row)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<Contact>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            deleteContact(selectedRowToDelete.original.id.toString()).then(r => {
                if(r && !Array.isArray(r)){
                    if(r.status >= 200 || r.status <= 300){
                        const dataCopy = [...contactList]
                        dataCopy.splice(selectedRowToDelete.index, 1)
                        setContactList(dataCopy)
                    }
                }

            })
            setShowDeleteModal(false);
        }

    }

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setContactList([created_obj,...contactList])
        }
        setShowCreateModal(false)
    }
    const handleUpdate = (updated_obj?: Contact) => {
        if(contactToUpdate){
            if(updated_obj){
                const dataCopy = [...contactList]
                dataCopy.splice(contactToUpdate.index, 1)
                setContactList([updated_obj, ...dataCopy])
            }
        }

    }
    if(isLoading){
        return(isLoadingSpinner())
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Contact Directory"}>
                <SearchableTable
                    columns={columns}
                    data={contactList}
                    globalSearch={true}
                    actionParam={
                        <Button onClick={handleShowCreate}>Add Contact</Button>
                    }
                />
            </StackedContentContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{contactToUpdate? "Update Contact": "Create Contact"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateContact
                        closeModal={handleCloseCreate}
                        contactToUpdate={contactToUpdate? contactToUpdate.original : undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageContactsPage