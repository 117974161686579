
import React from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
const AuthNavbar = () => {
    return (
        <>
            <Navbar expand="md" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand>Classic Office | Login Portal</Navbar.Brand>
                </Container>
            </Navbar>
        </>
    );
}
export default AuthNavbar;