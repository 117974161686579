import React from 'react'
import {Table} from 'react-bootstrap';
import StackedContentContainer from "../ContentContainer";
import {Link, useLocation} from "react-router-dom";
import {TimeSheet} from "../../types";
import {getDateString, getTimeString} from "../../util/Helpers";

const DailyTimeCard = () => {
    const location = useLocation();
    const timeEntries: TimeSheet[] = location?.state || [];
    let totalTimeElapsed = 0
    for (const timeSheet of timeEntries) {
      const startDate =new Date(timeSheet.start_date);
      const endDate = new Date(timeSheet.end_date);
      const timeElapsedMs = Number(endDate)-Number(startDate);
      totalTimeElapsed += timeElapsedMs;
    }
  const totalHoursWorked = totalTimeElapsed / (1000 * 60 * 60); // hours
  return(
        <StackedContentContainer title={''}>
          <h5>Total Hours: {totalHoursWorked}</h5>
          <Table responsive>
            <thead>
                <tr>
                    <th>Event</th>
                    <th>Date</th>
                    <th>Time In</th>
                    <th>Time Out</th>
                </tr>
            </thead>
            <tbody>
            {timeEntries.map((entry, index) => (
                <tr key={index}>
                    <td>
                        <Link to={`/events/${entry.event.id}`}>
                          {entry.event.event_name}
                        </Link>
                    </td>
                    <td>{getDateString(entry.start_date)}</td>
                    <td>{getTimeString(entry.start_date)}</td>
                    <td>{getTimeString(entry.end_date)}</td>
                </tr>
            ))}
            </tbody>
          </Table>
        </StackedContentContainer>
  )
}

export default DailyTimeCard