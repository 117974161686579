import React, {useEffect, useMemo, useState} from 'react'
import {ActionResponse, Quote, QuoteAction, QuoteStatus, User, UserQuote, UserRoles} from "../../types";
import {deleteQuote, getQuotePDF, getQuotes, getUserQuotes, getUsers, postQuoteAction} from "../../api/dataService";
import {Navigate, useNavigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import {ColumnDef, Row} from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Button, ButtonGroup, Col, Row as BRow} from "react-bootstrap"
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {
    checkIsAllowed,
    getDateTimeString,
    getQuoteStatusFromAction, getQuoteUser,
    handlePostError, isLoadingSpinner
} from "../../util/Helpers";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import QuoteActionButtons from "../QuoteActionButtons";


const parentStyle = {
    padding: '2rem',
}

function ManageQuotesPage() {
    const user = useSelector((state: RootState) => state.user)
    const [isLoading, setIsLoading] = useState(true)
    const [quoteList, setQuoteList] = useState<Quote[]>([])
    const [userQuoteList, setUserQuoteList] = useState<UserQuote[]>([])
    const [userList, setUserList] = useState<User[]>([])
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<Quote> | null>(null);
    const navigate = useNavigate()
    useEffect(() => {
        Promise.all([getQuotes(), getUserQuotes(),getUsers(undefined,'?last_login=&is_superuser=&groups=1&groups=2&groups=7')])
            .then(([quoteResponse, userQuoteResponse, userResponse]) => {
                if ('redirectTo' in quoteResponse) { return <Navigate to={quoteResponse.redirectTo} />}
                if('redirectTo' in userQuoteResponse) { return <Navigate to={userQuoteResponse.redirectTo}/> }
                if('redirectTo' in userResponse) { return <Navigate to={userResponse.redirectTo}/> }
                if(Array.isArray(quoteResponse)) {
                    setQuoteList(quoteResponse)
                }

                // List of all quotes and keys associated to the user that created the intial quote
                if(Array.isArray(userQuoteResponse)) {setUserQuoteList(userQuoteResponse)}

                // List of users who manage quotes (Ownership, Management, and Sales)
                if(Array.isArray(userResponse)) {setUserList(userResponse)}

                if(quoteList){
                    setIsLoading(false)
                }

            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);


    const columns = useMemo<ColumnDef<Quote, any>[]>(
        () => [
            {
                accessorFn: row => `${row.id}`,
                id: 'quote_id',
                header: 'Quote ID',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.billing_client,
                id: 'billing_client',
                header: 'Billing Client',
                enableColumnFilter: true
            },
            {
                accessorFn: row => row.jobtype_set,
                id: 'job_type',
                header: 'Job Type',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.quote_status,
                id: 'status',
                header: 'Status',
                enableColumnFilter: true,
                filterFn: "arrIncludesSome"
            },

            {
                accessorFn: row => `${getDateTimeString(row.last_change_time)}`,
                id: 'last_change_time',
                header: () => 'Last Modified',
                enableColumnFilter: false
            },
            {
                accessorFn: row =>  getQuoteUser(row.id, userList,userQuoteList)?.username || "Unknown User" ,
                id: 'last_change_by_user',
                header: () => 'Created By',
                enableColumnFilter: false
            },
            {
                id: "View Quote",
                cell: ({row}) => {
                  return  <Button className="btn-secondary btn-sm" onClick={() => handleOpenDetails(row)}>View</Button>
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({row}) => {
                    // Quote Viewing Permission Logic
                    if(checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management]) ||
                        // Show quote if the current user created the initial quote
                        userQuoteList.find(element => element.user === user.userid)?.quote === row.original.id) {

                        // Quote Action Button Permission Logic
                        if((row.original.quote_status === QuoteStatus.rejected || row.original.quote_status === QuoteStatus.opened)) {
                            return <div className=" d-flex justify-content-center"><QuoteActionButtons quote_status={row.original.quote_status} quote_id={row.original.id}/><Button className="btn-sm btn-danger" onClick={() => handleDeleteRow(row)}>Delete</Button></div>
                        }
                        return <QuoteActionButtons quote_status={row.original.quote_status} quote_id={row.original.id}/>
                    } else {
                        return (
                            <div className="d-flex justify-content-center">
                            <ButtonGroup className=" btn-group-sm">
                                <Button
                                    onClick={() => handleQuoteAction(row.original.id, QuoteAction.save)}
                                    className="btn-primary"
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    Download PDF
                                </Button>
                            </ButtonGroup>
                        </div>
                        )
                    }

                }
            }
        ],
        [userQuoteList]
    )



    const handleShowCreate = () => {
        navigate('/quotes/create/')
    }

    const handleOpenDetails = (row: Row<Quote>) => {
        navigate(`/quotes/${row.original.id}`, {state: row.original})
    }

    // TODO: Duplicate code fragment, export and reformat function into a helper
    const handleQuoteAction = async (quote_ID: string, quoteAction: QuoteAction) => {
        try {
            if(quoteAction === QuoteAction.save) {
                await getQuotePDF(quote_ID)
            } else {
                const response = await postQuoteAction(quote_ID,quoteAction)

                let QuoteActionResponse = response as ActionResponse

                if (QuoteActionResponse && QuoteActionResponse.success) {

                    navigate(`/quotes/${getQuoteStatusFromAction(quoteAction) ? getQuoteStatusFromAction(quoteAction) : QuoteStatus.none}`)

                }
            }
        } catch (error) {
            setErrorMessages(handlePostError(error, 'QuoteAction'))
        }
    }

    const handleDeleteRow = (row: Row<Quote>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            deleteQuote(selectedRowToDelete.original.id.toString(), selectedRowToDelete.original).then(r => {
                    if(r && !Array.isArray(r)){
                        if(r.status >= 200 || r.status <= 300){
                            const dataCopy = [...quoteList]
                            dataCopy.splice(selectedRowToDelete.index, 1)
                            setQuoteList(dataCopy)
                        }
                    }
                }
            )
            // Close the delete modal
            setShowDeleteModal(false);
        }
    };

    if(isLoading){
        return(isLoadingSpinner())
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Quote Directory"}>
                <SearchableTable
                    columns={columns}
                    data={quoteList}
                    globalSearch={true}
                    actionParam={
                        checkIsAllowed(user.groups, ['Ownership_Group','Management'])?
                            (<BRow>
                                <Col>
                                    <Button onClick={handleShowCreate}>Create Quote</Button>
                                </Col>
                            </BRow>) : undefined
                    }
                />
            </StackedContentContainer>

            {/*MODALS / POP UP WINDOWS */}

            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageQuotesPage