import React, {useEffect, useMemo, useState} from 'react'

import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    getExpandedRowModel,
    ColumnDef,
    flexRender, Row,
} from '@tanstack/react-table'
import {
    formatAddress,
    WorkEvent,
    Address,
    EventResourceUse,
    FullWorkEvent,
    WorkEventStatus, UserRoles, TimeSheet,
} from "../../types";
import Button from "react-bootstrap/Button";
import {Modal, Table as BTable, ButtonGroup, ModalBody, Alert} from "react-bootstrap";
import {
    checkIsAllowed,
    getDateTimeString,
    getElapsedHours,
    handlePostError
} from "../../util/Helpers";
import CreateEvent from "../CreateEvent";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {deleteEvent, setEventState} from "../../api/dataService";
import ViewEvent from "../ViewEvent";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import WorkOrderActionButtons from "../WorkOrderActionButtons";
import EventActionButtons from "../EventActionButtons";
import {tab} from "@testing-library/user-event/dist/tab";

interface WorkOrderEventsTableProps  {
    eventData: FullWorkEvent[],
    handleEventDelete: (object: Partial<WorkEvent>, eventDataIndex: number) => void,
    disabled: boolean
}

/**
 * This component is responsible for loading a table of events (non-quoting)
 * @param eventData list of event objects that include resource, timesheet, and employee data
 * @constructor
 */
const WorkOrderEventsTable: React.FC<WorkOrderEventsTableProps> = (props) => {
    const user = useSelector((state:RootState) => state.user);
    const [tableData, setTableData] = useState<FullWorkEvent[]>(props.eventData)
    const [showSuccess, setShowSuccess] = useState(false)
    const navigate = useNavigate()
    const [errorMessages, setErrorMessages] = React.useState<string[]>([])
    const [showExistingEventModal, setShowExistingEventModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<FullWorkEvent> | null >(null);
    const [showViewModal, setShowViewModal] = useState(false)
    const [rowToUpdate, setRowToUpdate] = useState<Row<FullWorkEvent> | undefined>()
    const [eventToUpdate, setEventToUpdate] = useState<Row<FullWorkEvent>>()
    const [showCreateModal, setShowCreateModal] = useState(false)

    // Use useEffect to update tableData when props.eventData changes
    useEffect(() => {

        setTableData(props.eventData);
    }, [props.eventData]);


    const columns = useMemo<ColumnDef<FullWorkEvent>[]>(
        () => [
                    {
                        accessorFn: row => row.WorkEvent.event_name,
                        id: 'event_name',
                        header: 'Event Name',
                        enableColumnFilter: false
                    },
                    {
                        accessorKey: 'start_date',
                        header: "Date",
                        cell: ({ row }) => (
                            <>
                                {row.original.WorkEvent.start_date ? getDateTimeString(row.original.WorkEvent.start_date) : 'No start Date'}
                            </>
                        ),
                        enableColumnFilter: false
                    },
                    {
                        accessorFn: row => row.WorkEvent.origin_address ? formatAddress(row.WorkEvent.origin_address as Address) : '',
                        id: 'origin_address',
                        header: 'Origin Address',
                        enableColumnFilter: false

                    },
                    {
                        accessorFn: row => row.WorkEvent.event_description,
                        id: 'event_description',
                        header: 'Event Description',
                        enableColumnFilter: false
                    },
                    {
                        accessorFn: row => {
                            if(row.TimeSheetList) {
                                return  row.TimeSheetList.reduce((total, timeEntry) => timeEntry.start_date && timeEntry.end_date ? parseFloat(getElapsedHours(timeEntry.start_date,timeEntry.end_date)) + total : total, 0);
                            } else {
                                return 0
                            }

                        },
                        id: 'totalHours',
                        header: 'Total Hours',
                        enableColumnFilter: false

                    },
                    {
                        accessorFn: row => row.WorkEvent.status,
                        id: 'status',
                        header: "Status",
                        enableColumnFilter: false
                    }, {
                id: "viewEvent",
                cell: ({row}) => {

                    // Event Editing Permission Logic
                    return (
                        <div className="d-flex justify-content-center">
                            <ButtonGroup className={'btn-group-sm'}>
                                <Button className={'btn-primary'}
                                        onClick={() => navigate(`/events/${row.original.WorkEvent.id}`)}>View</Button>
                                {checkIsAllowed(user.groups, ['Ownership_Group', 'Management', 'Office_Admin', 'Dispatch',]) ?
                                    (<>
                                        <Button className={'btn-secondary'}
                                                onClick={() => handleOpenDetails(row)}>Update</Button>
                                    </>) : (<></>)
                                }
                            </ButtonGroup>
                        </div>
                    )
                }
            },
            {
                id: 'actions',
                header: "Actions",
                cell: ({row}) => {

                    // Event Action Permission Logic
                    if (checkIsAllowed(user.groups, [UserRoles.management, UserRoles.ownership, UserRoles.officeAdmin])) {

                        return (
                            <div className="d-flex justify-content-evenly">
                                <EventActionButtons event={row.original.WorkEvent} onUpdate={handleActionUpdate} />
                                {row.original.WorkEvent.status === "deleted" ? '' :
                                    <Button className="btn-sm btn-danger" onClick={() => handleDeleteRow(row)}>Delete</Button>}
                                    </div>
                                )
                            }
                                },
                        enableColumnFilter: false
                    }
        ],
        [props.disabled,tableData],
    )

    // Function to handle event updates
    const handleEditExistingEvent = (workEvent: Row<FullWorkEvent>, resourceData: EventResourceUse[]) => {
        setRowToUpdate(workEvent)
        setShowExistingEventModal(true)
    }

    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setEventToUpdate(undefined)
    }

    const handleDeleteRow = (row: Row<FullWorkEvent>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);

    };


    const handleOpenDetails = (row: Row<FullWorkEvent>) => {
        setEventToUpdate(row)
        setShowCreateModal(true)
    }

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            const response = deleteEvent(selectedRowToDelete.original.WorkEvent.id.toString(), selectedRowToDelete)

            if(response && !Array.isArray(response)) {
                const dataCopy = [...tableData]
                dataCopy.splice(selectedRowToDelete.index, 1)
                setTableData(dataCopy)
            }
            setShowDeleteModal(false);
        }
    };

    const handleViewEvent =(row: Row<FullWorkEvent>) => {
        navigate(`../../events/${row.original.WorkEvent.id}`, {state: row.original.WorkEvent})
    }

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setTableData([created_obj,...tableData])
        }
        setShowCreateModal(false)
    }
    // Function to handle event updates (except for action changes)
    const handleUpdatedEvent = (updatedWorkEvent: WorkEvent,updatedAssignedEmployeeList: string[], updatedAssignedResources: EventResourceUse[]) => {

        // Find the index of the updated event in the table data array
        const index = tableData.findIndex(fullWorkEvent => fullWorkEvent.WorkEvent.id === rowToUpdate?.original.WorkEvent.id)

        if(index !== -1) {
            // Update the event ensuring that updates to the resources, employees, and event details are added
            const updatedTableData = [...tableData]
            updatedTableData[index].WorkEvent = updatedWorkEvent
            updatedTableData[index].AssignedResourceList = updatedAssignedResources
            updatedTableData[index].AssignedEmployeeList = updatedAssignedEmployeeList
            setTableData(updatedTableData)
            setRowToUpdate(undefined)

        }
        setRowToUpdate(undefined)
        setShowExistingEventModal(false)

    }


    // Function to handle event action change
    const handleActionUpdate = (updated_obj?: WorkEvent, previous_obj?: WorkEvent) => {
        if(previous_obj && updated_obj){
            const dataCopy = [...tableData]

            let prevFullEvent = dataCopy.splice(dataCopy.findIndex(value => value.WorkEvent.id === previous_obj.id), 1)[0]


            if(prevFullEvent) {
                const updatedFullEvent = {
                    ...prevFullEvent,
                    WorkEvent: updated_obj
                }
                setTableData([updatedFullEvent, ...dataCopy])
            }
        }
    }

    const table = useReactTable({
        data: tableData,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true,
    })

    return (
        <div className="p-2">
            <div className="h-2" />
            <Alert className="alert-success" show={showSuccess}>
                Event Action Successful! (Please refresh page)
            </Alert>
            <BTable>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>

                {table.getRowModel().rows.length !== 0 ? (
                    table.getRowModel().rows.map((row) => {
                        return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={8}>
                            <p className="text-center">No events inserted</p>
                        </td>
                    </tr>
                )}
                </tbody>
            </BTable>

            {/*MODALS / POP UP WINDOWS */}

            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{eventToUpdate? `Update Event ${eventToUpdate.original.WorkEvent.id}`: "Create New Event"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateEvent
                        closeModal={handleCloseCreate}
                        eventToUpdate={eventToUpdate?.original.WorkEvent? eventToUpdate.original.WorkEvent:undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdatedEvent}
                        isQuoting={true}
                        showCrew={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )


}


export default WorkOrderEventsTable;
