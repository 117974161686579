import CreateEvent from "../CreateEvent";
import React from "react";
import Container from "react-bootstrap/Container";
import {ParentContainerStyle} from "../../types";


const CreateEventPage: React.FC = () => {
    return(
        <Container fluid style={ParentContainerStyle}>
            <CreateEvent isQuoting={true} showCrew={true}/>
        </Container>
    )
}


export default CreateEventPage