import React, {ReactNode} from 'react'
import Container from "react-bootstrap/Container";
import {Stack} from "react-bootstrap";


const containerStyling = {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.5rem',
    boxShadow: '0 0.5rem 1rem rgba(0,0,0,0.1)',
    width: '80%'
};


interface ContainerProps {
    children: ReactNode;
    title?: string;
}

// const ContentContainer: React.FC<ContainerProps> = ({children}) => {
//     const childrenArray = React.Children.toArray(children)
//
//     return (
//         <div>
//             <Container fluid style={containerStyling}>
//                 <h4>Test</h4>
//                 <Row className="justify-content-center">
//                     <Col>
//                         {childrenArray.map((child, index) => (
//                             <div style={contentStyling} key={index}>{child}</div>
//                         ))}
//                     </Col>
//                 </Row>
//             </Container>
//         </div>
//     )
// }

const StackedContentContainer: React.FC<ContainerProps> = ({children, title}) => {
    const childrenArray = React.Children.toArray(children)

    return (
        <Container fluid style={containerStyling}>

            <h2 className="text-center">{title}</h2>
            <Stack gap={3}>
                {childrenArray.map((child, index) => (
                    <div key={index}>{child}</div>
                ))}

            </Stack>
        </Container>
    )
}

export default StackedContentContainer;
