import React, {useEffect, useState} from "react";
import {User, WorkOrder} from "../types";
import DatePicker from "react-datepicker";
import {getUsers} from "../api/dataService";
import {Navigate} from "react-router-dom";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Alert, Button} from "react-bootstrap";
import {fetchFile} from "../api/api";


interface ExportTimeProps {
    endpoint: string,
    workorderList?: WorkOrder[]
}

const ExportTimecard: React.FC<ExportTimeProps> = (props) => {
    const [start, setStart] = useState<string>()
    const [end,setEnd] = useState<string>()
    const [emp, setEmp] = useState<string[]>()
    const [wo, setWO] = useState<string[]>([])
    const [empList, setEmpList] = useState<User[]>([])
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        Promise.all([getUsers()])
            .then(([userResponse]) => {
                if('redirectTo' in userResponse) { return(<Navigate to={userResponse.redirectTo}/>)}
                if(Array.isArray(userResponse)){ setEmpList(userResponse) }
            })
    }, []);

    const handleExport = () => {
            let filters = `?&start_date_after=${start ? start : ''}&end_date_before=${end ? end : ''}&emloyeee_id_in=${emp ? emp: ''}&workorder=${wo ? wo : ''}&format=xlsx`
            fetchFile(props.endpoint, filters)
            setErrorMessage(undefined)
        }
    return (
        <Container>
            {errorMessage && (
                <Alert variant="danger">
                    {errorMessage}
                </Alert>
            )}
            <Row className={'mb-3'}>
                <label id={'employeeuser_set'}>
                    Employee
                </label>
                <Select
                    isMulti={true}
                    name="employeeuser_set"
                    options={empList.map(entry => ({
                        label: `${entry.last_name}, ${entry.first_name}`,
                        value: entry.id,
                    })).sort((a, b) => a.label.localeCompare(b.label))}
                    placeholder={'Select an Employee...'}
                    onChange={(selectedOption) => {
                        const selectedEmpIds: string[] = []
                        selectedOption.forEach((selection) => {
                            selectedEmpIds.push(selection.value)
                        })
                        setEmp(selectedEmpIds)
                    }}
                />
            </Row>
            <Row>
                <label id={'workorder_set'}>
                    Work Order
                </label>
                {
                    props.workorderList ?
                        <Select
                            isMulti={true}
                            name="workorder_set"
                            options={props.workorderList.map(entry => ({
                                label: `WO #${entry.id}, Client Name ${entry.billing_client}`,
                                value: entry.id
                            })).sort((a,b) => b.label.localeCompare(a.label))}
                            placeholder={'Select an Work Order...'}
                            onChange={(selectedOption) => {
                                const selectedWOIds: string[] = []
                                selectedOption.forEach((selection) => {
                                    selectedWOIds.push(selection.value)
                                })
                                setWO(selectedWOIds)
                            }}
                            /> : <></>

                }

            </Row>
            <Row className={'mb-3'}>
                <Col className={'justify-content-center'}>
                    <p id={'start_date'}>
                        Start Date
                    </p>
                    <DatePicker
                        id="start_date"
                        className={'datetime-select form-control'}
                        dateFormat={"yyyy-MM-dd"}
                        selected={
                            start? new Date(start):undefined
                        }
                        onChange={(date: Date) => {
                            if (date) {
                                setStart(date.toLocaleDateString())
                            }
                        }}
                    />
                </Col>
                <Col className={'justify-items-center'}>
                    <p id={'end_date'}>
                        End Date
                    </p>
                    <DatePicker
                        id="end_date"
                        className={'datetime-select form-control'}
                        dateFormat={"yyyy-MM-dd"}
                        selected={
                            end? new Date(end): undefined
                        }
                        onChange={(date: Date) => {
                            if (date) {
                                setEnd(date.toLocaleDateString())
                            }
                        }}
                    />

                </Col>
            </Row>
            <Row className={'mb-3'}>
                <Button onClick={() => handleExport()}>
                    Export Timecard
                </Button>
            </Row>

        </Container>
    )

}

export default ExportTimecard