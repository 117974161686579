import React, {useRef, useState} from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {JobType, PartialJobType} from "../types";
import * as Yup from 'yup'
import {handlePostError} from "../util/Helpers";
import {postJobType, updateJobType } from "../api/dataService";
import Container from "react-bootstrap/Container";
import {Alert} from "react-bootstrap";
import {ErrorMessage, Field, Formik} from "formik";
interface CreateJobTypeProps {
    jobTypeToUpdate? : PartialJobType
    closeModal?: (values?: PartialJobType) => void
    onCreate?: (createdObject: PartialJobType) => void
    onUpdate?: (updatedObject: JobType) => void
}

const CreateJobType: React.FC<CreateJobTypeProps> = (props) => {
    const isUpdating = !!props.jobTypeToUpdate
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [showSuccess, setShowSuccess] = useState(false)
    const formikRef = useRef<any>(null)
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Job Type Name is required.'),
    })
    const initialValues = props.jobTypeToUpdate || {
        name: ''
    }


    const handleSubmit = async(values: PartialJobType) => {
        try {
            let response = undefined
            if(isUpdating){
                response = await updateJobType(props.jobTypeToUpdate?.id? props.jobTypeToUpdate.id.toString(): '',values)
            }
            else {
                response = await postJobType(values)
            }

            if (response && !Array.isArray(response)) {
                setErrorMessages([])
                setShowSuccess(true)
                if (formikRef.current) formikRef.current.resetForm()
                if (props.closeModal) props.closeModal()
                if (props.onCreate) props.onCreate(response.data)
                if (isUpdating && props.onUpdate) props.onUpdate(response)

            }
        }
        catch (error) {
            setErrorMessages(handlePostError(error, 'Job Type'))
        }
    }

    return (
        <Container>
            <Row className={"justify-content-center"}>
                {errorMessages.length > 0 && (
                    <Alert variant={'danger'}>
                        <ul>
                            {errorMessages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        Job Type created successfully!
                    </Alert>
                )}
            </Row>
            <Row className={'justify-content-center'}>
                <Formik
                    enableReinitialize={true}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <Form onSubmit={formik.handleSubmit}>
                            <Row className={'my-3'}>
                                <Col>
                                    <Form.Group controlId="make">
                                        <Form.Label className={'fw-bold'}>Job Type Name</Form.Label>
                                        <Field
                                            as={Form.Control}
                                            name={'name'}
                                            type={'text'}
                                            placeholder={''}
                                        />
                                        <ErrorMessage name={'name'} component={'div'} className={'text-danger'}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={'mb-3 justify-content-center '} xs={5}>
                                <Button type={"submit"} disabled={formik.isSubmitting}>Submit</Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Row>
        </Container>
    );
}

export default CreateJobType;