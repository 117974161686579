import React, {useEffect, useMemo, useState} from 'react'
import {WorkEvent, WorkEventStatus, WorkOrder} from "../../types";
import {deleteEvent, getEvents, setEventState} from "../../api/dataService";
import {Navigate, useNavigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import {ColumnDef, Row} from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Alert, Button, ButtonGroup, Modal} from "react-bootstrap"
import {
    checkIsAllowed,
    getDateTimeString,
    handlePostError,
    isLoadingSpinner
} from "../../util/Helpers";
import CreateEvent from "../CreateEvent";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import {endDateFilter, startDateFilter} from "../../util/CustomFilters";
import EventActionButtons from "../EventActionButtons";


const parentStyle = {
    padding: '2rem',
}


function ManageEventsPage() {
    const [eventList, setEventList] = useState<WorkEvent[]>([])
    const [showSuccess, setShowSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<WorkEvent> | null >(null);
    const [eventToUpdate, setEventToUpdate] = useState<Row<WorkEvent>>()
    const user = useSelector((state: RootState) => state.user)
    const navigate = useNavigate()
    useEffect(() => {
        Promise.all([getEvents(undefined)])
            .then(([eventResponse]) => {
                if('redirectTo' in eventResponse){
                    return(<Navigate to={eventResponse.redirectTo}/>)
                }
                if(Array.isArray(eventResponse)){
                    setEventList(eventResponse)
                }
                if(eventList) setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, []);

    const columns = useMemo<ColumnDef<WorkEvent, any>[]>(
        () => [
            {
                cell: ({row}) => {
                   if(row.original.work_order) {
                       return <div className="text-center">
                           <a href={`/workorders/${(row.original.work_order as WorkOrder).id}`}>WO #{(row.original.work_order as WorkOrder).id}</a>
                       </div>

                   }
                },
                id: 'billing_client',
                header: 'Work Order',
                enableColumnFilter: true
            },
            {
                accessorFn: row => row.event_name,
                id: 'event_name',
                header: 'Event Name',
                enableColumnFilter: false
            },
            {
                accessorFn: row => `${getDateTimeString(row.start_date)}`,
                id: 'start_date',
                header:'Start Date',
                enableColumnFilter: true,
                filterFn: startDateFilter
            },
            {
                accessorFn: row => `${getDateTimeString(row.end_date)}`,
                id: 'end_date',
                header: 'End Date',
                enableColumnFilter: true,
                filterFn: endDateFilter
            },

            {
                accessorFn: row => row.status,
                id: 'event_status',
                header: 'Status',
                enableColumnFilter: true,
                filterFn: "arrIncludesSome"
            },

            {
                id: "viewEvent",
                cell: ({row}) => {
                    return (
                        <div className="d-flex justify-content-center">
                            <ButtonGroup className={'btn-group-sm'}>
                                <Button className={'btn-primary'} onClick={() => navigate(`/events/${row.original.id}`)}>View</Button>
                                {checkIsAllowed(user.groups, ['Ownership_Group', 'Management', 'Office_Admin', 'Dispatch',])?
                                    (<>
                                        <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}>Update</Button>
                                    </>) : (<></>)
                                }
                                </ButtonGroup>
                            </div>
                            )
                }
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({row}) => {

                    if (checkIsAllowed(user.groups, ['Ownership_Group', 'Management', 'Office_Admin'])) {
                        return (
                            <div className="d-flex justify-content-center">
                                    <EventActionButtons event = {row.original} onUpdate={handleActionUpdate} />
                                    {
                                        row.original.status === "deleted" ? '': <Button className="btn-sm btn-danger" onClick={() => handleDeleteRow(row)}>Delete</Button>
                                    }
                            </div>
                        )
                    }

                }
            }
        ],
        [eventList]
    )

    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setEventToUpdate(undefined)
    }
    const handleShowCreate = () => {
        setShowCreateModal(true)
    }
    const handleOpenDetails = (row: Row<WorkEvent>) => {
        setEventToUpdate(row)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<WorkEvent>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);

    };

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            const response = deleteEvent(selectedRowToDelete.original.id.toString(), selectedRowToDelete)

            if(response && !Array.isArray(response)) {
                const dataCopy = [...eventList]
                dataCopy.splice(selectedRowToDelete.index, 1)
                setEventList(dataCopy)
            }
            setShowDeleteModal(false);
        }
    };

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setEventList([created_obj,...eventList])
        }
        setShowCreateModal(false)
    }
    const handleUpdate = (updated_obj?: WorkEvent) => {
        if(eventToUpdate){
            if(updated_obj){
                const dataCopy = [...eventList]
                dataCopy.splice(eventToUpdate.index, 1)
                setEventList([updated_obj, ...dataCopy])
            }
        }
    }


    const handleActionUpdate = (updated_obj?: WorkEvent, previous_obj?: WorkEvent) => {
        if(previous_obj && updated_obj){
            const dataCopy = [...eventList]
            dataCopy.splice(dataCopy.findIndex(value => value.id === previous_obj.id), 1)
            setEventList([updated_obj, ...dataCopy])
        }
    }

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Event Directory"}>
                <Alert className="alert-success" show={showSuccess}>
                    Event Action Successful! (Please refresh page)
                </Alert>
                <SearchableTable
                    columns={columns}
                    data={eventList}
                    globalSearch={true}
                    actionParam={
                        checkIsAllowed(user.groups, ['Ownership_Group','Management','Office_Admin','Sales'])?
                            (<Button onClick={handleShowCreate}>Create Event</Button>):undefined
                    }
                />
            </StackedContentContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{eventToUpdate? `Update Event ${eventToUpdate.original.id}`: "Create New Event"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateEvent
                        closeModal={handleCloseCreate}
                        eventToUpdate={eventToUpdate? eventToUpdate.original:undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                        isQuoting={true}
                        showCrew={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}




export default ManageEventsPage