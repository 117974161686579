import React from 'react';
import {Navigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {RootState} from "../redux/reduxTypes";

interface PrivateRouteProps {
    roles: string[]
    children: React.ReactNode
}
// Prevents access to unauthenticated users, redirects to home if route not allowed
const PrivateRoute: React.FC<PrivateRouteProps> = ({ roles, children, ...props }) => {
    const userRoles: string[] = useSelector((state:RootState) => state.user.groups);
    const unauthenticatedAccess = roles.includes('-')
    if(!userRoles || userRoles.length < 1){
        if(unauthenticatedAccess){
            return <>{children}</>
        }
        else{
            console.error("No Permissions, redirecting to login")
            return <Navigate to="/auth/login" />
        }
    }

    if(roles.includes('*') || roles.some((role) => userRoles.includes(role))) {
        if(!unauthenticatedAccess){
            return <>{children}</>
        }

    }
    console.error("Route not allowed, redirecting to home!")
    return <Navigate to="/home" />
};

export default PrivateRoute;
