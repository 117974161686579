import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';

interface NavigationCardProps {
    title: string;
    subtitle: string;
    body?: React.ReactNode;
    subtext?: string;
    id?: string
    link_destination: string
    link_state?: any
    additional_buttons?: any
}

function NavigationCard(props: NavigationCardProps) {
    const isSmallScreen = useMediaQuery({maxWidth: 767}); // Define your breakpoint

    const cardClickHandler = () => {
        if (isSmallScreen) {
            window.location.href = props.link_destination;
        }
    };

    return (
        <Card
            style={{cursor: isSmallScreen ? 'pointer' : 'default', userSelect: 'none'}}
            onClick={cardClickHandler}
            key={props.id !== undefined ? props.id : ''}
        >
            <Card.Body
                className="d-flex flex-column"
                style={{textDecoration: 'none'}}
            >
                <div className="d-flex flex-row justify-content-between mb-3">
                    <div>
                        <Card.Title>{props.title}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            {props.subtitle}
                        </Card.Subtitle>
                        {!isSmallScreen && (
                            <Card.Text>
                                <>
                                    {props.body}
                                    <br/>
                                    {props.subtext !== undefined ? props.subtext : ''}
                                </>
                            </Card.Text>
                        )}
                    </div>
                    {!isSmallScreen && (
                        <div className="d-flex align-items-center">
                            {props.additional_buttons && (
                                props.additional_buttons
                            )}
                            <Link to={props.link_destination} style={{textDecoration: 'none'}}
                            state={props.link_state}>
                                <Button variant="primary">Details</Button>
                            </Link>
                        </div>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
}

export default NavigationCard;
