import React, {useEffect, useMemo, useState} from 'react'
import { Vehicle} from "../../types";
import {deleteVehicle, getVehicles} from "../../api/dataService";
import {Navigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import { ColumnDef, Row } from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Button, Modal, ButtonGroup} from "react-bootstrap"
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import CreateVehicle from "../CreateVehicle";
import {isLoadingSpinner} from "../../util/Helpers";


const parentStyle = {
    padding: '2rem',
}

function ManageVehiclesPage() {
    const [vehicleList, setVehicleList] = useState<Vehicle[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<Vehicle> | null>(null);
    const [rowToUpdate, setRowToUpdate] = useState<Row<Vehicle> | undefined>()
    useEffect(() => {
        Promise.all([getVehicles()])
            .then(([vehicleResponse]) => {
                if('redirectTo' in vehicleResponse){
                    return(<Navigate to={vehicleResponse.redirectTo}/>)
                }
                if(Array.isArray(vehicleResponse)){
                    setVehicleList(vehicleResponse)
                }
                if(vehicleList) setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, []);

    const columns = useMemo<ColumnDef<Vehicle, any>[]>(
        () => [
            {
                accessorFn: row => `${row.make}`,
                id: 'make',
                header: 'Make',
                enableColumnFilter: false
            },
            {
                accessorFn: row => `${row.model}`,
                id: 'model',
                header: 'Model',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.vin,
                id: 'vin',
                header: 'VIN',
                enableColumnFilter: false,

            },
            {
                id: 'actions',
                header: () => 'Actions',
                cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className={'btn-group-sm'}>
                            <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}> Update </Button>
                            <Button className={'btn-danger'} onClick={() => handleDeleteRow(row)}> Delete </Button>
                        </ButtonGroup>
                    </div>
                )
            }
        ],
        []
    )
    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setRowToUpdate(undefined)
    }
    const handleShowCreate = () => setShowCreateModal(true)
    const handleOpenDetails = (row: Row<Vehicle>) => {
        setRowToUpdate(row)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<Vehicle>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            console.log("Deleting row:", selectedRowToDelete);
            deleteVehicle(selectedRowToDelete.original.id.toString()).then(r => {
                if(r && !Array.isArray(r)){
                    if(r.status >= 200 || r.status <= 300){
                        const dataCopy = [...vehicleList]
                        dataCopy.splice(selectedRowToDelete.index, 1)
                        setVehicleList(dataCopy)
                    }
                }

                console.log('RESULT', r)
            })
            setShowDeleteModal(false);
        }

    }

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setVehicleList([created_obj,...vehicleList])
        }
        setShowCreateModal(false)
    }

    const handleUpdate = (updated_obj?: Vehicle) => {
        if(rowToUpdate){
            if(updated_obj){
                console.log('update Handled?')
                const dataCopy = [...vehicleList]
                dataCopy.splice(rowToUpdate.index, 1)
                setVehicleList([updated_obj, ...dataCopy])
            }
        }

    }

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Vehicle Directory"}>
                <SearchableTable
                    columns={columns}
                    data={vehicleList}
                    globalSearch={true}
                    actionParam={
                        <Button onClick={handleShowCreate}>Add Vehicle</Button>
                    }
                />
            </StackedContentContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{rowToUpdate? "Update Vehicle": "Create Vehicle"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateVehicle
                        closeModal={handleCloseCreate}
                        vehicleToUpdate={rowToUpdate? rowToUpdate.original : undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageVehiclesPage