// Sets the current user
export const setUser = (username: string, id: string, groups: string[]) => {
    return {
        type: 'SET_USER',
        payload: {
            id,
            username,
            groups,
        },
    };
};
// Logs the current user out
export const logout = () => {
    return {
        type: 'LOGOUT',
    };
};
