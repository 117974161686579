// noinspection SpellCheckingInspection

import React, {useEffect, useMemo, useState} from 'react'
import {Resource} from "../../types";
import {deleteResource, getResources} from "../../api/dataService";
import {Navigate, useNavigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import {ColumnDef, Row} from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Button, ButtonGroup, Col, Modal, Row as BRow} from "react-bootstrap"
import CreateResource from "../CreateResource";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import {checkIsAllowed, isLoadingSpinner} from "../../util/Helpers";


const parentStyle = {
    padding: '2rem',
}


function ManageResourcesPage() {
    const [resourceList, setResourceList] = useState<Resource[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<Resource> | null>(null);
    const [resourceToUpdate, setResourceToUpdate] = useState<Row<Resource> | undefined>()
    const navigate = useNavigate()
    const user = useSelector((state: RootState) => state.user)
    useEffect(() => {
        Promise.all([getResources()])
            .then(([resourceResponse]) => {
                if('redirectTo' in resourceResponse){
                    return(<Navigate to={resourceResponse.redirectTo}/>)
                }
                if(Array.isArray(resourceResponse)){
                    setResourceList(resourceResponse)
                }
                if(resourceList) setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, []);

    const columns = useMemo<ColumnDef<Resource, any>[]>(() =>
            checkIsAllowed(user.groups, ['Ownership_Group', 'Management'])? [
            {
                accessorFn: row => row.resource_name,
                id: 'resource_name',
                header: 'Resource Name',
                enableColumnFilter: true
            },
            {
                accessorFn: row => row.type,
                id: 'resource_type',
                header: 'Resource Type',
                enableColumnFilter: true
            },
            {
                accessorFn: row => row.stock,
                id: 'resource_stock_noholds',
                header: 'Current Stock (No Holds)',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.stock - row.soft_hold - row.hard_hold,
                id: 'resource_stock_holds',
                header: 'Current Stock (After Holds)',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.soft_hold,
                id: 'soft_hold',
                header: 'Soft Hold',
                enableColumnFilter: false
            },
            {
                id: 'hard_hold',
                header: 'Hard Hold',
                enableColumnFilter: false,
                accessorFn: row => row.hard_hold
            },
            {
                id: 'hourly_billing_rate',
                header: 'Hourly Rate',
                enableColumnFilter: false,
                accessorFn: row => row.hourly_billing_rate
            },
            {
                id: 'unit_billing_rate',
                header: 'Per Unit Rate',
                enableColumnFilter: false,
                accessorFn: row => row.unit_billing_rate,
            },
            {
                id: 'actions',
                header: () => 'Actions',
                cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className={'btn-group-sm'}>
                            <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}> Update </Button>
                            <Button className={'btn-danger'} onClick={() => handleDeleteRow(row)}> Delete </Button>
                        </ButtonGroup>
                    </div>
                )
            }
        ] :
                [
            {
                accessorFn: row => row.resource_name,
                id: 'resource_name',
                header: 'Resource Name',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.type,
                id: 'resource_type',
                header: 'Resource Type',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.stock,
                id: 'resource_stock',
                header: 'Current Stock (No Holds)',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.stock - row.soft_hold - row.hard_hold,
                id: 'resource_stock',
                header: 'Current Stock (After Holds)',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.soft_hold,
                id: 'soft_hold',
                header: 'Soft Hold',
                enableColumnFilter: false
            },
            {
                id: 'hard_hold',
                header: 'Hard Hold',
                enableColumnFilter: false,
                accessorFn: row => row.hard_hold
            },
            ],
        []
        )
    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setResourceToUpdate(undefined)
    }
    const handleShowCreate = () => setShowCreateModal(true)
    const handleOpenDetails = (row: any) => {
        setResourceToUpdate(row)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<Resource>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };
    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            deleteResource(selectedRowToDelete.original.id.toString()).then(r =>
                {
                    if(r && !Array.isArray(r)){
                        if(r.status >= 200 || r.status <= 300){
                            const dataCopy = [...resourceList]
                            dataCopy.splice(selectedRowToDelete.index, 1)
                            setResourceList(dataCopy)
                        }
                    }

                }
            )
            setShowDeleteModal(false);
        }
    };

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setResourceList([created_obj,...resourceList])
        }
        setShowCreateModal(false)
    }
    const handleUpdate = (updated_obj?: Resource) => {
        if(resourceToUpdate){
            if(updated_obj){
                const dataCopy = [...resourceList]
                dataCopy.splice(resourceToUpdate.index, 1)
                setResourceList([updated_obj, ...dataCopy])
            }
        }

    }

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Resource Directory"}>

                <SearchableTable
                    columns={columns}
                    data={resourceList}
                    globalSearch={true}
                    actionParam={
                        <BRow>
                            <Col>
                                {
                                    checkIsAllowed(user.groups, ['Ownership_Group', 'Management']) ?
                                    (
                                        <Button onClick={handleShowCreate}>Add resource</Button>
                                    ) : undefined}
                                <Button className={'mx-2'}
                                    onClick={() => navigate('/inventory/')}>
                                    View inventory
                                </Button>
                            </Col>
                        </BRow>
                    }
                />
            </StackedContentContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{resourceToUpdate? "Update Resource": "Create Resource"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateResource
                        closeModal={handleCloseCreate}
                        resourceToUpdate={resourceToUpdate? resourceToUpdate.original : undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageResourcesPage