import React, {useRef, useState} from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {Address, PartialAddress} from "../types";
import * as Yup from 'yup'
import {postAddress, updateAddress} from "../api/dataService";
import {handlePostError} from "../util/Helpers";
import Container from "react-bootstrap/Container";
import {Alert} from "react-bootstrap";
import {ErrorMessage, Field, Formik} from "formik";
interface CreateAddressProps {
    addressToUpdate? : PartialAddress
    closeModal?: (values?: PartialAddress) => void
    onCreate?: (createdObject: PartialAddress) => void
    onUpdate?: (updatedObject: Address) => void
}

const CreateAddress: React.FC<CreateAddressProps> = (props) => {
    const isUpdating = !!props.addressToUpdate
    // const [isLoading, setIsLoading] = useState(false)
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [showSuccess, setShowSuccess] = useState(false)
    const formikRef = useRef<any>(null)
    const validationSchema = Yup.object().shape({
        label: Yup.string(),
        unit: Yup.string(),
        building: Yup.string(),
        street: Yup.string().required('Street Address is required'),
        city: Yup.string().required('City is required'),
        province: Yup.string().required('Province is required'),
        postal_code: Yup.string()
            .required('Postal code is required')
            .matches(/^([A-Z]\d[A-Z]\d[A-Z]\d|^\d{5}(-\d{4})?)$/i, 'Invalid postal code format.')
            .transform((value, originalValue) => {
                if (originalValue) {
                    // Remove all non-alphanumeric characters and convert to uppercase
                    const cleanedValue = originalValue.replace(/\W/g, '').toUpperCase();
                    // Canadian postal code pattern match
                    const canadianMatch = cleanedValue.match(/([A-Z])(\d)([A-Z])(\d)([A-Z])(\d)/i);
                    if (canadianMatch) {
                        return `${canadianMatch[1]}${canadianMatch[2]}${canadianMatch[3]}${canadianMatch[4]}${canadianMatch[5]}${canadianMatch[6]}`;
                    }
                    // American ZIP code pattern match
                    const zipMatch = cleanedValue.match(/(\d{5})(\d{4})?/);
                    if (zipMatch) {
                        return zipMatch[2] ? `${zipMatch[1]}-${zipMatch[2]}` : zipMatch[1];
                    }
                }
                return value;
            }),
        country: Yup.string().required('Country is required'),
    })
    const initialValues = props.addressToUpdate || {
        label: '',
        unit: '',
        building: '',
        street: '',
        city: 'Calgary',
        province: 'Alberta',
        postal_code: '',
        country: 'Canada'
    }


    const handleSubmit = async(values: PartialAddress) => {
        try {
            // setIsLoading(true)
            if(values.postal_code){
                const transformedPostalCode = values.postal_code.toUpperCase().replace(/\W*/g, '');
                if (transformedPostalCode.match(/^[A-Z][0-9][A-Z][0-9][A-Z][0-9]$/)) {
                    values.postal_code = transformedPostalCode;
                } else if(transformedPostalCode.match(/(\d{5})(\d{4})?/)) {
                    values.postal_code = transformedPostalCode;
                } else {
                    // Handle invalid postal code format
                    setErrorMessages(['Invalid postal code format.']);
                    return; // Exit the function to prevent submitting invalid data
                }
            }
            let response = undefined
            if(isUpdating){
                response = await updateAddress(props.addressToUpdate?.id? props.addressToUpdate.id.toString(): '',values)
            }
            else {
                response = await postAddress(values)
            }
            if (response && !Array.isArray(response)) {
                setErrorMessages([])
                setShowSuccess(true)
                if (formikRef.current) formikRef.current.resetForm()
                if (props.closeModal) props.closeModal()
                if (props.onCreate) props.onCreate(response.data)
                if (isUpdating && props.onUpdate) props.onUpdate(response)
            }
            // setIsLoading(false)
        }
        catch (error) {
            setErrorMessages(handlePostError(error, 'Address'))
            // setIsLoading(false)
        }
    }

    // if (isLoading) {
    //     return (isLoadingSpinner());
    // }

    return (
        <Container>
            <Row className={"justify-content-center"}>
                {errorMessages.length > 0 && (
                    <Alert variant={'danger'}>
                        <ul>
                            {errorMessages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        Address created successfully!
                    </Alert>
                )}
            </Row>
            <Row className={'justify-content-center'}>
                <Formik
                    enableReinitialize={true}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group className="mb-3" controlId="label">
                                <Form.Label className={'fw-bold'}>Custom Address Label</Form.Label>
                                <Field name={'label'} type={'text'} placeholder={""} as={Form.Control} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="street">
                                <Form.Label className={'fw-bold'}>Street Address</Form.Label>
                                <Field name={'street'} type={'text'} placeholder={""} as={Form.Control} />
                                <ErrorMessage name={'street'} component={'div'} className={'text-danger'}/>
                            </Form.Group>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="unit">
                                    <Form.Label className={'fw-bold'}>Apartment, Unit, Suite or Floor #</Form.Label>
                                    <Field name={'unit'} type={'text'} placeholder={""} as={Form.Control} />
                                </Form.Group>

                                <Form.Group as={Col} controlId="building">
                                    <Form.Label className={'fw-bold'}>Building #</Form.Label>
                                    <Field name={'building'} type={'text'} placeholder={''} as={Form.Control} />
                                </Form.Group>
                            </Row>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label className={'fw-bold'}>City</Form.Label>
                                <Field name={'city'} type={'text'} placeholder={"Calgary"} as={Form.Control} />
                                <ErrorMessage name={'city'} component={'div'} className={'text-danger'}/>
                            </Form.Group>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="province">
                                    <Form.Label className={'fw-bold'}>Province / State</Form.Label>
                                    <Field name={'province'} type={'text'} placeholder={"Alberta"} as={Form.Control} />
                                    <ErrorMessage name={'province'} component={'div'} className={'text-danger'}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="postal_code">
                                    <Form.Label className={'fw-bold'}>Postal / Zip Code</Form.Label>
                                    <Field name={'postal_code'} type={'text'} placeholder={""} as={Form.Control} />
                                    <ErrorMessage name={'postal_code'} component={'div'} className={'text-danger'}/>
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" controlId="country">
                                <Form.Label className={'fw-bold'}>Country</Form.Label>
                                <Field
                                    name={'country'}
                                    type={'text'}
                                    placeholder={"Canada"}
                                    as={Form.Control}
                                />
                                <ErrorMessage name={'country'} component={'div'} className={'text-danger'}/>
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
                                {isUpdating? 'Update':'Submit'}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Row>
        </Container>

    );
}

export default CreateAddress;