import React, {useEffect, useMemo, useState} from 'react'
import {Address, formatAddress, getAddressLink, WorkEvent, WorkOrder} from "../types";
import {getEvents} from "../api/dataService";
import {Navigate, useNavigate} from "react-router-dom";
import {ColumnDef, Row} from "@tanstack/react-table";
import {checkIsAllowed, getDateFromToday, getTimeString, isLoadingSpinner, isNightShift} from "../util/Helpers";
import SearchableTable from "./SearchableTable";
import {Row as BRow, Col, Pagination, ButtonGroup, Button, Modal} from "react-bootstrap";
import {addDays} from "date-fns";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import {RootState} from "../redux/reduxTypes";
import CreateEvent from "./CreateEvent";


const parentStyle = {
    padding: '2rem',
}
interface DispatchCalendarProps {
    after_date?: string;
    before_date?: string;
}

const DispatchCalendar: React.FC<DispatchCalendarProps> = (props) => {
    const user = useSelector((state: RootState) => state.user)
    const [eventList, setEventList] = useState<WorkEvent[]>([])
    const [filteredEvents, setFilteredEvents] = useState<WorkEvent[]>([])
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [isLoading, setIsLoading] = useState(true)
    const [eventToUpdate, setEventToUpdate] = useState<Row<WorkEvent>>()
    const [showCreateModal, setShowCreateModal] = useState(false)


    const navigate = useNavigate()
    useEffect(() => {
        const after_param = props.after_date? new Date(props.after_date): getDateFromToday(0,-1,0)
        const before_param = props.before_date ? new Date(props.before_date) : getDateFromToday(0,1,0)

        Promise.all([getEvents(undefined,
            `?start_date_after=${after_param}&start_date_before=${before_param}`)])
            .then(([eventResponse]) => {
                if('redirectTo' in eventResponse){
                    return(<Navigate to={eventResponse.redirectTo}/>)
                }
                if(Array.isArray(eventResponse)){
                    setEventList(eventResponse.filter((elem)=> elem.status !== 'deleted'))
                }
                setIsLoading(false);
                setSelectedDate(new Date())
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        const currentFilter = selectedDate.toLocaleDateString()
        const filteredData = eventList.filter((item) => {
            return new Date(item.start_date).toLocaleDateString() === currentFilter
        })
        setFilteredEvents(filteredData)
    }, [selectedDate]);


    const columns = useMemo<ColumnDef<WorkEvent, any>[]>(
        () => [
            {
                accessorFn: row => `${row.work_order? (row.work_order as WorkOrder).billing_client: 'No Client'}`,
                id: 'jobsite_client',
                header: 'Client',
                enableColumnFilter: true
            },
            {
                accessorFn: row => `${row.event_name} ${row.event_description? `- ${row.event_description}`:''}`,
                id: 'event_name_desc',
                header: 'Description',
                enableColumnFilter: false
            },
            {
                id: 'start_address',
                header:'Origin Address',
                enableColumnFilter: false,
                cell: ({row}) => {
                    if(row.original.origin_address){
                        return (
                            <a href={getAddressLink(row.original.origin_address as Address)}>{formatAddress(row.original.origin_address as Address)}</a>
                        )
                    }else{
                        return <p>No Address</p>
                    }
                }
            },
            {
                id: 'end_address',
                header:'Destination Address',
                enableColumnFilter: false,
                cell: ({row}) => {
                    if(row.original.destination_address){
                        return (
                            <a href={getAddressLink(row.original.destination_address as Address)}>{formatAddress(row.original.destination_address as Address)}</a>
                        )
                    }else{
                        return <p>No Address</p>
                    }
                }
            },
            {
                id: 'time',
                header:'Time',
                enableColumnFilter: false,
                cell: ({row}) => {
                    return <p
                        className={isNightShift(row.original.start_date)? 'text-warning':''}
                    >{getTimeString(row.original.start_date)} - {getTimeString(row.original.end_date)}
                    </p>
                }
            },
            {
                id: 'employees',
                header: 'Employees',
                enableColumnFilter: true,
                cell: ({row}) => {
                    const assignedList = row.original.employeeuser_set?.map(employee => employee.first_name)
                        .join(', ');
                    if(assignedList){
                        return (
                            <p>{assignedList}</p>
                        )
                    }else{
                        return <p className={'text-danger'}>No Employees Assigned</p>
                    }
                },
                filterFn:'arrIncludesSome'
            },

            {
                id: 'actions',
                header:'Actions',
                cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                        <ButtonGroup className={'btn-group-sm'}>
                            <Button className={'btn-primary'} onClick={() => navigate(`/events/${row.original.id}`)}>View</Button>
                            {checkIsAllowed(user.groups, ['Ownership_Group', 'Management', 'Office_Admin', 'Dispatch',])?
                                (<>
                                    <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}>Update</Button>
                                </>) : (<></>)
                            }
                        </ButtonGroup>
                    </div>
                )
            }
        ],
        []
    )

    const handleOpenDetails = (row: Row<WorkEvent>) => {
        setEventToUpdate(row)
        setShowCreateModal(true)
    }
    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setEventToUpdate(undefined)
    }
    const handleUpdate = (updated_obj?: WorkEvent) => {
        if(eventToUpdate){
            if(updated_obj){
                const dataCopy = [...eventList]
                dataCopy.splice(eventToUpdate.index, 1)
                setEventList([updated_obj, ...dataCopy])
                console.log(eventList)
            }
        }
    }

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <div style={parentStyle}>
            <SearchableTable columns={columns} data={filteredEvents} globalSearch={false} actionParam={
                <BRow className={'mx-3'}>
                    <Pagination className={"justify-content-center"}>
                        <Col xs lg={2} className={"d-flex align-items-start flex-column"}>
                            <Pagination.First className={"w-auto"} onClick={()=>setSelectedDate(addDays(selectedDate,-1))}/>
                        </Col>
                        <Col md={'auto'} className={"ml-3 my-auto"}>
                            <DatePicker
                                id="start_date"
                                className={'datetime-select form-control'}
                                dateFormat={"yyyy-MM-dd"}
                                selected={
                                    new Date(selectedDate)
                                }
                                onChange={(date: Date) => {
                                    if (date) {
                                        setSelectedDate(date)
                                    }
                                }}
                            />
                        </Col>
                        <Col xs lg={2} className={"d-flex align-items-end flex-column"}>
                            <Pagination.Last className={"w-auto"} onClick={()=>setSelectedDate(addDays(selectedDate,1))}/>
                        </Col>
                    </Pagination>
                </BRow>
            }/>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{eventToUpdate? `Update Event ${eventToUpdate.original.id}`: "Create New Event"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateEvent
                        closeModal={handleCloseCreate}
                        eventToUpdate={eventToUpdate? eventToUpdate.original:undefined}
                        onUpdate={handleUpdate}
                        isQuoting={false}
                        showCrew={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}




export default DispatchCalendar