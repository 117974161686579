import React from "react";
import Container from "react-bootstrap/Container";
import {ParentContainerStyle} from "../../types";
import EventAndTimeList from "../EventAndTimeList";

const EventAndTimePage: React.FC = () => {
    return(
        <Container fluid style={ParentContainerStyle}>
            <EventAndTimeList/>
        </Container>
    )
}
export default EventAndTimePage