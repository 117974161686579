import {Navigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Quote} from "../types";
import {getQuotes} from "../api/dataService";
import QuoteForm from "./pages/QuoteForm";


const ViewQuotePage = () => {
    const {id} = useParams<{ id: string }>();
    const [quote, setQuote]= useState<Quote>()

    useEffect(() => {
        Promise.all([getQuotes(id)])
            .then(([quoteResponse]) => {
                if ('redirectTo' in quoteResponse) {
                    return (<Navigate to={quoteResponse.redirectTo}/>)
                }
                if (!Array.isArray(quoteResponse) && quoteResponse) {
                    setQuote(quoteResponse)
                }
                // setIsLoading(false)
            })
    }, [id]);

    return (
        <QuoteForm savedQuote={quote}/>
    )
}

export default ViewQuotePage;