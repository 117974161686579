import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Routes, Route, Navigate, Outlet} from "react-router-dom";
import Auth from './auth/Auth'
import Login from './components/pages/Login'
import Home from './components/pages/Home'
import DailyTimeCard from "./components/pages/DailyTimeCard"
import {Provider} from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'
import PrivateRoute from "./auth/PrivateRoute";
import QuoteForm from "./components/pages/QuoteForm";
import Navigation from "./components/Navbar";
import QuoteStatusView from "./components/pages/QuoteStatusView";
import {QuoteStatus} from "./types";
import ManageEmployeesPage from "./components/pages/ManageEmployeesPage";
import ManageTimeEntriesPage from "./components/pages/ManageTimeEntriesPage";
import ManageEventsPage from "./components/pages/ManageEventsPage";
import CreateEventPage from "./components/pages/CreateEventPage";
import ManageQuotesPage from "./components/pages/ManageQuotesPage";
import ManageWorkOrdersPage from "./components/pages/ManageWorkOrdersPage";
import WorkOrderPage from "./components/pages/WorkOrderPage";
import CreateTimeEntryPage from "./components/pages/CreateTimeEntryPage";
import CreateClientPage from "./components/pages/CreateClientPage";
import CreateContactPage from "./components/pages/CreateContactPage";
import CreateAddressPage from "./components/pages/CreateAddressPage";
import CreateEmployeePage from "./components/pages/CreateEmployeePage";
import ManageClientsPage from "./components/pages/ManageClientsPage";
import ManageContactsPage from "./components/pages/ManageContactsPage";
import ManageAddressesPage from "./components/pages/ManageAddressesPage";
import CreateResource from "./components/CreateResource";
import ManageResourcesPage from "./components/pages/ManageResourcesPage";
import ViewEventPage from "./components/pages/ViewEventPage";
import ManageDriverTimeEntriesPage from "./components/pages/ManageDriverTimeEntriesPage";
import CreateVehiclePage from "./components/pages/CreateVehiclePage";
import ManageVehiclesPage from "./components/pages/ManageVehiclesPage";
import CreateDriverTimeEntryPage from "./components/pages/CreateDriverTimeEntryPage";
import {DispatchPage} from "./components/pages/DispatchPage";
import EventAndTimePage from "./components/pages/EventAndTimePage";
import ManageInventoryPage from "./components/pages/ManageInventoryPage";
import ManageJobTypesPage from "./components/pages/ManageJobTypesPage";
import ViewQuotePage from "./components/ViewQuotePage";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
// Handles rendering of all routes for the website
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={'/'}>
                <Routes>
                        <Route path='auth' element={<Auth/>}>
                            <Route path='login' element={
                                <PrivateRoute  roles={['-']}>
                                    <Login/>
                                </PrivateRoute>
                            }
                            />
                        </Route>
                    <Route
                        path="/"
                        element={
                            <>
                                <Navigate to="home" replace />
                                <App />
                            </>
                        }
                    />
                    <Route path="/"
                        element={
                            <>
                                <Navigation /> {/* Include Navigation component on all routes */}
                                <Outlet/>
                            </>
                        }
                    >
                        <Route path="home"
                            element={
                                <PrivateRoute roles={['*']}>
                                    <Home />
                                </PrivateRoute>
                            }
                        />
                        {/* Quotes */}
                        <Route path="/quotes/" element={
                            <PrivateRoute roles={['Ownership_Group','Management', 'Sales']}>
                                <ManageQuotesPage/>
                            </PrivateRoute>}
                        />
                        <Route path="quotes/create" element={
                            <PrivateRoute roles={['Ownership_Group','Management', 'Sales']}>
                                <QuoteForm/>
                            </PrivateRoute>}
                        />

                        <Route path="quotes/pushed" element={
                            <PrivateRoute roles={['Ownership_Group','Management', 'Sales']}>
                                <QuoteStatusView label= "Pushed Quotes" filter= {QuoteStatus.pushed} />
                            </PrivateRoute>}
                        />

                        <Route path="quotes/open" element={
                            <PrivateRoute roles={['Ownership_Group','Management', 'Sales']}>
                                <QuoteStatusView label= "Open Quotes" filter={QuoteStatus.opened}/>
                            </PrivateRoute>}
                        />

                        <Route path="quotes/revision" element={
                            <PrivateRoute roles={['Ownership_Group','Management', 'Sales']}>
                                <QuoteStatusView label= "Review Quotes" filter={QuoteStatus.revision}/>
                            </PrivateRoute>}
                        />

                        <Route path="quotes/submitted" element={
                            <PrivateRoute roles={['Ownership_Group','Management', 'Sales']}>
                                <QuoteStatusView label= "Submitted Quotes" filter= {QuoteStatus.submitted} />
                            </PrivateRoute>}
                        />

                        <Route path="quotes/accepted" element={
                            <PrivateRoute roles={['Ownership_Group','Management', 'Sales']}>
                                <QuoteStatusView label= "Accepted Quotes" filter= {QuoteStatus.accepted} />
                            </PrivateRoute>}
                        />

                        <Route path="quotes/rejected" element={
                            <PrivateRoute roles={['Ownership_Group','Management', 'Sales']}>
                                <QuoteStatusView label= "Rejected Quotes" filter= {QuoteStatus.rejected} />
                            </PrivateRoute>}
                        />


                        <Route path="quotes/:id" element={
                            <PrivateRoute roles={['Ownership_Group','Management', 'Sales']}>
                                <ViewQuotePage/>
                            </PrivateRoute>}
                        />

                        {/* Work Orders */}
                        <Route path="/workorders/" element={
                            <PrivateRoute roles={['Ownership_Group',
                                    'Management',
                                    'Sales',
                                    'Office_Admin',
                                    'Dispatch',
                                    'Driver',
                                    'Supervisor'
                                ]}
                            >
                                <ManageWorkOrdersPage/>
                            </PrivateRoute>}
                        />
                        <Route path="/workorders/create/" element={
                            <PrivateRoute roles={['Ownership_Group','Management']}>
                                <QuoteForm/>
                            </PrivateRoute>}
                        />

                        <Route path="/workorders/:id" element={
                            <PrivateRoute roles={['Ownership_Group',
                                'Management',
                                'Sales',
                                'Office_Admin',
                                'Dispatch',
                                'Driver',
                                'Supervisor'
                            ]}>
                                <WorkOrderPage />
                            </PrivateRoute>}
                        />


                        {/* Events */}
                        <Route path="/events/" element={
                            <PrivateRoute roles={['Ownership_Group',
                                'Management',
                                'Sales',
                                'Office_Admin',
                                'Dispatch',
                                'Driver',
                                'Supervisor',
                            ]}>
                                <ManageEventsPage/>
                            </PrivateRoute>}
                        />

                        <Route path="/events/:id" element={
                            <PrivateRoute roles={['Ownership_Group',
                                'Management',
                                'Sales',
                                'Office_Admin',
                                'Dispatch',
                                'Driver',
                                'Supervisor',
                                'Pusher'
                            ]}>
                                <ViewEventPage/>
                            </PrivateRoute>}
                        />
                        <Route path="/events/create" element={
                            <PrivateRoute roles={['Ownership_Group',
                                'Management',
                                'Office_Admin',
                                'Sales'
                            ]}>
                                <CreateEventPage/>
                            </PrivateRoute>
                        }/>
                        <Route path="/events/update/:id" element={
                            <PrivateRoute roles={['Ownership_Group',
                                'Management',
                                'Office_Admin',
                                'Dispatch',
                            ]}>
                                <CreateEventPage/>
                            </PrivateRoute>
                        }/>

                        {/* Time Entries */}
                        <Route path="/personaltimesheet/" element={
                            <PrivateRoute roles={['*']}>
                                <EventAndTimePage/>
                            </PrivateRoute>
                        }/>
                        <Route path="/timesheets/" element={
                            <PrivateRoute roles={['*']}>
                                <ManageTimeEntriesPage/>
                            </PrivateRoute>}
                        />
                        <Route path="/drivertimesheets/" element={
                            <PrivateRoute roles={['Ownership_Group',
                                'Management',
                                'Driver',
                                'Dispatch',
                                'Supervisor'
                            ]}>
                                <ManageDriverTimeEntriesPage/>
                            </PrivateRoute>}
                        />
                        <Route path={"timesheets/:id"} element={
                            <PrivateRoute roles={['*']}>
                                <DailyTimeCard/>
                            </PrivateRoute>
                        } />
                        <Route path={"timesheets/create"} element={
                            <PrivateRoute roles={['*']}>
                                <CreateTimeEntryPage/>
                            </PrivateRoute>
                        } />
                        <Route path={"drivertimesheets/create"} element={
                            <PrivateRoute roles={['Ownership_Group',
                                'Management',
                                'Dispatch',
                                'Driver',
                                'Office_Admin'
                            ]}>
                                <CreateDriverTimeEntryPage/>
                            </PrivateRoute>
                        } />


                        {/* Employees */}
                        <Route path="/employees/" element={
                            <PrivateRoute roles={['Ownership_Group',
                                'Management',
                                'Dispatch',
                                'Sales',
                                'Office_Admin',
                            ]}>
                                <ManageEmployeesPage/>
                            </PrivateRoute>
                        }/>

                        <Route path="/employees/create/" element={
                            <PrivateRoute roles={['Ownership_Group','Management']}>
                                <CreateEmployeePage/>
                            </PrivateRoute>
                        }/>

                        {/* Clients */}
                        <Route path="/clients/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Sales', 'Office_Admin']}>
                                <ManageClientsPage/>
                            </PrivateRoute>
                        }/>
                        <Route path="/clients/create/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Sales']}>
                                <CreateClientPage/>
                            </PrivateRoute>
                        }/>

                        {/* Contacts */}
                        <Route path="/contacts/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Sales', 'Office_Admin']}>
                                <ManageContactsPage/>
                            </PrivateRoute>
                        }/>
                        <Route path="/contacts/create/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Sales', 'Office_Admin']}>
                                <CreateContactPage/>
                            </PrivateRoute>
                        }/>

                        {/* Addresses */}
                        <Route path="/addresses/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Sales','Office_Admin']}>
                                <ManageAddressesPage/>
                            </PrivateRoute>
                        }/>
                        <Route path="/addresses/create/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Sales', 'Office_Admin']}>
                                <CreateAddressPage/>
                            </PrivateRoute>
                        }/>

                        {/* Resources */}

                        <Route path="/resources/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Sales','Office_Admin', 'Dispatch', 'Driver', 'Supervisor']}>
                                <ManageResourcesPage/>
                            </PrivateRoute>
                        }/>
                        <Route path="/resources/create" element={
                            <PrivateRoute roles={['Ownership_Group','Management']}>
                                <CreateResource/>
                            </PrivateRoute>
                        }/>
                        <Route path="/inventory/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Sales','Office_Admin','Dispatch','Driver', 'Supervisor']}>
                                <ManageInventoryPage/>
                            </PrivateRoute>
                        }/>


                        {/* Vehicles */}
                        <Route path="/vehicles/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Office_Admin', 'Driver', 'Supervisor']}>
                                <ManageVehiclesPage/>
                            </PrivateRoute>
                        }/>
                        <Route path="/vehicles/create" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Office_Admin']}>
                                <CreateVehiclePage/>
                            </PrivateRoute>
                        }/>
                        {/* Dispatch Calendar */}
                        <Route path="/dispatch/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Sales','Dispatch','Supervisor','Driver','Office_Admin']}>
                                <DispatchPage/>
                            </PrivateRoute>
                        }/>
                        {/*Job Types*/}
                        <Route path="/jobtypes/" element={
                            <PrivateRoute roles={['Ownership_Group','Management','Office_Admin']}>
                                <ManageJobTypesPage/>
                            </PrivateRoute>
                        }/>


                    </Route>
                </Routes>
            </BrowserRouter>
        </PersistGate>
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
