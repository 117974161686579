import React, {useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Col,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    FormSelect,
    Modal,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    OffcanvasTitle,
    Row,
    Stack,
} from 'react-bootstrap';
import {
    Client,
    EventResourceUse,
    FileStorage,
    JobRequestMethod,
    JobType,
    PartialQuote,
    Quote,
    QuoteStatus,
    User,
    UserQuote,
    UserRoles,
    WorkEvent
} from "../../types";
import StackedContentContainer from "../ContentContainer";
import {ErrorMessage, Field, Formik} from "formik";
import {
    deleteFile,
    getClients,
    getEvents,
    getFiles,
    getJobTypes,
    getQuotePDF, getQuotes,
    getResourceUses,
    getUserQuotes,
    getUsers,
    postQuote,
    updateQuote
} from "../../api/dataService";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import {checkIsAllowed, handlePostError} from "../../util/Helpers";
import CreateClient from "../CreateClient";
import QuotedEventsTable from "./QuotedEventsTable";
import CreateEvent from "../CreateEvent";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import FileStorageUpload from "../FileStorageUpload";
import DeleteConfirmationModal from "../DeleteConfirmationModal";

interface QuoteProp {
    savedQuote?: Quote
}


const QuoteForm: React.FC<QuoteProp> = ({savedQuote}) => {

    const {id} = useParams<{ id: string }>();
    const user = useSelector((state: RootState) => state.user);
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const formikRef = useRef<any>(null)
    const navigate = useNavigate()
    const [showSuccess, setShowSuccess] = useState(false)
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [showFileAttachments, setShowFileAttachments] = useState(false)
    const [showClientModal, setShowClientModal] = useState(false)
    const [showEventModal, setShowEventModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedBillClient, setSelectedBillClient] = useState<Client>()
    const [selectedJobClient, setSelectedJobClient] = useState<Client>()
    const [clientData, setClientData] = useState<Client[]>([])
    const [userQuoteList, setUserQuoteList] = useState<UserQuote[]>([])
    const [userList, setUserList] = useState<User[]>([])
    const [jobTypeList, setJobTypeList] = useState<JobType[]>([])
    const [currentFileDelete, setCurrentFileDelete] = useState<string[]>([])
    const [fileData, setFileData] = useState<FileStorage[]>([])
    const [eventData, setEventData] = useState<Array<[Partial<WorkEvent>, EventResourceUse[]]>>([]);

    useEffect(() => {
        // READ-ONLY vs READ/WRITE Quote Permission logic
        if (savedQuote && (
            savedQuote.quote_status === QuoteStatus.submitted ||
            savedQuote.quote_status === QuoteStatus.rejected ||
            savedQuote.quote_status === QuoteStatus.accepted
        )) {
            setIsDisabled(true);
            // Sales role (or any role other than management or ownership)
        } else if (savedQuote && !checkIsAllowed(user.groups, [UserRoles.management, UserRoles.ownership])) {
            // Check if this quote is a quote created by the current sales user
            const userQuote = userQuoteList.find(userQuote => userQuote.quote === savedQuote.id)

            // Ensures that sales users (or any other non-admin user) cannot edit a quote if it has been pushed
            if (savedQuote.quote_status === QuoteStatus.pushed) {
                setIsDisabled(true)
            } else if (userQuote) {
                // Allow sales to edit quotes they have created
                setIsDisabled(false)
            } else {
                setIsDisabled(true)
            }

        } else {
            setIsDisabled(false);
        }

    }, [userQuoteList, id, user, savedQuote]);


    useEffect(() => {
        Promise.all([getClients(), getJobTypes()])
            .then(([clientResponse, jobTypeResponse]) => {
                if (jobTypeResponse && Array.isArray(jobTypeResponse)) {
                    setJobTypeList(jobTypeResponse)
                }

                if (clientResponse && Array.isArray(clientResponse)) {
                    // Set the clientData state
                    setClientData(clientResponse);

                    // When an existing quote instance is passed using a state object, then form values must be initialized to saved quote data
                    if (savedQuote) {
                        // Retrieve saved event data on a quote, along with saved file attachments
                        Promise.all([getEvents(undefined, `?quote=${savedQuote.id}`), getFiles(undefined, `?quote_form=${savedQuote.id}`), getUserQuotes(undefined, `?user=${user.userid}`), getUsers(), getJobTypes()])
                            .then(async ([eventResponse, filesResponse, userQuoteResponse, userResponse, jobTypeResponse]) => {
                                if ('redirectTo' in userQuoteResponse) {
                                    return <Navigate to={userQuoteResponse.redirectTo}/>
                                }

                                // List of quote id's created by the current user
                                if (Array.isArray(userQuoteResponse)) {
                                    setUserQuoteList(userQuoteResponse)
                                }

                                if (eventResponse && Array.isArray(eventResponse)) {

                                    // For each event retrieve resource information
                                    for await (const event of eventResponse) {
                                        Promise.all([getResourceUses(undefined, `?event=${event.id}`)])
                                            .then(([resourceResponse]) => {
                                                if (resourceResponse && Array.isArray(resourceResponse)) {
                                                    setEventData(prevData => [...prevData, [event, resourceResponse]]);
                                                }
                                            })
                                    }
                                }

                                if (filesResponse && Array.isArray(filesResponse)) {
                                    setFileData(filesResponse)
                                }

                                if (userResponse && Array.isArray(userResponse)) {
                                    setUserList(userResponse)
                                    // Set Dropdown values
                                }
                            })

                        clientResponse.forEach((client) => {
                            // Set full client objects
                            if (client.client_name === savedQuote.billing_client) {
                                setSelectedBillClient(client);
                            }

                            if (client.client_name === savedQuote.jobsite_client) {
                                setSelectedJobClient(client);
                            }
                        });

                    } else {
                        // Reset form
                        if (formikRef.current) {
                            formikRef.current.resetForm()
                            setIsDisabled(false);
                            setEventData([]);
                            setSelectedBillClient(undefined);
                            setSelectedJobClient(undefined);
                        }
                    }
                }
            });
    }, [user.userid, id, savedQuote]);





    const validationSchema = Yup.object().shape({
        billing_client: Yup.string().required('Billing Client is required'),
        jobsite_client: Yup.string().required('Jobsite Client is required'),
        jobtype_set: Yup.array().min(1, 'Must select at least one job type'),
        job_request_method: Yup.string().required('Job request method is required'),
    })

    const initialValues: PartialQuote = {
        jobtype_set: [],
        job_notes: '',
        internal_feedback: '',
        special_skillsets: '',
        tools_required: '',
        speciality_equipment_required: '',
        consumables_notes: '',
        quoted_number: 0,
        quote_status: QuoteStatus.none,
        job_request_method: JobRequestMethod.none,
        revision_number: 0
    }


    const setCost = (totalCost: number) => {
        if (formikRef.current) {
            formikRef.current.setFieldValue('quoted_number', totalCost)
        }
    }

    const handleSubmit = async (values: PartialQuote) => {

        try {
            let response = undefined
            if(eventData && id){
                let data:any = values
                data.presented_by = values.presented_by? values.presented_by.id: undefined
                response = await updateQuote(id, data, eventData)
            }
            else {
                response = await postQuote(values, eventData)
            }


            if (response && !Array.isArray(response)) {
                if (response.status >= 200 && response.status < 300) {
                    setErrorMessages([])
                }
                // Reset form
                if (formikRef.current) {
                    formikRef.current.resetForm()
                    setIsDisabled(false);
                    setEventData([]);
                    setSelectedBillClient(undefined);
                    setSelectedJobClient(undefined);
                }
                setShowSuccess(true)

                // Navigate to quote table depending on the quote status
                navigate(`../../quotes/${values.quote_status ? values.quote_status : 'open'}`);
            }
        } catch (error) {
            setErrorMessages(handlePostError(error, 'Quote'))
        }
    }

    const handleCreateClient = (created_obj?: any) => {
        if (created_obj) {
            setClientData([created_obj, ...clientData])
        }
        setShowClientModal(false)
    }

    const handleNewEventUpdate = (created_obj: any, assignedResources: EventResourceUse[], eventDataIndex: number) => {
        const updatedEventData = [...eventData]; // Create a copy of the array
        updatedEventData.splice(eventDataIndex, 1); // Remove the item at the specified index
        updatedEventData.push([created_obj, assignedResources])
        setEventData(updatedEventData);
        setShowEventModal(false)
    }

    const handleEventDelete = (created_obj: any, eventDataIndex: number) => {
        const updatedEventData = [...eventData]; // Create a copy of the array
        updatedEventData.splice(eventDataIndex, 1); // Remove the item at the specified index
        setEventData(updatedEventData);
        setShowEventModal(false)
    }

    const handleFileUpload = (fileData: FileStorage[]) => {
        setFileData(prevData => [...prevData, ...fileData]);
    }


    const handleFileDelete = async (file_id: string, file_name: string) => {

        const response = await deleteFile(file_id)

        if (response) {
            const updatedFileData = [...fileData]; // Create a copy of the array
            const index = fileData.findIndex(file => file.file === file_name);
            if (index !== -1) {
                updatedFileData.splice(index, 1);
                setFileData(updatedFileData);
            }
        }
    }
    
    return (
        <StackedContentContainer>
            <Formik innerRef={formikRef} validationSchema={validationSchema} enableReinitialize={true}
                    initialValues={savedQuote || initialValues} onSubmit={handleSubmit}>
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        {showSuccess && (
                            <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                                Quote created or Saved successfully!
                            </Alert>
                        )}
                        {errorMessages.length > 0 && (
                            <Alert variant="danger">
                                <ul>
                                    {errorMessages.map((message, index) => (
                                        <li key={index}>{message}</li>
                                    ))}
                                </ul>
                            </Alert>
                        )}

                        <h2 className="text-center">{savedQuote ? `Quote # ${savedQuote.id}` : "Create a Quote"}</h2>

                        {savedQuote ?
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="text-nowrap fw-semibold">Status - <span
                                    className="text-uppercase">{savedQuote.quote_status}</span></div>
                                <div className="text-nowrap d-flex justify-content-center align-items-center">
                                    <div className="fw-semibold">Sales Representative -</div>
                                    <Select
                                        name="presented_by"
                                        className="form-select p-2"
                                        defaultValue={savedQuote && savedQuote.presented_by ? {
                                            label: savedQuote.presented_by.first_name + savedQuote.presented_by.last_name,
                                            value: savedQuote.presented_by
                                        } : {}}
                                        options={userList
                                            .map(entry => ({
                                                label: `${entry.last_name}, ${entry.first_name}`,
                                                value: entry
                                            }))
                                            .sort((a, b) => a.label.localeCompare(b.label))} // Sort alphabetically
                                        onChange={(selectedOptions) => {
                                            // Set the BillClient useState to set/trigger client autofill information
                                            if (selectedOptions) {
                                                formik.setFieldValue('presented_by', selectedOptions ? selectedOptions.value : '')
                                            }
                                        }}
                                        isDisabled={isDisabled}
                                    />
                                </div>


                                <Button
                                    onClick={async () => {
                                        await getQuotePDF(savedQuote.id)
                                    }}
                                    className="btn-secondary"
                                    style={{whiteSpace: 'nowrap'}}
                                >
                                    Download PDF
                                </Button>
                            </div>
                            : ""}

                        {savedQuote ?
                            <hr/> : ""
                        }
                        <Stack gap={4}>
                            <FormGroup controlId="billing_client">
                                <p className="fw-bold">Billing Details</p>
                                <FormLabel>Billing Client</FormLabel>
                                <div className="input-group">
                                    <Select
                                        name="billing_client"
                                        className="form-select p-2"
                                        value={{
                                            label: selectedBillClient?.client_name,
                                            value: selectedBillClient?.client_name
                                        }}
                                        options={clientData
                                            .map(entry => ({
                                                label: entry.client_name,
                                                value: entry.client_name
                                            }))
                                            .sort((a, b) => a.label.localeCompare(b.label))} // Sort alphabetically
                                        onChange={(selectedOptions) => {

                                            // Set the BillClient useState to set/trigger client autofill information
                                            if (selectedOptions) {
                                                setSelectedBillClient(clientData.find(client => client.client_name === selectedOptions.value))
                                                formik.setFieldValue('billing_client', selectedOptions ? selectedOptions.value : '')
                                            }
                                        }}
                                        isDisabled={isDisabled}
                                    />

                                    <Button disabled={isDisabled} onClick={() => {
                                        setShowClientModal(true)
                                    }}>Add Client</Button>
                                </div>
                                <ErrorMessage name="billing_client" component="div" className="text-danger"/>
                            </FormGroup>

                            <FormGroup controlId="billingInfoAutoFill">
                                <FormLabel>Billing Contact Full Name</FormLabel>
                                <Field as={FormControl}
                                       value={selectedBillClient ? selectedBillClient.contact.contact_name : ""}
                                       onChange={formik.handleChange} disabled/>
                                <Row></Row>
                                <FormLabel>Billing Contact Phone Number</FormLabel>
                                <Field as={FormControl}
                                       value={selectedBillClient ? selectedBillClient.contact.contact_phone_number : ""}
                                       onChange={formik.handleChange} disabled/>
                                <Row>
                                    <Col>
                                        <FormLabel>Street</FormLabel>
                                        <Field as={FormControl}
                                               value={selectedBillClient ? selectedBillClient.address.street : ""}
                                               onChange={formik.handleChange} disabled/>
                                    </Col>
                                    <Col>
                                        <FormLabel>Postal Code</FormLabel>
                                        <Field as={FormControl}
                                               value={selectedBillClient ? selectedBillClient.address.postal_code : ""}
                                               onChange={formik.handleChange} disabled/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormLabel>Country</FormLabel>
                                        <Field as={FormControl}
                                               value={selectedBillClient ? selectedBillClient.address.country : ""}
                                               onChange={formik.handleChange} disabled/>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <hr/>
                            <p className="fw-bold">Job Details</p>
                            <FormGroup controlId="jobsite_client">
                                <FormLabel>Jobsite Client</FormLabel>
                                <div className="input-group">
                                    <Select
                                        name="jobsite_client"
                                        className="form-select p-2"
                                        value={{
                                            label: selectedJobClient?.client_name,
                                            value: selectedJobClient?.client_name
                                        }}
                                        options={clientData.map(entry => ({
                                            label: entry.client_name,
                                            value: entry.client_name
                                        })).sort((a, b) => a.label.localeCompare(b.label))}
                                        onChange={(selectedOptions) => {

                                            // Set the JobSiteClient useState to set/trigger client autofill information
                                            if (selectedOptions) {
                                                setSelectedJobClient(clientData.find(client => client.client_name === selectedOptions.value))
                                                formik.setFieldValue('jobsite_client', selectedOptions ? selectedOptions.value : '')
                                            }
                                        }}
                                        isDisabled={isDisabled}
                                    />

                                    <Button disabled={isDisabled} onClick={() => {
                                        setShowClientModal(true)
                                    }}>Add Client</Button>
                                </div>
                                <ErrorMessage name="jobsite_client" component="div" className="text-danger"/>
                            </FormGroup>

                            <FormGroup controlId="jobsiteClientInfoAutoFill">
                                <FormLabel>Jobsite Contact Full Name</FormLabel>
                                <Field as={FormControl}
                                       value={selectedJobClient ? selectedJobClient.contact.contact_name : ""}
                                       onChange={formik.handleChange} disabled/>
                                <Row></Row>
                                <FormLabel>Jobsite Contact Phone Number</FormLabel>
                                <Field as={FormControl}
                                       value={selectedJobClient ? selectedJobClient.contact.contact_phone_number : ""}
                                       onChange={formik.handleChange} disabled/>
                                <Row>
                                    <Col>
                                        <FormLabel>Street</FormLabel>
                                        <Field as={FormControl}
                                               value={selectedJobClient ? selectedJobClient.address.street : ""}
                                               onChange={formik.handleChange} disabled/>
                                    </Col>
                                    <Col>
                                        <FormLabel>Postal Code</FormLabel>
                                        <Field as={FormControl}
                                               value={selectedJobClient ? selectedJobClient.address.postal_code : ""}
                                               onChange={formik.handleChange} disabled/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormLabel>Country</FormLabel>
                                        <Field as={FormControl}
                                               value={selectedJobClient ? selectedJobClient.address.country : ""}
                                               onChange={formik.handleChange} disabled/>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup controlId="jobRequestMethod">
                                <FormLabel>Job Request Method</FormLabel>
                                <Field disabled={isDisabled} as={FormSelect} name="job_request_method"
                                       onChange={formik.handleChange}>
                                    {Object.entries(JobRequestMethod).map(([key, value], index) => {
                                        return <option key={index} value={value}>{value}</option>
                                    })}
                                </Field>
                                <ErrorMessage name="job_request_method" component="div" className="text-danger"/>
                            </FormGroup>
                            <FormGroup controlId="jobtype_set">
                                <FormLabel>Job Type</FormLabel>
                                <Row md={8}>

                                    {savedQuote ?

                                    <div><Select
                                        name="jobtype_set"
                                        defaultValue={savedQuote ? (savedQuote.jobtype_set as Array<string>).map(job => ({
                                            label: job,
                                            value: job,
                                        })) : [ {label: 'undef' ,
                                            value: 'undef'}]}
                                        options={jobTypeList.map(entry => ({
                                            label: entry.name,
                                            value: entry.name,
                                        })).sort((a, b) => a.label.localeCompare(b.label))}
                                        isMulti
                                        onChange={(selectedOptions) => {
                                            const selectedValues = selectedOptions.map(option => option.value)
                                            formik.setFieldValue('jobtype_set', selectedValues)
                                        }}
                                        isDisabled={isDisabled}
                                    /></div> : <Select
                                            name="jobtype_set"
                                            options={jobTypeList.map(entry => ({
                                                label: entry.name,
                                                value: entry.name,
                                            })).sort((a, b) => a.label.localeCompare(b.label))}
                                            isMulti
                                            onChange={(selectedOptions) => {
                                                const selectedValues = selectedOptions.map(option => option.value)
                                                formik.setFieldValue('jobtype_set', selectedValues)
                                            }}
                                            isDisabled={isDisabled}
                                        />}

                                </Row>
                                <ErrorMessage name="jobtype_set" component="div" className="text-danger"/>
                            </FormGroup>

                            <div>
                                <FormLabel>Special Skillsets Required</FormLabel>
                                <Field disabled={isDisabled} name="special_skillsets" as={FormControl}
                                       onChange={formik.handleChange}/>
                                <FormLabel>Additional Job Site Notes</FormLabel>
                                <Field disabled={isDisabled} name="job_notes" as={FormControl}
                                       onChange={formik.handleChange}/>
                            </div>

                            <hr/>

                            <p className="fw-bold">Quote Details</p>

                            <FormGroup controlId="events">
                                <Card>
                                    <Card.Header>Events</Card.Header>
                                    <Card.Body>
                                        <QuotedEventsTable
                                            disabled={isDisabled}
                                            handleEventDelete={handleEventDelete}
                                            handleNewEventUpdate={handleNewEventUpdate}
                                            eventData={eventData}
                                            costFn={setCost}
                                        />
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button disabled={isDisabled} onClick={() => {
                                            setShowEventModal(true)
                                        }} variant="success">Add Event</Button>
                                    </Card.Footer>
                                </Card>
                            </FormGroup>

                            <Row className="p-2">
                                <Col>
                                    <Row><p className="fw-bold">Additional File Attachments</p></Row>
                                </Col>
                                <Col>
                                    <center><Button className="btn-sm" onClick={() => setShowFileAttachments(true)}>Add/View
                                        File Attachments</Button></center>
                                    <center><Row className="text-secondary"><p> {fileData.length} attachment(s)</p>
                                    </Row></center>

                                </Col>
                            </Row>


                            <hr/>
                            <ButtonGroup className="d-flex gap-2">
                                <Button type="submit" disabled={isDisabled}>Save Quote</Button>
                            </ButtonGroup>
                        </Stack>
                    </Form>
                )}

            </Formik>

            {/*MODALS / POP UP WINDOWS */}

            <Modal show={showEventModal} onHide={() => {
                setShowEventModal(false)
            }} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{`Create an Event`}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <CreateEvent
                        submitEvent={false}
                        closeModal={() => setShowEventModal(false)}
                        onCreate={(createdObject, assignedEmployeeList, assignedResource: EventResourceUse[]) => {

                            // Update Event Data with new Event and Resource Data
                            setEventData(prevData => [...prevData, [createdObject, assignedResource]]);
                            setShowEventModal(false)
                        }}
                        isQuoting={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowEventModal(false)
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showClientModal} onHide={() => {
                setShowClientModal(false)
            }} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{"Create Client"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateClient onCreate={handleCreateClient} closeModal={() => {
                        setShowClientModal(false)
                    }}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowClientModal(false)
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Offcanvas show={showFileAttachments} onHide={() => setShowFileAttachments(false)}>
                <OffcanvasHeader closeButton>
                    <OffcanvasTitle>File Attachments</OffcanvasTitle>
                </OffcanvasHeader>
                <OffcanvasBody>
                    <Row className="p-2">
                        <Col><p className="fw-bold">File Attachment(s)</p></Col>
                        {
                            fileData ? fileData.map((file, index) => {
                                const fileName = file.file.substring(file.file.lastIndexOf('/') + 1);
                                return (
                                    <Row key={index} className="p-2"><Col><a href={file.file}
                                                                             target="_blank"
                                                                             rel="noopener noreferrer">{fileName.split('_').slice(2).join('_')}</a></Col><Col>{!checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management, UserRoles.sales]) ? '' :
                                        <Button onClick={() => {
                                            setCurrentFileDelete([file.id, file.file])
                                            setShowDeleteModal(true)
                                        }} className="btn-sm btn-danger">X</Button>}</Col></Row>)
                            }) : ""
                        }
                        {
                            id ? <FileStorageUpload onFileUpload={handleFileUpload} quote_id={id}
                                                    disabled={!checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management, UserRoles.sales])}/> :
                                <p className="text-center">Unable to attach files on an unsaved Quote</p>
                        }
                    </Row>
                </OffcanvasBody>
            </Offcanvas>

            <DeleteConfirmationModal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} onConfirm={() => {
                handleFileDelete(currentFileDelete[0], currentFileDelete[1])
                setShowDeleteModal(false)
                setFileData([])

            }}/>

        </StackedContentContainer>
    )
};
export default QuoteForm;