import React, {useEffect, useMemo, useState} from 'react';
import {getInventory, updateInventoryCount,} from "../../api/dataService";
import {useNavigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import SearchableTable from "../SearchableTable";
import {Button, Col, Row as BRow} from "react-bootstrap";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import {isLoadingSpinner} from "../../util/Helpers";
import {DefaultCell, EditableCell} from "../EditableCell";
import {ColumnDef} from "@tanstack/react-table";
import {Inventory} from "../../types";
import Form from 'react-bootstrap/Form';


const parentStyle = {
    padding: '2rem',
};

interface DisplayInventory {
    [key: string]: any;  // Use 'any' for dynamic keys
    address: string;
}


const ManageInventoryPage = () => {
    const [filterZeros, setFilterZeros] = useState(true);
    const [inventoryList, setInventoryList] = useState<DisplayInventory[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [footerData, setFooterData] = useState<{
        stockValues: { [key: string]: number },
        diff: { [key: string]: number }
    }>({
        stockValues: {},
        diff: {}
    });
    const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.user);


    useEffect(() => {
        getInventory().then(inventoryResponse => {
            if ('redirectTo' in inventoryResponse) {
                navigate(inventoryResponse.redirectTo);
            } else if (Array.isArray(inventoryResponse)) {
                const formattedList = transformInventoryData(inventoryResponse);
                setInventoryList(formattedList);
                const footerData = calculateDifferences(formattedList);
                setFooterData(footerData);
            }
            setIsLoading(false);
        }).catch(error => {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        });
    }, []);

    const transformInventoryData = (inventoryData: Inventory[]): DisplayInventory[] => {
        const addressMap: { [key: string]: DisplayInventory } = {};

        inventoryData.forEach(({id, quantity, resource, address}) => {
            if (!resource) {
                console.error('Missing resource data:', resource);
                return; // Skip this item if it lacks essential resource data
            }
            const addressLabel = address.label || `${address.street}, ${address.city}, ${address.province}`;
            if (!addressMap[addressLabel]) {
                addressMap[addressLabel] = {
                    address: addressLabel
                };
            }
            // Now we directly place the resource under the address without _items intermediary
            addressMap[addressLabel][resource.resource_name] = {
                quantity: parseFloat(quantity.toString()),
                resource,
                id  // Store the inventory item's ID here for easy access during updates
            };
        });

        return Object.values(addressMap);
    };

    const calculateDifferences = (inventory: DisplayInventory[]): {
        stockValues: { [key: string]: number },
        diff: { [key: string]: number }
    } => {
        const stockValues: { [key: string]: number } = {};
        const diff: { [key: string]: number } = {};

        inventory.forEach(item => {
            Object.keys(item).forEach(key => {
                if (key !== 'address' && item[key] && item[key].resource) {
                    const quantity = item[key].quantity ?? 0;
                    const stock = item[key].resource?.stock ?? 0; // Safe access using optional chaining

                    if (stockValues[key] === undefined) {
                        stockValues[key] = stock;
                        diff[key] = stock - quantity;
                    } else {
                        diff[key] -= quantity;
                    }
                }
            });
        });

        return {stockValues, diff};
    };

    const updateInventory = async (addressLabel: string, resourceName: string, newQuantity: number) => {
        let itemId;
        const updatedList = inventoryList.map(item => {
            if (item.address === addressLabel) {
                const resourceInfo = item[resourceName];
                console.log(resourceInfo)
                if (resourceInfo) {
                    itemId = resourceInfo.id;  // Capture the ID for backend update
                    return {
                        ...item,
                        [resourceName]: {
                            ...resourceInfo,
                            quantity: newQuantity
                        }
                    };
                }
            }
            return item;
        });

        setInventoryList(updatedList);
        // Recalculate footer data
        const newFooterData = calculateDifferences(updatedList);
        setFooterData(newFooterData);
        // Perform backend update using the captured ID
        if (itemId) {
            console.log(itemId)
            try {
                const result = await updateInventoryCount(itemId, {quantity: newQuantity})
                    .then(response => {
                        console.log(response)
                        return response
                    })
                console.log(result)
            } catch (error) {
                console.log(error)
            }
        }
    };

    const getFilteredInventory = useMemo(() => {
        if (!filterZeros) return inventoryList;
        return inventoryList.filter(item => {
            return Object.keys(item).some(key => key !== 'address' && item[key] && !(item[key].quantity === 0));
        });
    }, [filterZeros, inventoryList]);

    const toggleZeroFilter = () => {
        setFilterZeros(!filterZeros);
    };


    const columns = useMemo(() => {
        let cols: ColumnDef<DisplayInventory, any>[] = [
            {
                accessorKey: 'address',
                id: 'address',
                header: 'Address',
                enableColumnFilter: false,
                cell: ({getValue}) => <DefaultCell value={getValue()}/>
            },
        ];

        if (inventoryList.length > 0) {
            const resourceNames = Object.keys(inventoryList[0]).filter(key => key !== 'address');
            resourceNames.forEach(resource => {
                cols.push({
                    accessorKey: resource,
                    id: resource,
                    header: resource,
                    enableColumnFilter: false,
                    cell: info => {
                        // Access the quantity directly for rendering
                        const quantity = info.getValue()?.quantity ?? '';
                        return <EditableCell value={quantity} row={info.row} column={info.column}
                                             updateInventory={updateInventory}/>
                    }
                });
            });
        }

        return cols;
    }, [inventoryList, updateInventory]);

    if (isLoading) {
        return isLoadingSpinner();
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Inventory"}>
                <SearchableTable
                    columns={columns}
                    data={getFilteredInventory}
                    globalSearch={true}
                    actionParam={
                        <BRow>
                            <Col className={"my-auto mx-2"}>
                                <Form.Check
                                    label="Filter Zeros"
                                    checked={filterZeros}
                                    type="checkbox"
                                    onChange={toggleZeroFilter}
                                />
                            </Col>
                            <Col>
                                <Button className={'mx-2'} onClick={() => navigate('/resources/')}>
                                    View resources
                                </Button>
                            </Col>
                        </BRow>
                    }
                    customFooter={
                        footerData && (
                            <tfoot>
                            <tr>
                                <td><strong>Equipment Totals</strong></td>
                                {Object.entries(footerData.stockValues).map(([resource, stock]) => (
                                    <td key={resource}><strong>{stock}</strong></td>  // Displaying the stock per resource
                                ))}
                            </tr>
                            <tr>
                                <td><strong>Inventory Differences</strong></td>
                                {Object.entries(footerData.diff).map(([resource, diff]) => {
                                    const color = diff < 0 ? "text-danger" : (diff === 0 ? "text-success" : "text-primary");
                                    return (
                                        <td key={resource}>
                                            <p className={color}>{diff}</p>
                                        </td>
                                    );
                                })}
                            </tr>
                            </tfoot>
                        )
                    }
                    hideFilters={true}
                />
            </StackedContentContainer>
        </div>
    );
};

export default ManageInventoryPage;
