// noinspection SpellCheckingInspection

import React, {ChangeEvent, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {handlePostError} from "../util/Helpers";
import {postFile} from "../api/dataService";
import {FileStorage} from "../types";
import {Alert} from "react-bootstrap";
import {useRef} from "react";
import { v4 as uuidv4 } from 'uuid';
import Row from "react-bootstrap/Row";



interface FileStorageUploadProps {
    onFileUpload?: (uploadedFiles: FileStorage[]) => void,
    disabled?: boolean,
    quote_id?: string,
    workorder_id?: string,
    invoice_id?: string
}
const FileStorageUpload: React.FC<FileStorageUploadProps>= ({
    onFileUpload,
    disabled,
    quote_id,
    workorder_id,
    invoice_id}) => {

    const MAX_COUNT = 10

    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png",
        "image/heic",
        "application/pdf"
    ];

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
    const [fileLimit, setFileLimit] = useState(false);
    const fileInputRef: React.RefObject<HTMLInputElement> = useRef(null);
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [responseData, setResponseData] = useState<FileStorage[]>([])

    useEffect(() => {
        setResponseData([])
    }, [onFileUpload]);


    useEffect(() => {
        if (responseData.length > 0) {
            if (onFileUpload) onFileUpload(responseData);
        }
    }, [responseData]);


    const handleUploadFiles = (files: File[]) => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)

    }

    const handleFileEvent =  (e: ChangeEvent<HTMLInputElement>) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }

    const handleSubmit = (uploadedFiles: File[]) => {
        uploadedFiles.forEach(async (file) => {
            const shortRandomString = uuidv4().substring(0, 6);
            const uniqueFileName = `${Date.now()}_${shortRandomString}_${file.name}`;
            const formData = new FormData()
            formData.append("file", file,uniqueFileName)
            if (quote_id) formData.append("quote_form", quote_id)
            if (invoice_id) formData.append("InvoiceForm_form", invoice_id)
            if (workorder_id) formData.append("workorder_form", workorder_id)

            try {
                const response = await postFile(formData);

                if (response && !Array.isArray(response)) {
                    setResponseData(prevData => [...prevData, response]);
                } else {
                    setErrorMessages([...errorMessages, `Failed to Upload ${file.name}`]);
                }
            } catch (error) {
                handlePostError(error, 'FileUpload');
            }
        })

        if (errorMessages.length === 0) {
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Reset the value
                fileInputRef.current.files = null; // Reset the value
            }
            setUploadedFiles([])
        }
    }


    return (
        <div>
            <Form>
                <Row>
            <input id='fileUpload' type='file' multiple
                   // accept={SUPPORTED_FORMATS.join(", ")}
                   onChange={handleFileEvent}
                   disabled={disabled}
                   ref={fileInputRef}
            />
                </Row>

                <Row className="p-4">
                <Button  disabled={disabled} className={`btn btn-primary ${!fileLimit ? '' : 'disabled' }`} onClick={() => handleSubmit(uploadedFiles)}>Upload Files</Button>
                </Row>

            </Form>

            <div className="uploaded-files-list">
                {uploadedFiles.map(file => (
                    <div >
                        {file.name}
                    </div>
                ))}
            </div>

            {errorMessages.length > 0 && (
                <Alert variant="danger">
                    <ul>
                        {errorMessages.map((message, index) => (
                            <li key={index}>{message}</li>
                        ))}
                    </ul>
                </Alert>
            )}        </div>
    );
}


export default FileStorageUpload