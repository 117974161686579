import {EventResourceUse, formatAddress, getAddressLink, Resource, TimeSheet, WorkEvent} from "../types";
import React, {useState} from "react";
import { Button, Col, Container, Modal, Row, Tab, Tabs} from "react-bootstrap";
import {getDate, getDateString, getElapsedHours, getTimeString} from "../util/Helpers";
import CreateTimeEntry from "./CreateTimeEntry";
import {useSelector} from "react-redux";
import {RootState} from "../redux/reduxTypes";

interface EventViewProps {
    closeModal?: () => void,
    eventToView: WorkEvent,
    timeEntries: TimeSheet[],
    resourcesOnEvent?: EventResourceUse[]
}

const ViewEvent: React.FC<EventViewProps> = ({closeModal,resourcesOnEvent, eventToView, timeEntries}) => {
    const [showModal, setShowModal] = useState(false)
    const [selectedTimesheet, setSelectedTimesheet] = useState<TimeSheet | undefined>(undefined)
    const [timesheetList, setTimesheetList] = useState<TimeSheet[]> (timeEntries)
    const user = useSelector((state: RootState) => state.user)
    const canViewAssigned = user.groups.some(element =>
        ['Supervisor', 'Dispatch','Office_Admin','Ownership_Group'].includes(element)
    )


    const handleCloseModal = () => {
        setShowModal(false)
        setSelectedTimesheet(undefined)
    }

    const handleShowModal = () => setShowModal(true)
    const handleOpenDetails = (timesheet: any) => {
        setSelectedTimesheet(timesheet)
        setShowModal(true)
    }
    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setTimesheetList([created_obj,...timesheetList])
        }
        setShowModal(false)
    }
    const handleUpdate = (updated_obj?: TimeSheet) => {
        console.log(updated_obj)
        // if(selectedRowToUpdate){
        //     if(updated_obj){
        //         const dataCopy = [...timesheetList]
        //         dataCopy.splice(selectedRowToUpdate.index, 1)
        //         setTimesheetList([updated_obj, ...dataCopy])
        //     }
        // }

    }

    return (
        <Container className={'text-center'}>
            <Tabs defaultActiveKey="details" className="mb-3" justify>
                <Tab eventKey="details" title="Details">
                    <Row className={"my-3 text-center"}>
                        <h4 className={'fw-bold'}>{eventToView.jobsite_client? `${eventToView.jobsite_client} - `:""}{eventToView.event_name}</h4>
                    </Row>
                    <hr/>
                    <Row className="mb-3">
                        <Col>
                            <p className="fw-bold">Start Date & Time</p>
                            <p> {getDate(eventToView.start_date)}, <strong>{getTimeString(eventToView.start_date)}</strong></p>
                        </Col>
                        <Col>
                            <p className="fw-bold">End Date & Time</p>
                            <p> {getDate(eventToView.end_date)}, <strong>{getTimeString(eventToView.end_date)}</strong></p>
                        </Col>
                    </Row>
                    <hr/>
                    {eventToView.supervisor?
                        (<Row className="mb-3">
                            <Col>
                                <p>
                                    <strong>Supervisor: </strong>{`${eventToView.supervisor.first_name} ${eventToView.supervisor.last_name}`}
                                </p>
                            </Col>
                            <Col>
                                <p><strong>Phone:</strong> {eventToView.supervisor.mobile_number}</p>
                                <p><strong>Email:</strong> <a href={`mailto:${eventToView.supervisor.email}`}>{eventToView.supervisor.email}</a></p>
                            </Col>
                        </Row>)
                        :(
                            <Row>
                                <p>
                                    No Assigned Supervisor
                                </p>
                            </Row>
                        )
                    }

                    <hr/>
                    <Row className="mb-3">
                        <Col className="fw-bold">
                            Origin Address
                        </Col>
                        <Col className="">
                            {eventToView.origin_address? (
                                <a href={getAddressLink(eventToView.origin_address)}>{formatAddress(eventToView.origin_address)}</a>
                            ): <p>No Location Specified</p>
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="fw-bold">
                            Destination Address
                        </Col>
                        <Col className="">
                            {eventToView.destination_address? (
                                <a href={getAddressLink(eventToView.destination_address)}>{formatAddress(eventToView.destination_address)}</a>
                            ): <p>No Location Specified</p>
                            }
                        </Col>
                    </Row>

                    <hr/>
                    <Row>
                        <p className="fw-bold">Description</p>
                        <p>{eventToView.event_description}</p>
                    </Row>
                    <Row>
                        <p className="fw-bold">Notes</p>
                        <p>{eventToView.event_notes}</p>
                    </Row>
                </Tab>
                <Tab eventKey="time" title="Timesheets">
                    {timesheetList.length > 0 ? (
                        timesheetList.map((entry, index) => (
                            <>
                                <Row className="mb-3" key={index}>
                                    <Col>
                                        Employee: {entry.employee.first_name} {entry.employee.last_name}
                                    </Col>
                                    <Col>
                                        Time In: {getDateString(entry.start_date)} <br/>@ {getTimeString(entry.start_date)}
                                    </Col>
                                    <Col>
                                        Time Out: {getDateString(entry.end_date)} <br/>@ {getTimeString(entry.end_date)}
                                    </Col>
                                    <Col xs="2">
                                        Total: {getElapsedHours(entry.start_date, entry.end_date)}
                                    </Col>
                                    <Col xs="2" className="justify-content-center">
                                        <Button size="sm" onClick={() => handleOpenDetails(entry)}>Update</Button>
                                    </Col>
                                </Row>
                                <hr/>
                            </>
                        ))
                    ): (<p>No Time Logged towards this event...</p>)
                    }
                    <Row className="justify-content-center my-3" xs={4}>
                        <Button variant="primary" onClick={handleShowModal}>
                            Add time
                        </Button>
                    </Row>
                </Tab>

                <Tab eventKey="resources" title="Resources">
                    <p className="fw-bold">Equipment Required</p>
                        {
                            resourcesOnEvent ? resourcesOnEvent.map((value) => {
                                return <p><span> {value.number_reserved}</span> {(value.resource as Resource).resource_name}</p>
                            }) : ""
                        }
                </Tab>


                {canViewAssigned ? (
                    <Tab eventKey="employees" title="Assigned Employees">
                        {eventToView.employeeuser_set && eventToView.employeeuser_set.length >0 ? (
                            eventToView.employeeuser_set.map((entry, index) => (
                                <Row className="mb-3 text-start" key={index}>
                                    <Col>
                                        <p><strong>Employee:</strong> {entry.first_name} {entry.last_name}</p>
                                    </Col>
                                    <Col>
                                        <p><strong>Phone:</strong> {entry.mobile_number}</p>
                                    </Col>
                                    <Col>
                                        <p><strong>Email:</strong><a href={`mailto:${entry.email}`}> {entry.email}</a></p>
                                    </Col>
                                </Row>
                            ))
                        ): "No Assigned Employees"}
                    </Tab>
                ):<></>}
            </Tabs>
            <Modal show={showModal} onHide={handleCloseModal} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateTimeEntry
                        selectedEvent={eventToView}
                        timeEntryToUpdate={selectedTimesheet? selectedTimesheet:undefined}
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default ViewEvent