import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

interface DeleteConfirmationModalProps {
    show: boolean;
    onHide: () => void;
    onConfirm: () => void;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
                                                                             show,
                                                                             onHide,
                                                                             onConfirm,
                                                                         }) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState("");

    const handleConfirm = () => {
        if (deleteConfirmation === "delete") {
            onConfirm();
            setDeleteConfirmation(""); // Clear the user's input when confirming
        }
    };

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    handleConfirm(); // Call your delete confirmation function
                }}>
                    <Form.Group controlId="deleteConfirmation">
                        <Form.Label>Type 'delete' to confirm:</Form.Label>
                        <Form.Control
                            type="text"
                            value={deleteConfirmation}
                            onChange={(e) => setDeleteConfirmation(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant="danger"
                    onClick={handleConfirm}
                    disabled={deleteConfirmation !== "delete"}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal;
