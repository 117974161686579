import React, {useEffect, useMemo, useState} from 'react'
import {UserRoles, WorkEvent, WorkOrder, WorkOrderActions} from "../../types";
import {deleteWorkOrder, getWorkOrderPDF, getWorkOrders, postWOAction} from "../../api/dataService";
import {Navigate, useNavigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import {ColumnDef, Row} from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Alert, Button, ButtonGroup} from "react-bootstrap"
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {checkIsAllowed, getDateTimeString, getWorkOrderActions, isLoadingSpinner} from "../../util/Helpers";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import {endDateFilter, startDateFilter} from "../../util/CustomFilters";
import WorkOrderActionButtons from "../WorkOrderActionButtons";


const parentStyle = {
    padding: '2rem',
}


function ManageWorkOrdersPage() {
    const user = useSelector((state: RootState) => state.user)
    const [isLoading, setIsLoading] = useState(true)
    const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([])
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false)
    const [workOrderToUpdate, setWorkOrderToUpdate] = useState<Row<WorkOrder>>()
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<WorkOrder> | null>(null);
    const navigate = useNavigate()

    useEffect(() => {
        Promise.all([getWorkOrders()])
            .then(([workOrderResponse]) => {
                if('redirectTo' in workOrderResponse){
                    return(<Navigate to={workOrderResponse.redirectTo}/>)
                }
                if(Array.isArray(workOrderResponse)){
                    setWorkOrderList(workOrderResponse)
                }

                if(workOrderList) setIsLoading(false)
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false)
            });
    }, []);


    const columns = useMemo<ColumnDef<WorkOrder, any>[]>(
        () => [
            {
                accessorFn: row => `${row.id}`,
                id: 'id',
                header: 'ID',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.billing_client,
                id: 'billing_client',
                header: 'Billing Client',
                enableColumnFilter: true
            },
            {
                accessorFn: row => row.jobtype_set,
                id: 'job_type',
                header: 'Job Type',
                enableColumnFilter: false
            },
            {
                accessorFn: row => `${getDateTimeString(row.start_date)}`,
                id: 'start_date',
                header: 'Start Date',
                enableColumnFilter: true,
                filterFn: startDateFilter
            },
            {
                accessorFn: row => `${getDateTimeString(row.end_date)}`,
                id: 'end_date',
                header: 'End Date',
                enableColumnFilter: true,
                filterFn: endDateFilter
            },
            {
                accessorFn: row => row.workorder_status,
                id: 'status',
                header: 'Status',
                enableColumnFilter: true,
                filterFn: 'arrIncludesSome'
            },
            {
                id: "viewQuote",
                header: 'View',
                cell: ({row}) => {
                    return <Button className="btn-secondary btn-sm"
                                   onClick={() => handleOpenDetails(row.original)}>View</Button>
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({row}) => {
                        if(checkIsAllowed(user.groups,[UserRoles.ownership, UserRoles.management])) {
                            return (
                          <div className=" d-flex justify-content-center"><WorkOrderActionButtons onUpdate={handleActionUpdate} workorder={row} />
                              <Button className="btn-sm btn-danger" onClick={ () => handleDeleteRow(row)}>Delete</Button>
                          </div>
                            )
                        } else if(checkIsAllowed(user.groups, [UserRoles.officeAdmin])) {
                            return <WorkOrderActionButtons onUpdate={handleActionUpdate} workorder={row} />
                            // getWorkOrderActions(row.original, row.original.workorder_status, handleWorkOrderAction)
                        } else {
                    return <div><p>No Actions</p></div>
                }
                }
}
        ],
        [workOrderList]
    )

    const handleShowCreate = () => navigate('/quotes/create/')
    const handleOpenDetails = (row: any) => {
        navigate(`/workorders/${row.id}`, {state: row})
    }

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            deleteWorkOrder(selectedRowToDelete.original.id.toString()).then(r =>
                {
                    if(r && !Array.isArray(r)){
                        if(r.status >= 200 || r.status <= 300){
                            const dataCopy = [...workOrderList]
                            dataCopy.splice(selectedRowToDelete.index, 1)
                            setWorkOrderList(dataCopy)
                        }
                    }

                }
            )
            setShowDeleteModal(false);
        }
    };

    const handleWorkOrderAction = async (workOrder: WorkOrder, action: WorkOrderActions) => {

            if(action === WorkOrderActions.save) {
                await getWorkOrderPDF(workOrder.id)
            } else {

                const response = await postWOAction(workOrder.id, action)

                if (response && !Array.isArray(response)) {
                    setShowSuccess(true)

                }

            }
        }

    const handleActionUpdate = (updated_obj?: WorkOrder, previous_obj?: Row<WorkOrder>) => {
        if(previous_obj && updated_obj){
                const dataCopy = [...workOrderList]
                dataCopy.splice(previous_obj.index, 1)
                setWorkOrderList([updated_obj, ...dataCopy])
            }
        }


    const handleDeleteRow = (row: Row<WorkOrder>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };
    //
    // const handleConfirmDelete = () => {
    //     if (selectedRowToDelete) {
    //         // Perform the deletion logic here
    //         console.log("Deleting row:", selectedRowToDelete);
    //         //deleteQuote(selectedRowToDelete.id.toString(), selectedRowToDelete).then(
    //         //)
    //         // Close the delete modal
    //         setShowDeleteModal(false);
    //     }
    // };

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Work Order Directory"}>

                <Alert className="alert-success" show={showSuccess}>
                    WO Order Action Successful! (Please refresh page)
                </Alert>

                <SearchableTable
                    columns={columns}
                    data={workOrderList}
                    globalSearch={true}
                    actionParam={
                        checkIsAllowed(user.groups, [UserRoles.sales, UserRoles.management, UserRoles.ownership])?
                            (<Button onClick={handleShowCreate}>Create Work Order</Button>):undefined
                    }

                />
            </StackedContentContainer>

            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageWorkOrdersPage