import {Table} from "@tanstack/react-table";
import {Workbook} from "exceljs";
import {saveAs} from "file-saver";


// Exports currently displayed table data
export async function exportTableData(table: Table<any>, filename: string, applyFilters: boolean = true, filetype: string = 'csv') {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet()
    const lastHeaderGroup = table.getHeaderGroups().at(-1)
    if (!lastHeaderGroup) {
        console.error('No Header groups found')
        return
    }
    // For each header, get the header def string and set it as one of the header columns
    worksheet.columns = lastHeaderGroup.headers.filter((header) => header.column.getIsVisible())
        .map((head) => {
            return {
                header: head.column.columnDef.header as string,
                key: head.id,
                width: 20 // adjust as needed
            }
        })
    const exportRows = applyFilters ? table.getFilteredRowModel().rows : table.getCoreRowModel().rows

    exportRows.forEach((row) => {
        const cells = row.getVisibleCells()
        const values = cells.map((cell) => cell.getValue() ?? '')
        worksheet.addRow(values)
    })
    worksheet.getRow(1).eachCell((cell) => {
        cell.font = {bold: true}
    })
    const buffer = filetype==='csv' ? await workbook.csv.writeBuffer() : await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buffer]), `${filename}.${filetype}`)
}