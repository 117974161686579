interface SetUserAction {
    type: 'SET_USER'
    payload: {
        id: string;
        username: string;
        groups: string[]
    }
}

interface LogoutAction{
    type: 'LOGOUT'
}


type UserAction = SetUserAction | LogoutAction;

const initialState = {
    username: null,
    userid: null,
    groups: [],
};

const userReducer = (state = initialState, action: UserAction) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                username: action.payload.username,
                userid: action.payload.id,
                groups: action.payload.groups,
            };
        case 'LOGOUT':
            return initialState;
        default:
            return state;
    }
};

export default userReducer;
