import React, {useEffect, useMemo, useState} from 'react'
import {TimeSheet} from "../../types";
import {deleteTimeSheet, getTimesheets} from "../../api/dataService";
import {Navigate} from "react-router-dom";
import {
    ColumnDef,
    flexRender,
    getCoreRowModel, getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    Row,
    useReactTable
} from "@tanstack/react-table";
import {getDateTimeString, getElapsedHours} from "../../util/Helpers";
import {Button, ButtonGroup, Modal, Table as BTable} from "react-bootstrap"
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import CreateTimeEntry from "../CreateTimeEntry";


const parentStyle = {
    padding: '2rem',
}


// TODO: Makes sense to make timesheetData optional or remove as timesheets can be rendered by event_id in this component
interface TimeEntriesTableProps {
    timeSheetData: TimeSheet[],
    event_id: string
}


/**
 * This component renders a table of general labour timesheets for an event by event_id or by a
 * prop array. Usage of this component exists on the Work Order page
 *
 * @param timeSheetData: Array of timesheets
 * @param event_id: Event ID associated to TimeEntries to render
 * @constructor
 */
const TimeEntriesTable: React.FC<TimeEntriesTableProps> = ({timeSheetData,event_id}) => {
    const [timesheetList, setTimesheetList] = useState<TimeSheet[]>([])
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToUpdate, setSelectedRowToUpdate] = useState<TimeSheet | null>(null)
    const [selectedRowToDelete, setSelectedRowToDelete] =  useState<Row<TimeSheet> | null>(null)
    const user = useSelector((state: RootState) => state.user)

    const filters = ['Ownership_Group', 'Office_Admin'].some((role) =>
        (user.groups as string []).includes(role)) ? '':`?event=${event_id}`

    useEffect(() => {
        if(timeSheetData) {
            setTimesheetList(timeSheetData)
        } else if (event_id) {
            Promise.all([getTimesheets(undefined, filters)])
                .then(([timesheetResponse]) => {
                    if('redirectTo' in timesheetResponse){
                        return(<Navigate to={timesheetResponse.redirectTo}/>)
                    }
                    if(Array.isArray(timesheetResponse)){
                        setTimesheetList(timesheetResponse)
                    }
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });

        } else {
            setTimesheetList([])
        }

    }, []);



    const columns = useMemo<ColumnDef<TimeSheet, any>[]>(
        () => [
                    {
                        accessorFn:  row => `${row.employee.first_name} ${row.employee.last_name}`,
                        id: 'full_name',
                        header: 'Employee Name',
                        enableColumnFilter: false
                    },
                    {
                        accessorFn: row => row.event.event_name,
                        id: 'event_name',
                        header: 'Event Name',
                        enableColumnFilter: false
                    },
                    {
                        accessorFn: row => `${getDateTimeString(row.start_date)}`,
                        id: 'start_date',
                        header: 'Clock In',
                        enableColumnFilter: false
                    },
                    {
                        accessorFn: row => `${getDateTimeString(row.end_date)}`,
                        id: 'end_date',
                        header: 'Clock Out',
                        enableColumnFilter: false
                    },
                    {
                        accessorFn: row => `${getElapsedHours(row.start_date,row.end_date)}`,
                        id: 'total_hours',
                        header: 'Worked Hours',
                        enableColumnFilter: false
                    },
                    {
                        accessorFn: row => `${row.status}`,
                        id: 'status',
                        header: 'Status',
                        enableColumnFilter: false
                    },
                    {
                        id: 'actions',
                        header: 'Actions',
                        cell: ({row}) => (
                            <div className="d-flex justify-content-center">
                                <ButtonGroup className="btn-group-sm">
                                    <Button onClick={() => handleOpenDetails(row)}>View</Button>
                                </ButtonGroup>
                            </div>
                        )
                    }
        ],
        []
    )

    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setSelectedRowToDelete(null)
        setSelectedRowToUpdate(null)
    }
    const handleShowCreate = () => setShowCreateModal(true)
    const handleOpenDetails = (row: any) => {
        setSelectedRowToUpdate(row.original)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<TimeSheet>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            // Perform the deletion logic here
            deleteTimeSheet(selectedRowToDelete.original.id.toString()).then(r => {
                if(r && !Array.isArray(r)){
                    if(r.status >= 200 || r.status <= 300){
                        const dataCopy = [...timesheetList]
                        dataCopy.splice(selectedRowToDelete.index, 1)
                        setTimesheetList(dataCopy)
                    }
                }
            })
            // Close the delete modal
            setShowDeleteModal(false);
        }
    };

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setTimesheetList([created_obj,...timesheetList])
        }
        setShowCreateModal(false)
    }



    const table = useReactTable({
        data: timesheetList,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true,
    })

    return (
        <div style={parentStyle}>
            <BTable>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.length !== 0 ? (
                    table.getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={7}>
                            <p className="text-center">No Time Entries</p>
                        </td>
                    </tr>
                )}
                </tbody>
            </BTable>

            {/*MODALS / POP UP WINDOWS */}

            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateTimeEntry timeEntryToUpdate={selectedRowToUpdate? selectedRowToUpdate : undefined} onCreate={handleCreate}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default TimeEntriesTable