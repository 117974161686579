import React, {useEffect, useMemo, useState} from 'react'

import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    ColumnDef,
    flexRender, Row,
} from '@tanstack/react-table'
import {
    formatAddress,
    PartialWorkEvent,
    WorkEvent,
    Address,
    EventResourceUse
} from "../../types";
import Button from "react-bootstrap/Button";
import {Modal, Table as BTable} from "react-bootstrap";
import {getDateTimeString} from "../../util/Helpers";
import CreateEvent from "../CreateEvent";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {deleteEvent} from "../../api/dataService";
import {Row as BRow} from 'react-bootstrap'
import {Col as BCol} from 'react-bootstrap'
import {roundTo} from "../../util/Helpers";

// TODO: Handle implementation to edit non pushed quotes, newData array is ignored for now
interface QuotedEventsTableProps  {
    eventData: Array<[Partial<WorkEvent>, EventResourceUse[]]>,
    handleNewEventUpdate: (object: Partial<WorkEvent>, assignedResources: EventResourceUse[], eventDataIndex: number) => void,
    handleEventDelete: (object: Partial<WorkEvent>, eventDataIndex: number) => void,
    disabled: boolean,
    costFn: (cost:number) => void

}
const QuotedEventsTable: React.FC<QuotedEventsTableProps> = (props) => {

    const [eventData, setEventData] = useState(props.eventData)
    const [eventToEdit, setEventToEdit] = React.useState<PartialWorkEvent>()
    const [resourceToEdit, setResourceToEdit] = React.useState<EventResourceUse[]>()
    const [showExistingEventModal, setShowExistingEventModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<PartialWorkEvent | null>(null);
    const [showNewEventModal, setShowNewEventModal] = useState(false)
    const [currentRowId, setCurrentRowId] = useState<number>()

    useEffect(() => {
        setEventData(props.eventData)
    }, [props.eventData]);

    const totalCost = useMemo(() => {
        const cost_calc = eventData ? eventData.reduce((total, event) => event[0].event_cost ? parseFloat(total.toString()) + parseFloat(event[0].event_cost.toString()) : total, 0) : 0
        let roundedTotal = roundTo(cost_calc,2)
        props.costFn(roundedTotal)
        return roundedTotal
    }, [eventData]);

    const columns = useMemo<ColumnDef<[Partial<WorkEvent>, EventResourceUse[]]>[]>(
        () => [
            {
                header: 'Quoted Events',
                footer: foot_props => foot_props.column.id,
                columns: [
                    {
                        accessorKey: 'start_date',
                        header: "Date",
                        cell: ({ row }) => (
                                <>
                                    {row.original[0].start_date ? getDateTimeString(row.original[0].start_date) : 'No start Date'}
                                </>
                        ),
                        enableColumnFilter: false
                    },
                    {
                        accessorFn: row => row[0].origin_address ? formatAddress(row[0].origin_address as Address) : '',
                        id: 'origin_address',
                        header: 'Origin Address',
                        enableColumnFilter: false

                    },
                    {
                        accessorFn: row => row[0].event_description,
                        id: 'event_description',
                        header: 'Event Description',
                        enableColumnFilter: false
                    },
                    {
                        accessorFn: row => `$${row[0].event_cost}`,
                        id: 'quoted_amount',
                        header:  () => <div>Quoted Amount</div>,
                        enableColumnFilter: false
                    },
                    {
                        id: 'actions',
                        header: "Actions",
                        cell: ({row}) => {
                            if(props.disabled) {
                                return null
                            } else {
                                // Show Actions on an already submitted event w/ quote id
                                if(row.original[0].id) {
                                    return <div>{props.disabled ? '' : <div className="d-flex justify-content-evenly"><Button className="btn-secondary" onClick={() => handleEditExistingEvent(row)}>Edit</Button><Button className='btn-danger' onClick={()=>handleDeleteRow(row)}>Delete</Button></div>}</div>
                                // Show Actions on an un-submitted event w/o a quote id
                                } else {
                                    return <div><div className="d-flex justify-content-evenly"><Button className="btn-secondary" onClick={() => handleEditNewEvent(row)}>Edit</Button><Button className='btn-danger' onClick={()=> handleDeleteRow(row)}>Delete</Button></div><p className="text-danger">Unsaved Event</p></div>
                                }
                            }



                        },
                        enableColumnFilter: false
                    },
                ],
            },
        ],
        [props.disabled],
    )

    const handleEditExistingEvent = (row: Row<[Partial<WorkEvent>, EventResourceUse[]]>) => {
        const workEvent = row.original[0]
        const resourceData = row.original[1]
        setEventToEdit(workEvent)
        setResourceToEdit(resourceData)

        setShowExistingEventModal(true)

    }
    const handleEditNewEvent = (row: Row<[Partial<WorkEvent>,EventResourceUse[]]>) => {

        const workEvent = row.original[0]
        const resourceData = row.original[1]

        setEventToEdit(workEvent)
        setResourceToEdit(resourceData)

        // Required for updating event data as Events w/o event_id are referenced by the table_id (row.id) NOT event_id (row.original.id)
        setCurrentRowId(parseInt(row.id))

        setShowNewEventModal(true)
    }

    const handleDeleteRow = (row: Row<[Partial<WorkEvent>, EventResourceUse[]]>) => {
        const event = row.original[0]

        // TODO: Update useState variable to match the row type in the table and instead pass a Row object not a WorkEvent object
        setSelectedRowToDelete(event);

        setCurrentRowId(parseInt(row.id));
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {

        //Remove event w/o an id from Client side array only!
        if(selectedRowToDelete) {
            // Remove from eventData array (Client-side)
            if (currentRowId !== undefined) {
                props.handleEventDelete(selectedRowToDelete, currentRowId);
                setCurrentRowId(undefined)
            }
        }

        // If selected event has an id, delete event from server (Server-side)
        if (selectedRowToDelete && selectedRowToDelete.id) {
            // Remove event entity from server (Server-side)
            deleteEvent(selectedRowToDelete.id.toString(), selectedRowToDelete)
            setShowDeleteModal(false);
        }

        setShowDeleteModal(false);

    };

    const table = useReactTable({
        data: eventData,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        debugTable: true,
    })

    return (
        <div className="p-2">
            <div className="h-2" />
            <BTable>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.length !== 0 ? (
                    table.getRowModel().rows.map((row) => {
                        return (<tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>)
                    })
                ) : (
                    <tr>
                        <td colSpan={5}>
                            <p className="text-center">No events inserted</p>
                        </td>
                    </tr>
                )}
                </tbody>
            </BTable>

            <BRow className="p-2">
                <div className="d-flex justify-content-center">
                    <BCol><span className="fw-bold" >Quoted Amount</span> ${totalCost}</BCol>
                </div>
            </BRow>

            {/*MODALS / POP UP WINDOWS */}

            <Modal show={showNewEventModal} onHide={() => {
                setShowNewEventModal(false)
            }} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{eventToEdit ? 'Edit Event' : 'No Event Data :('}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateEvent
                        submitEvent={false}
                        closeModal={() => {setShowNewEventModal(false)}}

                        resourcesOnEvent={resourceToEdit}
                        eventToUpdate= {eventToEdit}

                        // Returned data from Create Event Component
                        onUpdate={(updatedObject, assignedEmployeeList,assignedResources) => {

                            if(currentRowId !== undefined) {

                                // Update the event table w/ updated event object
                                const updatedEventData = [...eventData]
                                // New events do not have event_id and set referenced by currentRowId (row.id)
                                updatedEventData[currentRowId] = [updatedObject,assignedResources]
                                setEventData(updatedEventData)

                                // Set all use states to undefined once data has been updated
                                setCurrentRowId(undefined)
                                setResourceToEdit(undefined)
                                setEventToEdit(undefined)
                            }
                        }}
                        isQuoting={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowNewEventModal(false)
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showExistingEventModal} onHide={() => {
                setShowExistingEventModal(false)
            }} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{eventToEdit? `Edit Event ${eventToEdit.id}`: "Create New Event"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateEvent
                        submitEvent={true}
                        closeModal={() => {
                            setShowExistingEventModal(false)
                        }}
                        eventToUpdate={eventToEdit}

                        // Return data from Create Event Component
                        onUpdate={
                        (createdObject,assignedEmployeeList,assignedResources) => {
                            // Find the index of existing event in the event data list
                            // TODO: Use currentRowId/setCurrentRowId as used in @newEventModal
                            const index = eventData.findIndex(someEvent => someEvent[0].id === createdObject.id)
                            if(index !== -1) {
                                // Update the event data list with updated event contents
                                const updatedEventData = [...eventData]
                                updatedEventData[index] = [createdObject,assignedResources]
                                setEventData(updatedEventData)
                            } else {
                                console.error("Updating Event.... Event not found ")
                            }
                            setShowExistingEventModal(false)
                        }}
                        isQuoting={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowExistingEventModal(false)
                    }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )


}



export default QuotedEventsTable
