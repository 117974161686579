import React, { useState } from 'react';

interface EditableCellProps {
    value: any;
    row: any;
    column: any;
    updateInventory: (address: string, resource: string, newQuantity: number) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({ value, row, column, updateInventory }) => {
    const [editMode, setEditMode] = useState(false);
    const [cellValue, setCellValue] = useState(value);

    const handleBlur = () => {
        setEditMode(false);
        let finalValue = parseInt(cellValue, 10);
        if (isNaN(finalValue)) {
            finalValue = 0; // Default to 0 if the final value isn't a number
        }
        if (finalValue !== value) { // Only update if the value has changed
            updateInventory(row.original.address, column.id, finalValue);
        }
    };

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const validValue = /^-?\d*$/.test(newValue) ? newValue : cellValue;
        setCellValue(validValue);
    };

    return editMode ? (
        <input
            type="number"
            value={cellValue}
            // onChange={e => setCellValue(e.target.value)}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
        />
    ) : (
        <span onDoubleClick={() => setEditMode(true)}>{value}</span>
    );
};

export const DefaultCell: React.FC<{ value: any }> = ({ value }) => {
    return <>{value}</>;
};