import React, {useEffect, useMemo, useState} from 'react'
import {DriverTimeSheet, UserRoles, WorkEvent, WorkOrder} from "../../types";
import {
    deleteDriverTimeSheet,
    getDriverTimesheets, getWorkOrders
} from "../../api/dataService";
import {Navigate} from "react-router-dom";
import StackedContentContainer from "../ContentContainer";
import {ColumnDef, Row} from "@tanstack/react-table";
import SearchableTable from "../SearchableTable";
import {Button, ButtonGroup, Modal} from "react-bootstrap"
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import {checkIsAllowed, getDateTimeString, getElapsedHours, isLoadingSpinner} from "../../util/Helpers";
import CreateTimeEntry from "../CreateTimeEntry";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import {endDateFilter, startDateFilter} from "../../util/CustomFilters";
import ExportTimecard from "../ExportTimecard";

const parentStyle = {
    padding: '2rem',
}

function ManageDriverTimeEntriesPage() {
    const [driverTimeEntryList, setDriverTimeEntryList] = useState<DriverTimeSheet[]>([])
    const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showExportModal, setShowExportModal] = useState(false)
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<DriverTimeSheet> | null>(null);
    const [selectedRowToUpdate, setSelectedRowToUpdate] = useState<Row<DriverTimeSheet> | undefined>()
    const user = useSelector((state: RootState) => state.user)
    const filters = checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management,
        UserRoles.officeAdmin, UserRoles.driver, UserRoles.supervisor, UserRoles.sales, UserRoles.dispatch]) ? '':`?employee=${user.userid}`

    useEffect(() => {
        Promise.all([getDriverTimesheets(undefined,filters), getWorkOrders()])
            .then(([driverTimeSheetResponse, workOrderResponse]) => {
                if('redirectTo' in driverTimeSheetResponse){
                    return(<Navigate to={driverTimeSheetResponse.redirectTo}/>)
                }
                if(Array.isArray(driverTimeSheetResponse)){
                    setDriverTimeEntryList(driverTimeSheetResponse)
                }
                if('redirectTo' in workOrderResponse) {
                    return (<Navigate to={workOrderResponse.redirectTo}/>)
                }
                if(Array.isArray(workOrderResponse)){
                    setWorkOrderList(workOrderResponse)
                }

                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, []);

    const columns = useMemo<ColumnDef<DriverTimeSheet, any>[]>(
        () => [
            {
                accessorFn: row => `${row.employee.first_name} ${row.employee.last_name}`,
                id: 'full_name',
                header: 'Employee Name',
                enableColumnFilter: true
            },
            {
                accessorFn: row => row.event.event_name,
                id: 'event_name',
                header: 'Event Name',
                enableColumnFilter: false
            },
            {
                // accessorFn: row => `${(row.event as WorkEvent).jobsite_client} - ${(row.event as WorkEvent).event_name}`,
                accessorFn: row => (row.event as WorkEvent).status,
                id: 'event_status',
                header: 'Event Status',
                enableColumnFilter: true,
                filterFn: "arrIncludesSome"
            },
            {
                cell: ({row}) => {
                    if((row.original.event as WorkEvent).work_order) {
                        let wo_id = (row.original.event as WorkEvent).work_order
                        return <div className="text-center">
                            <a href={`/workorders/${(row.original.event as WorkEvent).work_order}`}>WO #{ wo_id ? wo_id.toString(): "" }</a>
                        </div>
                    }
                },
                id: 'resource_workorder',
                header: 'Work Order',
                enableColumnFilter: true
            },
            {
                accessorFn: row => `${row.vehicle.make} ${row.vehicle.model}`,
                id: 'vehicle',
                header: 'Vehicle',
                enableColumnFilter: true
            },
            {
                accessorFn: row => `${getDateTimeString(row.start_date)}`,
                id: 'start_date',
                header: 'Clock In',
                enableColumnFilter: true,
                filterFn:startDateFilter
            },
            {
                accessorFn: row => `${getDateTimeString(row.end_date)}`,
                id: 'end_date',
                header: 'Clock Out',
                enableColumnFilter: true,
                filterFn:endDateFilter
            },
            {
                accessorFn: row => `${getElapsedHours(row.start_date,row.end_date)}`,
                id: 'total_hours',
                header: 'Worked Hours',
                enableColumnFilter: false
            },
            {
                accessorFn: row => `${row.status}`,
                id: 'status',
                header: 'Status',
                enableColumnFilter: true,
                filterFn: "arrIncludesSome"
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({row}) => (
                    <div className="d-flex justify-content-center">
                        {(
                            (row.original.status !== 'closed' && row.original.employee.id === user.userid)
                            || checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management, UserRoles.officeAdmin]))?
                            (<ButtonGroup className={'btn-group-sm'}>
                                <Button className={'btn-secondary'} onClick={() => handleOpenDetails(row)}>View</Button>
                                <Button className={'btn-danger'} onClick={() => handleDeleteRow(row)}>Delete</Button>
                            </ButtonGroup>):(<></>)}
                    </div>
                )
            }
        ],
        [workOrderList]
    )
    const handleCloseCreate = () => {
        setShowCreateModal(false)
        setSelectedRowToUpdate(undefined)
    }
    const handleShowCreate = () => setShowCreateModal(true)
    const handleOpenDetails = (row: any) => {
        setSelectedRowToUpdate(row)
        setShowCreateModal(true)
    }
    const handleDeleteRow = (row: Row<DriverTimeSheet>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };
    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            deleteDriverTimeSheet(selectedRowToDelete.original.id.toString(), selectedRowToDelete.original)
            setShowDeleteModal(false);
        }
    };

    const handleCreate = (created_obj? : any) => {
        if(created_obj){
            setDriverTimeEntryList([created_obj,...driverTimeEntryList])
        }
        setShowCreateModal(false)
    }
    const handleUpdate = (updated_obj?: DriverTimeSheet) => {
        if(selectedRowToUpdate){
            if(updated_obj){
                const dataCopy = [...driverTimeEntryList]
                dataCopy.splice(selectedRowToUpdate.index, 1)
                setDriverTimeEntryList([updated_obj, ...dataCopy])
            }
        }
    }

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <div style={parentStyle}>
            <StackedContentContainer title={"Driver Timesheet Directory"}>
                <SearchableTable
                    columns={columns}
                    data={driverTimeEntryList}
                    globalSearch={true}
                    actionParam={
                        checkIsAllowed(user.groups, ['Ownership_Group', 'Management', 'Office_Admin'])? // are these groups correct?
                            (
                                <ButtonGroup>
                                    <Button variant={'success'} onClick={()=> {setShowExportModal(true)}}>Export Timesheet</Button>
                                    <Button onClick={handleShowCreate}>Manual Time Entry</Button>
                                </ButtonGroup>
                            ):undefined
                    }
                />
            </StackedContentContainer>
            <Modal show={showCreateModal} onHide={handleCloseCreate} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedRowToUpdate? "Update Time Entry": "Create New Time Entry"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateTimeEntry
                        driverTimeEntryToUpdate={selectedRowToUpdate? selectedRowToUpdate.original : undefined}
                        timeEntryToUpdate={
                            selectedRowToUpdate? (
                                {
                                    employee: selectedRowToUpdate.original.employee,
                                    event: selectedRowToUpdate.original.event,
                                    status: selectedRowToUpdate.original.status,
                                    start_date: selectedRowToUpdate.original.start_date,
                                    end_date: selectedRowToUpdate.original.end_date,
                                    shift_note: selectedRowToUpdate.original.shift_note
                                }
                            ) : undefined
                        }
                        onCreate={handleCreate}
                        onUpdate={handleUpdate}
                        closeModal={handleCloseCreate}
                        renderDriver={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreate}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showExportModal} onHide={() => {setShowExportModal(false)}} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Export Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ExportTimecard endpoint={'drivertimesheet-csv'} workorderList={workOrderList}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setShowExportModal(false)}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    )
}

export default ManageDriverTimeEntriesPage