import {Accordion, Alert, Button, FormGroup, Modal, Spinner, Tab, Tabs} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    Address,
    Client,
    EventResourceUse,
    formatAddress,
    FullWorkEvent, UserRoles,
    WorkOrder,
    WorkOrderActions,
    WorkOrderStatus
} from "../../types";
import {Navigate, useLocation, useParams} from "react-router-dom";
import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import {
    getClients,
    getDriverTimesheets,
    getEvents,
    getResourceUses,
    getTimesheets,
    getWorkOrders,
    uploadWorkOrderFiles
} from "../../api/dataService";
import StackedContentContainer from "../ContentContainer";
import {
    checkIsAllowed,
    encodeFilterString,
    getDateTimeString,
    getTotalEventHours,
    handleWorkOrderAction, isLoadingSpinner
} from "../../util/Helpers";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionBody from "react-bootstrap/AccordionBody";
import CreateEvent from "../CreateEvent";
import {ErrorMessage, Field, FieldProps, Formik} from "formik";
import Form from "react-bootstrap/Form";
import WorkOrderEventsTable from "./WorkOrderEventsTable";
import TimeEntriesTable from "./TimeEntriesTable";
import * as Yup from 'yup'
import DriverTimeEntriesTable from "./DriverTimeEntriesTable";
import {v4 as uuidv4} from "uuid";
import Container from "react-bootstrap/Container";
import {fetchSafetySheet} from "../../api/api";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";

    // TODO: Refactor Work Order  component to retrieve WorkOrder instances via a prop, rather navigate()
    // These two values are set when a user accesses this component using react navigate('/workorders/:id',{state: QuoteForm})
export const WorkOrderPage: React.FC = () =>  {
    const location = useLocation();
    const user = useSelector((state:RootState) => state.user);

    //Type Assertion - Assumes that the state object will always be an instance of WorkOrder
    const state = location.state as WorkOrder;

    // Current id of work order passed in from link
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(false)
    const formikRef = useRef<any>(null)
    const [workOrderData , setWorkOrderData] = useState<WorkOrder>();
    const [eventData, setEventData] = useState<FullWorkEvent[]>([]);
    const [showEventModal, setShowEventModal] = useState(false)
    const [elevatorSchedule, setElevatorSchedule] = useState<string>()
    const [isDisabled,setIsDisabled] = useState<boolean>(false)
    const [elevatorScheduleFile, setElevatorScheduleFile] = useState<File>()
    const [safetyDataSheet, setSafetyDataSheet] = useState<string>()
    const [safetyDataSheetFile, setSafetyDataSheetFile] = useState<File>()
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [jobClient, setJobClient] = useState<Client>()
    const [showSuccess, setShowSuccess] = useState(false)
    const [billClient, setBillClient] = useState<Client>()

    // Tally of all hours spent on a Work Order across all event timesheet data
    const totalHoursWO = useMemo(() => {
        let total_hours = 0;
        eventData.forEach((eventData) => {
            total_hours = total_hours + getTotalEventHours(eventData.TimeSheetList) + getTotalEventHours(eventData.DriverTimeSheetList)
        })
        return total_hours
    }, [eventData]);

    // Initialize Work Order data either from state object or id in the navigation link
    useEffect(() => {
        // TODO: Render workorder date via State object not through API Call
        if (state) {
            setWorkOrderData(state)
        } else if (!state && id) {
            Promise.all([getWorkOrders(id)])
                .then(([workOrderResponse]) => {
                    if ('redirectTo' in workOrderResponse) {
                        return (<Navigate to={workOrderResponse.redirectTo}/>)
                    }
                    if (!Array.isArray(workOrderResponse) && workOrderResponse) {
                        setWorkOrderData(workOrderResponse)
                    }
                    setIsLoading(false)
                })
        } else {
            setWorkOrderData(undefined)
        }
    }, [id,state]);

    useEffect(() => {

        // Work Order READ_ONLY permission logic
        if (state && (
            state.workorder_status === WorkOrderStatus.complete
        )) {
            setIsDisabled(true);
        } else if (!checkIsAllowed(user.groups,[UserRoles.officeAdmin, UserRoles.management,UserRoles.ownership])) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }

    }, [state,id,user]);

    useEffect(() => {
        setEventData([])
        setIsLoading(true)
        if (workOrderData) {
            Promise.all([
                getClients(undefined, `?client_name=${encodeFilterString(workOrderData.jobsite_client)}`),
                getClients(undefined, `?client_name=${encodeFilterString(workOrderData.billing_client)}`),
                getEvents(undefined, `?work_order=${id}`)
            ])
                .then(([jobClientResponse, billClientResponse, eventResponse]) => {

                    if ('redirectTo' in jobClientResponse) { return (<Navigate to={jobClientResponse.redirectTo}/>) }
                    if ('redirectTo' in billClientResponse) {return (<Navigate to={billClientResponse.redirectTo}/>) }

                    if (Array.isArray(jobClientResponse) && Array.isArray(billClientResponse)) {
                        setJobClient(jobClientResponse[0]);
                        setBillClient(billClientResponse[0]);
                    }

                    // Set EventData Array which is an array of events with Resource, Timesheet, and Employee data attached
                    if (Array.isArray(eventResponse)) {
                        // Create an array to store the promises for fetching timesheets and resource data
                        const promises = eventResponse.map((event) => {
                            return Promise.all([
                                getTimesheets(undefined, `?event=${event.id}`),
                                getDriverTimesheets(undefined,`?event=${event.id}`),
                                getResourceUses(undefined, `?event=${event.id}`)

                            ]);
                        });

                        // Execute all promises and wait for their completion
                        Promise.all(promises).then((results) => {
                            results.forEach(([timeSheetResponse,driverTimeSheetResponse, resourceResponse], index) => {
                                if ('redirectTo' in timeSheetResponse) { return (<Navigate to={timeSheetResponse.redirectTo}/>) }
                                if ('redirectTo' in driverTimeSheetResponse) { return (<Navigate to={driverTimeSheetResponse.redirectTo}/>) }
                                if ('redirectTo' in resourceResponse) { return (<Navigate to={resourceResponse.redirectTo}/>) }

                                const [eventTimeSheetData, eventDriverTimeSheetData,eventResourceData] = results[index];

                                if (Array.isArray(eventTimeSheetData) && Array.isArray(eventResourceData) && Array.isArray(eventDriverTimeSheetData) ) {

                                    // Example of a single object to be inserted in the Event Data Array
                                    let fullWorkEvent: FullWorkEvent = {
                                        WorkEvent : eventResponse[index],
                                        TimeSheetList : eventTimeSheetData,
                                        AssignedResourceList : eventResourceData,
                                        DriverTimeSheetList : eventDriverTimeSheetData,
                                        AssignedEmployeeList :  [] ,
                                    }

                                    setEventData(prevState => [...prevState, fullWorkEvent]);
                                }
                            });
                        });
                    }
                    setIsLoading(false)
                });

            // Initialize paths to files, if already saved
            if(workOrderData.safety_data_sheet) setSafetyDataSheet(workOrderData.safety_data_sheet)
            if(workOrderData.elevator_schedule) setElevatorSchedule(workOrderData.elevator_schedule)

        }
    }, [workOrderData,id]);


    const handleEventDelete = (created_obj: any,  eventDataIndex: number) => {
        const updatedEventData = [...eventData]; // Create a copy of the array
        updatedEventData.splice(eventDataIndex, 1); // Remove the item at the specified index
        setEventData(updatedEventData);
        setShowEventModal(false)
    }

    const handleSubmit = async ( values: Partial<WorkOrder>) => {
        // Create a FormData object to send the file to the server
        const formData = new FormData();

        // Create a unique file name for s3
        if(values.safety_data_sheet && safetyDataSheetFile) {
            let shortRandomString = uuidv4().substring(0, 6);
            let uniqueFileName = `${Date.now()}_${shortRandomString}_${safetyDataSheetFile.name}`;
            formData.append('safety_data_sheet', safetyDataSheetFile, uniqueFileName);
        }

        // Create a unique file name for s3
        if(values.elevator_schedule && elevatorScheduleFile) {
            let shortRandomString = uuidv4().substring(0, 6);
            let uniqueFileName = `${Date.now()}_${shortRandomString}_${elevatorScheduleFile.name}`;
            formData.append('elevator_schedule', elevatorScheduleFile, uniqueFileName)
        }

        try {
            if(id || state.id) {
                // Send the FormData to your server using a fetch or Axios
                const response = await uploadWorkOrderFiles(id || state.id, formData)

                if (response && !Array.isArray(response) && workOrderData) {
                    setShowSuccess(true)

                    // Type Assertion - assumes the response is a WorkOrder type
                    let responseData = response as WorkOrder

                    let updatedWorkOrderData = {...workOrderData};

                    // Update new file attachment paths if successfully added into the server(w/o needing to refresh window)
                    updatedWorkOrderData.safety_data_sheet = responseData.safety_data_sheet
                    updatedWorkOrderData.elevator_schedule = responseData.elevator_schedule
                    setWorkOrderData(updatedWorkOrderData)

                    // Reset form values
                    if(formikRef.current){ formikRef.current.resetForm() }
                    setElevatorSchedule(undefined)
                    setSafetyDataSheet(undefined)
                } else {
                    // Handle server errors or display an error message
                    console.error('File upload failed:', response.statusText);
                }
            }

        } catch (error) {
            // Handle network or other errors
            console.log(error)
        }
    };


    const initialValues: Partial<WorkOrder> =  {
            safety_data_sheet: '',
            elevator_schedule: ''
    }

    const FILE_SIZE = 4 * 1024 * 1024; // 4 MB

    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png",
        "image/heic",
        "application/pdf"
    ];

    // File validation ensures attached files follow correct formats and file size attributes
    const validationSchema = Yup.object().shape({
        safety_data_sheet: Yup
            .mixed<File>()
            .test(
                "blobOrValidation",
                "File too large or unsupported format",
                function (value) {
                    // Check if value is a blob (File object)
                    if (value instanceof File) {
                        return value.size <= FILE_SIZE && SUPPORTED_FORMATS.includes(value.type);
                    }
                    // If it's not a blob, it's not checked
                    return true;
                }
            ),
        elevator_schedule: Yup
            .mixed<File>()
            .test(
                "blobOrValidation",
                "File too large or unsupported format",
                function (value) {
                    // Check if value is a blob (File object)
                    if (value instanceof File) {
                        return value.size <= FILE_SIZE && SUPPORTED_FORMATS.includes(value.type);
                    }
                    // If it's not a blob, it's not checked
                    return true;
                }
            ),
    });

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        workOrderData && eventData && billClient && jobClient ? (
            <StackedContentContainer title={`Work Order - #${workOrderData.id}`}>
            <div className="d-flex align-items-center">
                <div className="text-nowrap">Status - {workOrderData.workorder_status}</div>
                <Container className="d-flex justify-content-end">
                    {
                        state ?
                            <Button
                            onClick={() => handleWorkOrderAction(state, WorkOrderActions.save)}
                            className="btn-primary"
                            style={{ whiteSpace: 'nowrap' }}>
                            Download PDF
                        </Button> : ''
                    }
                   </Container>
            </div>

                <hr/>

                <p className="fw-normal text-center">Client Summary</p>

                <Row>
                    <Col className="d-flex justify-content-center">
                        <div>
                            <Row>
                                <p><span className="fw-bold">Client: </span>{jobClient?.client_name}</p>
                            </Row>
                            <Row>
                                <p>{jobClient?.address ? formatAddress(jobClient.address as Address) : "No Address"}</p>
                            </Row>
                            <Row>
                                <p><span className="fw-bold">Contact Name: </span>{jobClient?.contact.contact_name}</p>
                            </Row>
                            <Row>
                                <p><span className="fw-bold">Phone: </span>{jobClient?.contact.contact_phone_number}</p>
                            </Row>
                        </div>
                    </Col>

                    <Col className="d-flex justify-content-center">
                        <div>
                            <Row>
                                <p><span className="fw-bold">Bill to: </span>{billClient?.client_name}</p>
                            </Row>
                            <Row>
                                <p>{jobClient?.address ? formatAddress(billClient?.address as Address) : "No Address"}</p>
                            </Row>
                            <Row>
                                <p><span className="fw-bold">Contact Name: </span>{billClient?.contact.contact_name}</p>
                            </Row>
                            <Row>
                                <p><span className="fw-bold">Phone: </span>{billClient?.contact.contact_phone_number}</p>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <hr/>
                <p className="fw-normal text-center">Event Details</p>
                <div className="container" >
                <WorkOrderEventsTable disabled={isDisabled} eventData={eventData} handleEventDelete={handleEventDelete} />
                    <Button disabled={isDisabled} onClick={()=> {
                        setShowEventModal(true)
                    }} variant="success">Add Event</Button>
                </div>


                <hr/>
                <p className="fw-normal text-center">Event Time Sheets</p>
                <Accordion alwaysOpen={true} defaultActiveKey={"0"}>
                {
                    eventData.map((event) => {
                        return (
                        <AccordionItem eventKey={event.WorkEvent.id ? event.WorkEvent.id : "0"}>
                            <AccordionHeader>
                                <Col>Timesheet - {event.WorkEvent.event_name}</Col>
                            </AccordionHeader>
                            <AccordionBody>

                                <Row><p className={"text-center"}>{getDateTimeString(event.WorkEvent.start_date)} - {getDateTimeString(event.WorkEvent.end_date)}</p></Row>
                                <Tabs defaultActiveKey={"generalLabour"}>
                                    <Tab eventKey={"generalLabour"} title={"General Labour Time Cards"}>
                                        <TimeEntriesTable event_id={event.WorkEvent.id ? event.WorkEvent.id : "Unknown Event"} timeSheetData={event.TimeSheetList}/>
                                    </Tab>
                                    <Tab eventKey={"driver"} title={"Driver Time Cards"}>
                                        <DriverTimeEntriesTable timeSheetData={event.DriverTimeSheetList} event_id={event.WorkEvent.id}/>
                                    </Tab>
                                </Tabs>
                                <p>Total General Labour Hours: <span>{getTotalEventHours(event.TimeSheetList)}</span></p>
                                <p>Total Driver Hours: <span>{getTotalEventHours(event.DriverTimeSheetList)}</span></p>

                            </AccordionBody>
                        </AccordionItem>
                        )})
                }
                </Accordion>

                <Col>
                    <p><span className={"fw-bold"}>Total Worked Hours (Driving + Labour): </span>{totalHoursWO}</p>
                </Col>
                <hr/>

                <p className={"text-center"}>Additional File Attachments</p>
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        File Upload Success!
                    </Alert>
                )}
                <div>
                    <Formik innerRef={formikRef} enableReinitialize={true} validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                        {
                            (formik) => (
                                <Form onSubmit={formik.handleSubmit}>
                                    <Row>
                                        <Col>
                                            {/*Retrieves a Safety Sheet information*/}
                                            <Button onClick = {() => {
                                                fetchSafetySheet(id)
                                            }} disabled={isDisabled}>Generate WO Safety Sheet PDF </Button>
                                        </Col>
                                        <Col>
                                            <FormGroup className="mb-5" controlId="safetyDataSheet">
                                                <p className="fw-normal">Signed WO Safety Sheet</p>

                                                {
                                                    safetyDataSheet ?
                                                        <div>
                                                        <a
                                                            href={safetyDataSheet}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {safetyDataSheet}
                                                        </a>
                                                    </div> : ""
                                                }
                                                <Field name="safety_data_sheet" type="file">
                                                    {({ field, form }:FieldProps<File>) => (
                                                        <div>
                                                            <input
                                                                name="safety_data_sheet"
                                                                type="file"
                                                                disabled={isDisabled}
                                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                                    setShowSuccess(false);
                                                                    setErrorMessages([]);
                                                                    form.setFieldTouched("safety_data_sheet", true, true);
                                                                    if (event.currentTarget.files) {
                                                                        form.setFieldValue("safety_data_sheet", event.currentTarget.files[0]);
                                                                        setSafetyDataSheetFile(event.currentTarget.files[0])
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <ErrorMessage name="safety_data_sheet" component="div" className="text-danger" />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col></Col>
                                        <Col>
                                            <FormGroup className="mb-5" controlId="elevatorSchedule">
                                                <p className="fw-normal">Elevator Schedule</p>

                                                {
                                                    elevatorSchedule ?
                                                        <div>
                                                            <a
                                                                href={elevatorSchedule}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {elevatorSchedule}
                                                            </a>
                                                        </div> : ""
                                                }

                                                <Field name="elevator_schedule" type="file">
                                                    {({ field, form }:FieldProps<File>) => (
                                                        <div>
                                                            <input
                                                                disabled={isDisabled}
                                                                type="file"
                                                                name="elevator_schedule"
                                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                                    setShowSuccess(false);
                                                                    setErrorMessages([]);
                                                                    form.setFieldTouched("elevator_schedule", true, true);

                                                                    if (event.currentTarget.files) {
                                                                        form.setFieldValue("elevator_schedule", event.currentTarget.files[0]);
                                                                        setElevatorScheduleFile(event.currentTarget.files[0])

                                                                    } else {
                                                                        form.setFieldValue("elevator_schedule", null); // or set it to an empty string ""
                                                                    }}}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <ErrorMessage name="elevator_schedule" component="div" className="text-danger" />

                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row className="p-lg-5">

                                        {errorMessages.length > 0 && (
                                        <Alert variant="danger">
                                            <ul>
                                                {errorMessages.map((message, index) => (
                                                    <li key={index}>{message}</li>
                                                ))}
                                            </ul>
                                        </Alert>
                                    )}
                                    <Button onClick={() => {
                                        if (formik.errors) {
                                            Object.entries(formik.errors).forEach(([key, value]) => {
                                                setErrorMessages(prevState => [...prevState, `${key}: ${value}`]);
                                            });}
                                    }} type="submit" disabled={(!formik.touched.elevator_schedule && !formik.touched.safety_data_sheet)}>
                                        Save
                                    </Button>
                                    </Row>
                                </Form>
                            )
                        }
                    </Formik>
                </div>

                {/*MODALS / POP UP WINDOWS */}

                <Modal show={showEventModal} onHide={() => {
                    setShowEventModal(false)
                }} size={"lg"}>
                    <Modal.Header closeButton>
                        <Modal.Title>{`Create an Event`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateEvent
                            showCrew={true}
                            isQuoting={false}
                            submitEvent={true}
                            workOrder={id}
                            closeModal={() => {
                                setShowEventModal(false)
                            }}

                            onCreate=
                                {(createdObject,
                                  assignedEmployeeList,
                                  assignedResource: EventResourceUse[]) => {

                                    // Update Work Event table when a new event is added (w/o a page refresh)
                                    let fullWorkEvent: FullWorkEvent = {
                                        WorkEvent : createdObject,
                                        AssignedEmployeeList : assignedEmployeeList,
                                        AssignedResourceList : assignedResource,
                                        TimeSheetList : [],
                                        DriverTimeSheetList : []
                                    }
                                    setEventData(prevState => [...prevState, fullWorkEvent]);
                                    setShowEventModal(false)
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            setShowEventModal(false)
                        }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </StackedContentContainer>
             ) : (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )


    );
}

export default WorkOrderPage;