import {
    Address,
    formatAddress,
    getAddressLink,
    getDurationFromUnit,
    PartialEventResourceUse,
    Quote,
    QuoteAction,
    QuoteStatus,
    TimeSheet,
    User, UserGroup,
    UserQuote,
    WorkEvent,
    WorkOrder,
    WorkOrderActions,
    WorkOrderStatus
} from "../types";
import axios, {AxiosError} from "axios";
import {Button, ButtonGroup, Spinner, Stack} from "react-bootstrap";
import React from "react";
import { getWorkOrderPDF, postWOAction} from "../api/dataService";
import NavigationCard from "../components/NavigationCard";

export const getDate = (date:string) => {
    return new Date(date).toDateString()
}
// Used to generate the XX:XX Am / PM String value
export const getTimeString = (dateString: string, utc= false) => {
    const date = new Date(dateString);
    const hours = utc? date.getUTCHours(): date.getHours();
    const minutes = utc? date.getUTCMinutes(): date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}${period}`;
};

export const getDateTimeString = (date: string) => {
    const correctDate = new Date(date)
    return `${correctDate.toDateString()}, ${getTimeString(correctDate.toString())}`
}

export const getDateString = (dateString: string) => {
    const date = new Date(dateString);
    return date.toDateString();
}


export const getTimesheetDict = (today: Date, pastDayInterval: number) => {

    const timesheetsByDay: {[key: string]: TimeSheet[] } = {};

    for(let i = 0; i < pastDayInterval ; i ++) {

        const currentDate = new Date(today);

        currentDate.setDate(today.getDate() - i);

        timesheetsByDay[currentDate.toLocaleDateString()] = [];
    }

    return timesheetsByDay
}

// Returns true if the shift start date is between 8pm & 3sm
export const isNightShift = (start_day: string) => {
    const startHour = new Date(start_day).getHours()
    // Between 8pm & 3am?
    return startHour >= 20 || startHour <= 3
}
// get the time difference between two date times based on a start date & end date
export const getElapsedHours = (start_date: string, end_date: string) => {
    const start = new Date(start_date)
    const end = new Date(end_date)
    const timeDifferenceMS = end.getTime() - start.getTime()
    return getHoursFromMS(timeDifferenceMS).toString()

}

const getHoursFromMS = (time:number) => {
    return (time / (1000 * 60 * 60))
}

// get the datetime string from today's date
export const getDateFromToday = (years: number, months: number, days:number) => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + years)
    today.setMonth(today.getMonth() + months)
    today.setDate(today.getDate() + days)
    today.setSeconds(0,0)
    return today.toLocaleDateString()
}


export const  getTotalEventHours = (timesheetList: TimeSheet[]) => {
    return timesheetList.reduce((total, timeEntry) => timeEntry.start_date && timeEntry.end_date ? parseFloat(getElapsedHours(timeEntry.start_date,timeEntry.end_date)) + total : total, 0)
}


// Extracts server error messages from the response
export const extractErrorMessages = (data: any) => {
    const errorMessages: string[] = [];
    if (Array.isArray(data)) {
        // If data is an array, search for error messages in its objects
        data.forEach((obj) => {
            if (typeof obj === 'object') {
                Object.values(obj).forEach((value) => {
                    if (Array.isArray(value) && value.length > 0) {
                        errorMessages.push(value[0]);
                    }
                });
            }
        });
    } else if (typeof data === 'object') {
        // If data is an object, search for error messages in its values
        Object.values(data).forEach((value) => {
            if (Array.isArray(value) && value.length > 0) {
                errorMessages.push(value[0]);
            }
        });
    }
    return errorMessages;
};

// If errors arise in post requests, this function handles them.
export const handlePostError = (error:any, posttype: string) => {

    if (axios.isAxiosError(error)) {
        // AxiosError: Handle Axios error responses
        const axiosError = error as AxiosError;
        if (axiosError.response) {
            const errorResponseData = axiosError.response.data as Record<string, string[]>
            if (typeof errorResponseData === 'object') {
                // Extract and flatten field-specific error messages
                // TODO: Check flattening.
                //  Nested error arrays throw really annoying errors & sometimes the field name isn't here
                const fieldErrors = Object.values(errorResponseData).flatMap(messages => messages);
                console.log("FIELD ERRORS:::",fieldErrors)
                if (fieldErrors.length > 0) {
                    return(fieldErrors);
                } else {
                    console.error(`Error creating ${posttype}:`, errorResponseData);
                    return([]);
                }
            } else {
                console.error('Invalid AxiosError response data:', errorResponseData);
                return([]);
            }
        } else {
            console.error('AxiosError without a response:', axiosError);
            return([]);
        }
    } else {
        console.error(`Error creating ${posttype}:`, error);
        return([]);
    }
}

export function quoteToWorkOrder(quote: Partial<Quote>) {
    const workOrder: Partial<WorkOrder> = {
        consumables_notes: quote.consumables_notes,
        billing_client: quote.billing_client,
        job_notes: quote.job_notes,
        jobtype_set: quote.jobtype_set,
        special_skillsets: quote.special_skillsets,
        speciality_equipment_required: quote.speciality_equipment_required,
        tools_required: quote.tools_required,
    }
    return workOrder;
}

// Simple rounding function
export const roundTo = function(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
};

// calculates the cost of a resource use
export function calculateEquipmentUseCostDEPRECATED(resource: PartialEventResourceUse, start:string, end:string){
    if(resource.number_reserved && resource.rate_override_rate){
        return (
            parseFloat(resource.number_reserved.toString())) * (parseFloat(resource.rate_override_rate.toString())) * getDurationFromUnit(resource.unit, start, end)
    }else{
        return 0
    }
}

export function calculateEquipmentUseCost(resource: PartialEventResourceUse) {
    if(resource.unit && resource.unit !== ('unit' || 'no charge rate')){
        if(resource.number_reserved && resource.rate_override_rate && resource.duration) {
            return (
                parseFloat(resource.number_reserved.toString()) * parseFloat(resource.rate_override_rate.toString()) * parseFloat(resource.duration.toString())
            )
        }
        else return 0
    }
    else if (resource.number_reserved && resource.rate_override_rate) {
        return (
            parseFloat(resource.number_reserved.toString()) * parseFloat(resource.rate_override_rate.toString())
        )
    }
    else return 0
}

// Returns the calculation string
export function exposeEquipmentUseCostCalcDEPRECATED(resource:PartialEventResourceUse, start:string, end:string, showTotal= true ){
    return `${resource.unit !== ('unit' || 'no charge rate')?
        `${roundTo(getDurationFromUnit(resource.unit, start,end),2)} ${resource.unit}(s)  @`:''} $${
        resource.rate_override_rate} x ${resource.number_reserved} unit(s) ${showTotal? `= $${
            calculateEquipmentUseCost(resource)}`:''}`
}

export function exposeEquipmentUseCostCalc(resource:PartialEventResourceUse, showTotal= true ){


    return `${resource.unit !== ('unit' || 'no charge rate')?
        `${roundTo(resource.duration? resource.duration:0,2)} ${resource.unit}(s)  @`:''} $${
        resource.rate_override_rate} x ${resource.number_reserved} unit(s) ${showTotal? `= $${
        calculateEquipmentUseCost(resource)}`:''}`
}

export function isUserGroupArray(array: any[]): array is UserGroup[] {
    return array.every(item => typeof item === 'object' && 'id' in item && 'name' in item && 'permissions' in item);
}

// Replaces space characters with plus signs
export function encodeFilterString (string: string) {
    return string.replace(/ /g,'+')
}



export function getWorkOrderActions ( workOrder: WorkOrder,workOrderStatus: WorkOrderStatus, handleWorkOrderAction: (workOrder: WorkOrder,action : WorkOrderActions) => void) {
        let buttonsToRender;
        switch (workOrderStatus) {
            case WorkOrderStatus.open:
                buttonsToRender = (
                    <div className="d-flex justify-content-center">
                    <ButtonGroup className="btn-group-sm">
                        <Button onClick={() => handleWorkOrderAction(workOrder, WorkOrderActions.activated)} className="btn-success">Activate</Button>
                    </ButtonGroup>
                    </div>
                     );
        break;
    case WorkOrderStatus.activated:
        buttonsToRender = (
            <div className="d-flex justify-content-center">
            <ButtonGroup className="btn-group-sm">
            <Button className="btn-warning" style={{whiteSpace: 'nowrap'}} onClick={() => handleWorkOrderAction(workOrder, WorkOrderActions.closed)}> Prep Invoice</Button>
            </ButtonGroup>
    </div>
    )
        break;

    case WorkOrderStatus.closed:
        buttonsToRender = (
            <div className="d-flex justify-content-center">
            <ButtonGroup className="btn-group-sm">
            <Button className="btn-warning" onClick={() => handleWorkOrderAction(workOrder,WorkOrderActions.invoice)}>Invoice</Button>
    </ButtonGroup>
    </div>
    )
        break;

    case WorkOrderStatus.complete:
        buttonsToRender = (            <ButtonGroup className="btn-group-sm">
            <Button style={{whiteSpace: 'nowrap'}} onClick={() => handleWorkOrderAction(workOrder,WorkOrderActions.save)}>Download PDF</Button></ButtonGroup>)
        break;
    default:
        buttonsToRender = (
            <div className="d-flex justify-content-center">
                No Actions
            </div>
    )
    }
        return buttonsToRender
}



// Handles checking to see if a users groups contain any of the specified roles in the array
export const checkIsAllowed = (groups:any, roles: string[]) => {
    return roles.some((role) => (groups as string[]).includes(role))
}

export function getQuoteStatusFromAction(action: QuoteAction): QuoteStatus {
    switch (action) {
        case QuoteAction.push:
            return QuoteStatus.pushed;
        case QuoteAction.accept:
            return QuoteStatus.accepted;
        case QuoteAction.pco:
            return QuoteStatus.projectChangeOrder;
        case QuoteAction.revision:
            return QuoteStatus.revision;
        case QuoteAction.submit:
            return QuoteStatus.submitted;
        case QuoteAction.reject:
            return QuoteStatus.rejected;
        case QuoteAction.re_open:
            return QuoteStatus.opened;
        default:
            return QuoteStatus.pushed; // Handle the case where the action is not recognized
    }
}

export const getStatusCount = (listToSearch: any[], parameter: string, type:string ) => {
    if(type==='quote'){
        return listToSearch.filter((item) => item.quote_status === parameter).length
    }
    else if (type==='workorder'){
        return listToSearch.filter((item) => item.workorder_status === parameter).length
    }
    else {
        return listToSearch.filter((item) => item.status === parameter).length
    }
}



export const getQuoteUser = (quote_id: string, userList: User[], userQuoteList: UserQuote[]) => {
    const user_quote = userQuoteList.find((userQuote) => userQuote.quote === quote_id);

    if (user_quote) {
        return userList.find((user) => user.id === user_quote.user);
    }

    return undefined;
};

export const handleWorkOrderAction = async (workOrder: WorkOrder, action: WorkOrderActions) => {

    if(action === WorkOrderActions.save) {
        await getWorkOrderPDF(workOrder.id)
    } else {

        const response = await postWOAction(workOrder.id, action)

        if (response && !Array.isArray(response)) {
           // setShowSuccess(true)

        }
    }
}

// Gets a list of events for the home pages

export const returnEventList = (eventList: WorkEvent[], additionalButtons?: any) => {
    return (
        <Stack gap={3}>
            {
                eventList.length === 0 ? (
                    <div>You have nothing scheduled.</div>
                ) : (
                    eventList.map((entry, index) => (
                        entry.work_order ? (
                            <NavigationCard
                                title={`${(entry.work_order as WorkOrder).billing_client} - ${entry.event_name}`}
                                subtitle={`${getTimeString(entry.start_date)} - ${getTimeString(entry.end_date)}`}
                                body={
                                    entry.origin_address? (<a href={getAddressLink(entry.origin_address as Address)}>{formatAddress(entry.origin_address as Address)}</a>): "No Address Details"
                                }
                                subtext={entry.event_description}
                                link_destination={`/events/${entry.id}`}
                                additional_buttons={additionalButtons}
                                id = {index.toString()}
                                key = {`event-${index}`}
                            />

                        ) : (
                            <NavigationCard
                                title={`${entry.event_name}`}
                                subtitle={`${getDateTimeString(entry.start_date)} - ${getDateTimeString(entry.end_date)}`}
                                body={
                                    (entry.origin_address)?
                                        (<a href={getAddressLink(entry.origin_address as Address)} rel={"noopener"}>{formatAddress(entry.origin_address as Address)}</a>)
                                        : `${entry.event_description}`
                                }
                                subtext={entry.event_notes}
                                additional_buttons={additionalButtons}
                                link_destination={`/events/${entry.id}`}
                                id = {index.toString()}
                                key = {`event-${index}`}
                            />
                        )

                    ))
                )}
        </Stack>
    )
}



export const isLoadingSpinner = () => {
    return (<div className="d-flex justify-content-center">
        <Spinner  animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>)
}