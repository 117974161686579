import React, {useEffect, useRef, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../components/css/DatePicker.css";
import {Alert, Form, Modal, Tab, Tabs} from "react-bootstrap";
import {
    PartialWorkEvent,
    User,
    WorkOrder,
    Address,
    PartialEventResourceUse, Resource, Quote, WorkEvent, EventResourceUse, UserRoles, UserGroup
} from "../types";
import {
    getAddresses,
    getResources,
    getUsers,
    getWorkOrders,
    postEvent,
    updateEvent
} from "../api/dataService";
import {Navigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import * as Yup from 'yup'
import {ErrorMessage, Formik, Field} from "formik";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    calculateEquipmentUseCost,
    checkIsAllowed,
    getTimeString,
    handlePostError,
    isLoadingSpinner,
    roundTo
} from "../util/Helpers";
import DatePicker from "react-datepicker";
import {format} from 'date-fns'
import CreateAddress from "./CreateAddress";
import AddEventResources from "./AddEventResources";
import {useSelector} from "react-redux";
import {RootState} from "../redux/reduxTypes";



interface CreateEventProps {
    closeModal?: () => void;
    eventToUpdate?: PartialWorkEvent;
    resourcesOnEvent?: EventResourceUse[];
    onCreate?: (createdObject: WorkEvent, assignedEmployeeList: string[], assignedResources: EventResourceUse[]) => void,
    onUpdate?: (updatedObject: WorkEvent, assignedEmployeeList: string[], assignedResources: EventResourceUse[]) => void,
    submitEvent?: boolean,
    isQuoting?: boolean
    showCrew?: boolean
    workOrder?: string

}


const CreateEvent: React.FC<CreateEventProps> = (
    {
        closeModal,
        eventToUpdate,
        resourcesOnEvent,
        onCreate,
        onUpdate,
        submitEvent = true,
        isQuoting,
        workOrder  = '',
        showCrew,

    }) => {
    // Formik Validation
    const validationSchema = Yup.object().shape({
        event_name: Yup.string().required('Event Name is required'),
        event_description: Yup.string(),
        event_notes: Yup.string(),
        start_date: Yup.date().required('Start Date is required'),
        end_date: Yup.date().required('End Date is required'),
        status: Yup.string().required('Status is required'),
    });
    const formikRef = useRef<any>(null)
    const statusSelectRef = useRef<any>(null)
    const supervisorSelectRef = useRef<any>(null)
    const workOrderSelectRef = useRef<any>(null)
    const employeeSelectRef = useRef<any>(null)
    const originSelectRef = useRef<any>(null)
    const destinationSelectRef = useRef<any>(null)
    // Display values
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [showSuccess, setShowSuccess] = useState(false)
    const [showCreateAddress, setShowCreateAddress] = useState(false)
    const isUpdating = !!eventToUpdate
    const [isLoading, setIsLoading] = useState(true)
    // API Call Values
    const user = useSelector((state: RootState) => state.user)
    const [supervisorList, setSupervisorList] = useState<User[]>([])
    const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([])
    const [addressList, setAddressList] = useState<Address[]>([])
    const [resourcesList, setResourcesList] = useState<Resource[]>([])
    const [assignableEmployeeList, setAssignableEmployeeList] = useState<User[]>([])
    const [assignedEmployeeList, setAssignedEmployees] = useState<string[]>([])
    const [assignedResourcesList, setAssignedResources] = useState<any[]>(resourcesOnEvent||[])

    const [eventCost, setEventCost] = useState(eventToUpdate?.event_cost? eventToUpdate.event_cost: 0)
    const [startDate, setStartDate] = useState<string>(eventToUpdate?.start_date? eventToUpdate.start_date.toString():'')
    const [endDate, setEndDate] = useState<string>(eventToUpdate?.end_date? eventToUpdate.end_date.toString():'')


    // Initial values come from either props or are set to an empty state
    const initialValues = eventToUpdate || {
        event_name: '',
        event_description: '',
        event_notes: '',
        start_date: '',
        end_date: '',
        status: 'open',
        employeeuser_set: [],
        timesheetgeneral_set: [],
        work_order: undefined,
    }


    useEffect(() => {
        Promise.all([
            getWorkOrders(undefined,"?workorder_status=open" ),
            getUsers(undefined, "?is_active=true"),
            getAddresses(undefined),
            getResources(undefined)

            ])
            .then(([workOrdersResponse,
                       assignableEmployeeListResponse,
                       addressResponse,
                       resourceResponse
                   ]) => {
                if ('redirectTo' in workOrdersResponse) {
                    return (<Navigate to={workOrdersResponse.redirectTo} />);
                }
                if ('redirectTo' in assignableEmployeeListResponse){
                    return (<Navigate to={assignableEmployeeListResponse.redirectTo}/>)
                }
                if ('redirectTo' in addressResponse){
                    return (<Navigate to={addressResponse.redirectTo}/>)
                }
                if ('redirectTo' in resourceResponse) {
                    return (<Navigate to={resourceResponse.redirectTo}/>)
                }
                if (Array.isArray(workOrdersResponse)) {
                    setWorkOrderList(workOrdersResponse);
                }
                if (Array.isArray(assignableEmployeeListResponse)){
                    // Filter for only supervisor users, then get the entire employee list
                    setSupervisorList(assignableEmployeeListResponse.filter((user) => user.groups.some((elem)=> (elem as UserGroup).name === UserRoles.supervisor)))
                    setAssignableEmployeeList(assignableEmployeeListResponse)
                }
                if (Array.isArray(addressResponse)){
                    setAddressList(addressResponse)
                }
                if (Array.isArray(resourceResponse)){
                    setResourcesList(resourceResponse)
                }

                if(assignableEmployeeList && resourcesList && supervisorList && addressList) {
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false)
            });
    }, []);



    const handleSubmit = async (values: PartialWorkEvent) => {

        try {
            values.event_cost = roundTo(eventCost, 2)
            let response = undefined
            if(isUpdating && submitEvent){
                let data: any = values
                // Ensures the ID of the objects are being passed back
                data.quote =
                    values.quote?
                        (values.quote as Quote).id?
                            (values.quote as Quote).id: values.quote
                        :''
                data.work_order =
                    values.work_order?
                        (values.work_order as WorkOrder).id?
                            (values.work_order as WorkOrder).id: values.work_order
                        :''

                data.origin_address =
                    values.origin_address?
                        values.origin_address.id?
                            values.origin_address.id.toString():values.origin_address
                        : ''
                data.destination_address =
                    values.destination_address?
                        values.destination_address.id?
                            values.destination_address.id.toString() : values.destination_address
                        : ''
                data.supervisor =
                    values.supervisor?
                        values.supervisor.id?
                            values.supervisor.id: values.supervisor
                        :''
                data.invoice =
                    values.invoice?
                        values.invoice.id?
                            values.invoice.id: values.invoice
                        :''

                response = await updateEvent(eventToUpdate?.id ? eventToUpdate.id.toString() : "", values, assignedEmployeeList, assignedResourcesList)
            } else {


                if(workOrder) {
                    values.work_order = workOrder
                }

                response = submitEvent?
                    await postEvent(values, assignedEmployeeList, assignedResourcesList) :
                    values
            }
            // Indicates a success response
            if ((response && !Array.isArray(response)) || !submitEvent) {
                setErrorMessages([])
                setShowSuccess(true)
                // Reset form
                if(formikRef.current){ formikRef.current.resetForm() }
                if(statusSelectRef.current){ statusSelectRef.current.clearValue() }
                if(supervisorSelectRef.current){ supervisorSelectRef.current.clearValue() }
                if(workOrderSelectRef.current){ workOrderSelectRef.current.clearValue() }
                // Return the full new object
                if(onCreate || onUpdate){
                    let dataToReturn = (submitEvent && !isUpdating) ?
                        formatDataForReturn(response.data) : formatDataForReturn(response)
                    if(isUpdating && onUpdate){
                        onUpdate(dataToReturn, assignedEmployeeList, assignedResourcesList)
                    }
                    else if(onCreate){
                        onCreate(dataToReturn, assignedEmployeeList, assignedResourcesList)
                    }
                }

                if (closeModal) closeModal()

            }
        }
        catch (error) {
            setErrorMessages(handlePostError(error, 'Event'))
        }
    };


    // Parses data to see if there are keys, and if so - return the
    const formatDataForReturn = (originalData: any) => {
        let dataToReturn = originalData
        if(dataToReturn.origin_address) {
            dataToReturn.origin_address = addressList.find(address => address.id === dataToReturn.origin_address)
        }
        if(dataToReturn.destination_address) {
            dataToReturn.destination_address = addressList.find(address => address.id === dataToReturn.destination_address)
        }
        if(dataToReturn.supervisor){
            dataToReturn.supervisor = supervisorList.find(supervisor => supervisor.id === dataToReturn.supervisor)
        }
        if(assignedEmployeeList){
            dataToReturn.employeeuser_set = assignedEmployeeList.map((emp_id) => {
                return assignableEmployeeList.find(employee => employee.id === emp_id)
            })
        }

        // initialValues.employeeuser_set?.map(employee => ({
        //     label: `${employee.last_name}, ${employee.first_name}`,
        //     value: employee.id,

        return dataToReturn
    }

    const handleShowCreateAddress = () => {
        setShowCreateAddress(true)
    }

    const closeCreateAddress = () => {setShowCreateAddress(false)}

    const handleCreateAddress = (created_obj? : any) => {
        setShowCreateAddress(false)
        if(created_obj){
            setAddressList([created_obj,...addressList])
        }
    }

    const handleEquipmentCostChanges = (equipment: PartialEventResourceUse[]) => {
        setAssignedResources(equipment)
    }

    useEffect(() => {
        let currentTotal = 0
        if(startDate && endDate){
            assignedResourcesList.forEach((resource)=> {
                currentTotal+=calculateEquipmentUseCost(resource)
            })
            setEventCost(currentTotal)
        }
    }, [startDate, endDate, assignedResourcesList]);

    useEffect(() => {
        setIsLoading(true)
        if(eventToUpdate?.origin_address){
            let originaddress_id = eventToUpdate.origin_address? eventToUpdate.origin_address.id?
                eventToUpdate.origin_address.id.toString():eventToUpdate.origin_address : ''
            if(formikRef && formikRef.current){
                formikRef.current.setFieldValue('origin_address',originaddress_id)
            }
        }
        if(eventToUpdate?.destination_address){
            let destinationaddress_id = eventToUpdate.destination_address? eventToUpdate.destination_address.id?
                eventToUpdate.destination_address.id.toString():eventToUpdate.destination_address : ''
            if(formikRef && formikRef.current){
                formikRef.current.setFieldValue('destination_address',destinationaddress_id)
            }
        }
        if(eventToUpdate?.employeeuser_set){
            setAssignedEmployees(
                eventToUpdate.employeeuser_set.map((employee) => (
                    employee.id
                ))
            )
        }
        setIsLoading(false)
        // isUpdating ? (initialValues.employeeuser_set?.map(employee => ({
        //     label: `${employee.last_name}, ${employee.first_name}`,
        //     value: employee.id,
        // }))) : []}
    }, [eventToUpdate]);

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <Container>
            <Row className="justify-content-md-center">
                {errorMessages.length > 0 && (
                    <Alert variant="danger">
                        <ul>
                            {errorMessages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        Event created successfully!
                    </Alert>
                )}
                <Col xs={12} md={8}>
                    <Formik
                        enableReinitialize={true}
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {formik => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Tabs
                                    defaultActiveKey="details"
                                    id="event-creation-tabs"
                                    className="mb-3"
                                    justify
                                >
                                    <Tab eventKey="details" title="Details">
                                        <Row className={"mb-3"}>
                                            <Form.Group controlId="event_name">
                                                <Form.Label className={"fw-bold"}>Event Name</Form.Label>
                                                <Field
                                                    type="text"
                                                    name="event_name"
                                                    placeholder="Teardown Event"
                                                    as={Form.Control}
                                                    disabled={isUpdating &&
                                                        !checkIsAllowed(user.groups, [
                                                            UserRoles.ownership,
                                                            UserRoles.officeAdmin,
                                                            UserRoles.management,
                                                            UserRoles.sales
                                                        ])}
                                                />
                                                <ErrorMessage name="event_name" component="div" className="text-danger" />
                                            </Form.Group>
                                        </Row>
                                        <Row className={'mb-3'}>
                                            <Col className={"d-flex justify-content-center align-items-center text-center"}>
                                                <Form.Group controlId="start_date">
                                                    <div>
                                                        <Form.Label className={'fw-bold'}>Event Start Date & Time</Form.Label>
                                                    </div>
                                                    <div>
                                                        <DatePicker
                                                            id="start_date"
                                                            className={'datetime-select form-control'}
                                                            dateFormat={"MMMM d, h:mm aa"}
                                                            showTimeSelect
                                                            timeIntervals={15}
                                                            selected={
                                                                formik.values.start_date
                                                                    ? new Date(formik.values.start_date)
                                                                    : null
                                                            }
                                                            placeholderText={'mm/dd/yyyy hh:mm'}
                                                            onChange={(date: Date) => {
                                                                if (date) {
                                                                    const dateString = format(date, 'yyyy-MM-dd HH:mm:ss');
                                                                    setStartDate(dateString)

                                                                    formik.setFieldValue('start_date', dateString);
                                                                    if(formik.values.end_date && date > new Date(formik.values.end_date)){
                                                                        setEndDate('')
                                                                        formik.setFieldValue('end_date', '')
                                                                    }
                                                                }
                                                            }}
                                                            disabled={isUpdating &&
                                                                !checkIsAllowed(user.groups, [
                                                                    UserRoles.ownership,
                                                                    UserRoles.officeAdmin,
                                                                    UserRoles.management,
                                                                    UserRoles.sales
                                                                ])}
                                                        />
                                                    </div>
                                                    <ErrorMessage name="start_date" component="div" className="text-danger" />
                                                </Form.Group>
                                            </Col>
                                            <Col className={"d-flex justify-content-center align-items-center text-center"}>
                                                <Form.Group controlId="end_date">
                                                    <div>
                                                        <Form.Label className={'fw-bold'}>Event End Date & Time</Form.Label>
                                                    </div>
                                                    <div>
                                                        <DatePicker
                                                            id="end_date"
                                                            className={'datetime-select form-control'}
                                                            dateFormat={"MMMM d, h:mm aa"}
                                                            showTimeSelect
                                                            timeIntervals={15}
                                                            selected={
                                                                formik.values.end_date
                                                                    ? new Date(formik.values.end_date)
                                                                    : null
                                                            }
                                                            placeholderText={'mm/dd/yyyy hh:mm'}
                                                            onChange={(date: Date) => {
                                                                if (date) {
                                                                    const dateString = format(date, 'yyyy-MM-dd HH:mm:ss');
                                                                    setEndDate(dateString)
                                                                    formik.setFieldValue('end_date', dateString);
                                                                }
                                                            }}
                                                            filterTime={(time: Date) => {
                                                                if(formik.values.start_date){
                                                                    const start = new Date(formik.values.start_date)
                                                                    return time.getTime() > start.getTime()
                                                                }
                                                                else {return false}
                                                            }}
                                                            filterDate={(date: Date) => {
                                                                if(formik.values.start_date){
                                                                    const start = new Date(formik.values.start_date)
                                                                    if(date.toLocaleDateString() === start.toLocaleDateString()) return true
                                                                    return date >= start
                                                                }
                                                                else {return false}
                                                            }}
                                                            disabled={isUpdating &&
                                                                !checkIsAllowed(user.groups, [
                                                                    UserRoles.ownership,
                                                                    UserRoles.officeAdmin,
                                                                    UserRoles.management,
                                                                    UserRoles.sales
                                                                ])}
                                                        />
                                                    </div>
                                                    <ErrorMessage name="end_date" component="div" className="text-danger" />
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row className={"mb-3"}>
                                            <Form.Group controlId="event_description">
                                                <Form.Label className={'fw-bold'}>Event Description</Form.Label>
                                                <Field
                                                    component="textarea"
                                                    className={'form-control'}
                                                    name="event_description"
                                                    as={Form.Control}
                                                    placeholder="Enter Event Description"
                                                    disabled={isUpdating &&
                                                        !checkIsAllowed(user.groups, [
                                                            UserRoles.ownership,
                                                            UserRoles.officeAdmin,
                                                            UserRoles.management,
                                                            UserRoles.sales
                                                        ])}
                                                />
                                                <ErrorMessage name="event_description" component="div" className="text-danger" />
                                            </Form.Group>
                                        </Row>
                                        <Form.Group as={Row} className="mb-3" controlId="origin_address">
                                            <Row>
                                                <Form.Label column className={'fw-bold'} xs={2}>
                                                    Origin
                                                </Form.Label>
                                            </Row>
                                            <Col>
                                                <Select
                                                    ref={originSelectRef}
                                                    name="origin_address"
                                                    defaultValue={(initialValues.origin_address as Address)? ({
                                                        label: (initialValues.origin_address as Address).label? (initialValues.origin_address as Address).label:(initialValues.origin_address as Address).street,
                                                        value: (initialValues.origin_address as Address).id
                                                    }):undefined}
                                                    options={addressList.map(entry => ({
                                                        label: entry.label? `${entry.label} - ${entry.street}`:entry.street,
                                                        value: entry.id
                                                    })).sort((a, b) => a.label.localeCompare(b.label))}
                                                    onChange={(selectedOptions) => {
                                                        formik.setFieldValue('origin_address', selectedOptions? selectedOptions.value:'')
                                                    }}
                                                    isDisabled={isUpdating &&
                                                        !checkIsAllowed(user.groups, [
                                                            UserRoles.ownership,
                                                            UserRoles.officeAdmin,
                                                            UserRoles.management,
                                                            UserRoles.sales
                                                        ])}
                                                />
                                            </Col>
                                            <Col xs={2}>
                                                <Button
                                                    onClick={handleShowCreateAddress}
                                                    disabled={isUpdating &&
                                                        !checkIsAllowed(user.groups, [
                                                            UserRoles.ownership,
                                                            UserRoles.officeAdmin,
                                                            UserRoles.management,
                                                            UserRoles.sales
                                                        ])}
                                                >
                                                    Add
                                                </Button>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="destination_address">
                                            <Row>
                                                <Form.Label column className={'fw-bold'} xs={2}>
                                                    Destination
                                                </Form.Label>
                                            </Row>
                                            <Col>
                                                <Select
                                                    ref={destinationSelectRef}
                                                    name="destination_address"
                                                    defaultValue={initialValues.destination_address as Address? ({
                                                        label: (initialValues.destination_address as Address).label ? (initialValues.destination_address as Address).label:(initialValues.destination_address as Address).street,
                                                        value: (initialValues.destination_address as Address).id
                                                    }):undefined}
                                                    options={addressList.map(entry => ({
                                                        label: entry.label? `${entry.label} - ${entry.street}`:entry.street,
                                                        value: entry.id
                                                    })).sort((a, b) => a.label.localeCompare(b.label))}
                                                    onChange={(selectedOptions) => {
                                                        formik.setFieldValue('destination_address', selectedOptions? selectedOptions.value:'')
                                                    }}

                                                    isDisabled={isUpdating &&
                                                        !checkIsAllowed(user.groups, [
                                                            UserRoles.ownership,
                                                            UserRoles.officeAdmin,
                                                            UserRoles.management,
                                                            UserRoles.sales
                                                        ])}
                                                />
                                            </Col>
                                            <Col xs={2}>
                                                <Button
                                                    onClick={handleShowCreateAddress}
                                                    disabled={isUpdating &&
                                                        !checkIsAllowed(user.groups, [
                                                            UserRoles.ownership,
                                                            UserRoles.officeAdmin,
                                                            UserRoles.management,
                                                            UserRoles.sales
                                                        ])}
                                                >
                                                    Add
                                                </Button>
                                            </Col>
                                        </Form.Group>

                                        <Row className={'mb-3'}>
                                            <Form.Group controlId="event_notes">
                                                <Form.Label className={'fw-bold'}>Event Notes</Form.Label>
                                                <Field
                                                    component="textarea"
                                                    className={'form-control'}
                                                    name="event_notes"
                                                    as={Form.Control}
                                                    placeholder="Enter Event Notes"

                                                />
                                            </Form.Group>
                                        </Row>
                                        {/*
                                            Note: There doesn't seem to be an issue with removing these, but leaving
                                            code here for now, I can see a use case where you might want to directly
                                            assign a quote or workorder to an event. Mostly admin use case though.
                                        */}
                                        {/*<Row className={'mb-3'} hidden>
                                            <Form.Group controlId="work_order">
                                                <Form.Label className={'fw-bold'}>Set Work Order</Form.Label>
                                                <Select
                                                    ref={workOrderSelectRef}
                                                    name="work_order"
                                                    defaultValue={initialValues.work_order? ({
                                                        label: `${(initialValues.work_order as WorkOrder).billing_client} - WO${(initialValues.work_order as WorkOrder).id}`,
                                                        value: (initialValues.work_order as WorkOrder).id,
                                                    }):undefined}
                                                    options={workOrderList.map(entry => ({
                                                        label: `${entry.billing_client} - WO${entry.id}`,
                                                        value: entry.id,
                                                    }))}
                                                    isClearable={true}
                                                    onChange={(selectedOptions) => {
                                                        formik.setFieldValue('work_order', selectedOptions? selectedOptions.value:'')
                                                    }}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className={'mb-3'} hidden>
                                            <Form.Group controlId="status">
                                                <Form.Label className={'fw-bold'}>Event Status</Form.Label>
                                                <Select
                                                    ref={statusSelectRef}
                                                    name="status"
                                                    defaultValue={initialValues.status? ({
                                                        label: (initialValues.status as WorkEventStatus).toString(),
                                                        value: (initialValues.status as WorkEventStatus).toString(),
                                                    }):undefined}

                                                    options={WorkEventStatusArray.map(status => ({
                                                        label: status,
                                                        value: status,
                                                    }))}

                                                    onChange={(selectedOptions) => {
                                                        formik.setFieldValue('status', selectedOptions? selectedOptions.value:'open')
                                                    }}
                                                />
                                                <ErrorMessage name="status" component="div" className="text-danger" />
                                            </Form.Group>
                                        </Row>*/}
                                    </Tab>
                                    {
                                        !checkIsAllowed(user.groups, [UserRoles.officeAdmin]) && (
                                            <Tab eventKey="resources" title="Resources">
                                                <AddEventResources
                                                    resourceList={resourcesList}
                                                    isQuoting={isQuoting}
                                                    workevent={formik.values}
                                                    onChange={handleEquipmentCostChanges}
                                                    eventResources={assignedResourcesList? assignedResourcesList: []}
                                                />
                                            </Tab>
                                        )
                                    }
                                    {/*
                                    If issues are encountered here, it may be due to the ! operator.
                                    To fix, update to be any role except office admin rather than using the !
                                    */}
                                    {showCrew && !checkIsAllowed(user.groups, [UserRoles.officeAdmin])?
                                        (<Tab eventKey="crew" title="Crew">
                                        <Form.Group controlId="supervisor" className={'my-3'}>
                                            <Form.Label>Add Supervisor</Form.Label>
                                            <Select
                                                ref={supervisorSelectRef}
                                                name="supervisor"
                                                defaultValue={initialValues.supervisor ? ({
                                                    label: `${initialValues.supervisor.last_name}, ${initialValues.supervisor.first_name}`,
                                                    value: initialValues.supervisor.id,
                                                }) : undefined}
                                                options={supervisorList.map(entry => ({
                                                    label: `${entry.last_name}, ${entry.first_name}`,
                                                    value: entry.id,
                                                })).sort((a, b) => a.label.localeCompare(b.label))}
                                                isClearable={true}
                                                onChange={(selectedOptions) => {
                                                    formik.setFieldValue('supervisor', selectedOptions ? selectedOptions.value : '')
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="employeeuser_set" className={'my-3'}>
                                            <Form.Label>Assign Employees</Form.Label>
                                            <Select
                                                ref={employeeSelectRef}
                                                name="employeeuser_set"
                                                defaultValue={isUpdating ? (initialValues.employeeuser_set?.map(employee => ({
                                                    label: `${employee.last_name}, ${employee.first_name}`,
                                                    value: employee.id,
                                                }))) : []}
                                                options={assignableEmployeeList.map(entry => ({
                                                    label: `${entry.last_name}, ${entry.first_name}`,
                                                    value: entry.id,
                                                })).sort((a, b) => a.label.localeCompare(b.label))}
                                                isMulti
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions.map(option => option.value)
                                                    // formik.setFieldValue('employeeuser_set', selectedValues)
                                                    setAssignedEmployees(selectedValues)
                                                }}
                                            />
                                        </Form.Group>
                                    </Tab>) :(<></>)
                                    }
                                    <Tab eventKey="review" title="Review">
                                        <ReviewLine
                                            title={'Event Name:'}
                                            body={formik.values.event_name?
                                                formik.values.event_name:'No Name Provided'
                                            }
                                            textStyle={formik.values.event_name? 'text-success':'text-danger'}
                                        />

                                        {(checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management, UserRoles.sales])) &&
                                            <ReviewLine
                                                title={'Total Cost:'}
                                                body={`$${roundTo(eventCost, 2)}`}
                                            />
                                        }

                                        <ReviewLine
                                            title={'Start Date:'}
                                            body={
                                                formik.values.start_date?
                                                    `${
                                                        new Date(formik.values.start_date).toDateString()
                                                    } @ ${
                                                        getTimeString(formik.values.start_date)
                                                    }`
                                                    : 'No Date / Time Selected'
                                                }
                                            textStyle={formik.values.start_date? 'text-success':'text-danger'}
                                        />
                                        <ReviewLine
                                            title={'End Date:'}
                                            body={
                                                formik.values.end_date?
                                                    `${
                                                        new Date(formik.values.end_date).toDateString()
                                                    } @ ${
                                                        getTimeString(formik.values.end_date)
                                                    }`
                                                    : 'No Date / Time Selected'
                                            }
                                            textStyle={formik.values.end_date? 'text-success':'text-danger'}
                                        />

                                        <div className="d-grid gap-2 col-8 mx-auto mt-2">
                                            <Button type="submit"
                                                    variant="primary"
                                                    className="btn btn-primary"
                                                    disabled={formik.isSubmitting}
                                            >
                                                {!submitEvent? 'Save Event' : isUpdating ? 'Update Event' : 'Create Event'}
                                            </Button>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
            <Modal show={showCreateAddress} onHide={closeCreateAddress} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateAddress closeModal={closeCreateAddress} onCreate={handleCreateAddress}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeCreateAddress} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

interface ReviewLineProps {
    title:string,
    body:string
    textStyle?:string
}
const ReviewLine: React.FC<ReviewLineProps> = ({title, body,textStyle}) => {
    return(
        <Row>
            <Col className={'text-end'}>
                <strong>{title}</strong>
            </Col>
            <Col className={`text-start ${textStyle}`}>
                <i>{body}</i>
            </Col>
        </Row>
    )
}

export default CreateEvent