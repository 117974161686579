import React, {useEffect, useRef, useState} from "react";
import {Alert, Form} from "react-bootstrap";
import {UserGroup, PartialUser, User, UserRoles} from "../types";
import {getGroups, postUser, updateUser} from "../api/dataService";
import {Navigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import * as Yup from 'yup'
import {ErrorMessage, Formik, Field} from "formik";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {checkIsAllowed, handlePostError, isLoadingSpinner, isUserGroupArray} from "../util/Helpers";
import {useSelector} from "react-redux";
import {RootState} from "../redux/reduxTypes";

const parentStyle = {
    padding: '2rem',
}

interface CreateEmployeeProps {
    closeModal?: () => void;
    employeeToUpdate?: PartialUser;
    onCreate?: (createdObject: PartialUser) => void
    onUpdate?: (updatedObject: User) => void
}
const CreateEmployee: React.FC<CreateEmployeeProps> = (props) => {
    const user = useSelector((state:RootState) => state.user)
    const [isLoading, setIsLoading] = useState(true)
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
        confirmpassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string().required('Last Name is required'),
        mobile_number: Yup.string().matches(/^(?:\+\d{1,3})?(?:[-.()\s]*\d{1,}){8,}$/, 'Invalid mobile number format').required('Mobile Number is required'),
        emergency_contact_name: Yup.string(),
        emergency_mobile_number: Yup.string().matches(/^(?:\+\d{1,3})?(?:[-.()\s]*\d{1,}){8,}$/, 'Invalid mobile number format'),
        groups: Yup.array().required('Permission Groups are required'),
        experience_status: Yup.string().required('Experience Status is required'),
    });
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [roleList, setRoleList] = useState<UserGroup[]>([]);
    const [showSuccess, setShowSuccess] = useState(false)
    const formikRef = useRef<any>(null)
    const groupsSelectRef = useRef<any>(null)
    const isUpdating = !!props.employeeToUpdate
    const initialValues = props.employeeToUpdate || {
        username: '',
        password: '',
        email: '',
        first_name: '',
        last_name: '',
        mobile_number: '',
        emergency_contact_name: '',
        emergency_mobile_number: '',
        groups: [],
        experience_status: '',
        is_staff: false
    }
    useEffect(() => {
        Promise.all([getGroups(undefined)])
            .then(([roleResponse]) => {
                if ('redirectTo' in roleResponse) {
                    return (<Navigate to={roleResponse.redirectTo} />);
                }
                if (Array.isArray(roleResponse)) {
                    if(checkIsAllowed(user.groups, [UserRoles.ownership])){
                        setRoleList(roleResponse);
                    }
                    else{
                        setRoleList(roleResponse.filter((role)=> {
                            return (
                                role.name !== UserRoles.ownership &&
                                role.name !== UserRoles.management &&
                                role.name !== UserRoles.sales &&
                                role.name !== UserRoles.dispatch
                            )
                        }))
                    }
                }
                setIsLoading(false)
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });

    }, []);
    const handleSubmit = async (values: PartialUser | User) => {
        try {
            let response = undefined
            if(isUpdating){
                let data:any = values
                if(values.groups && isUserGroupArray(values.groups)) {
                    const groups = values.groups?.map((group) => (group as UserGroup).id )
                    data.groups = groups
                }



                response = await updateUser(props.employeeToUpdate?.id? props.employeeToUpdate.id: "", data)
            }
            else{
                values.last_login = new Date().toISOString()
                values.is_superuser = false
                values.is_active = true
                values.user_permissions = []
                response = await postUser(values);
            }
            if (response && !Array.isArray(response)) {
                if(!isUpdating && response.status>=200 && response.status < 300){
                    setErrorMessages([])
                    setShowSuccess(true)
                    // Reset form
                    if(formikRef.current) { formikRef.current.resetForm() }
                    if(groupsSelectRef.current) { groupsSelectRef.current.clearValue() }
                    if(props.closeModal){ props.closeModal() }
                    if(props.onCreate) { props.onCreate(response.data) }


                }else if(isUpdating){
                    let dataToReturn: any = response
                    dataToReturn.groups = response.groups.map((group_id: string) => roleList.find(role => role.id === group_id))

                    if(props.onUpdate) props.onUpdate(dataToReturn)
                }
            }
        }
        catch (error) {
            setErrorMessages(handlePostError(error, 'Employee'))
        }
    }

    useEffect(() => {
        setIsLoading(true)
        if(isUpdating && props.employeeToUpdate?.groups){
            if(formikRef && formikRef.current){
                formikRef.current.setFieldValue('groups', props.employeeToUpdate.groups.map((group)=> {
                    if (typeof group === 'string'){
                        return group
                    }
                    else{
                        return group.id
                    }
                }))
            }
        }
        setIsLoading(false)
    }, [isUpdating, props.employeeToUpdate]);

    if (isLoading) {
        return (isLoadingSpinner());
    }

    return (
        <Container style={parentStyle}>
            <Row className="justify-content-md-center">
                <Col xs={12} md={8}>
                    <Formik
                        enableReinitialize={true}
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {formik => (
                            <Form onSubmit={formik.handleSubmit}>
                                {errorMessages.length > 0 && (
                                    <Alert variant="danger">
                                        <ul>
                                            {errorMessages.map((message, index) => (
                                                <li key={index}>{message}</li>
                                            ))}
                                        </ul>
                                    </Alert>
                                )}
                                {showSuccess && (
                                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                                        Employee created successfully!
                                    </Alert>
                                )}
                                <Form.Group controlId="username" className={'mb-3'}>
                                    <Form.Label>Username</Form.Label>
                                    <Field type="text" name="username" as={Form.Control} placeholder="Enter Username"/>
                                    <ErrorMessage name="username" component="div" className="text-danger" />
                                </Form.Group>
                                <Form.Group controlId="email" className={'mb-3'}>
                                    <Form.Label>Email</Form.Label>
                                    <Field type="email" name="email" as={Form.Control} placeholder="Enter Email" />
                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                </Form.Group>
                                { !isUpdating && (
                                    <>
                                        <Form.Group controlId="password" className={'mb-3'}>
                                            <Form.Label>Password</Form.Label>
                                            <Field
                                                as={Form.Control}
                                                type="password"
                                                name="password"
                                                autoComplete="new-password"
                                                placeholder={"Enter Password"}
                                            />
                                            <ErrorMessage name="password" component="div" className="text-danger"/>
                                        </Form.Group>
                                        <Form.Group controlId="confirmpassword" className={'mb-3'}>
                                            <Form.Label>Re-Type Password</Form.Label>
                                            <Field
                                                as={Form.Control}
                                                type="password"
                                                name="confirmpassword"
                                                autoComplete="new-password"
                                                placeholder={"Confirm Password"}
                                            />
                                            <ErrorMessage name="confirmpassword" component="div" className="text-danger"/>
                                        </Form.Group>

                                    </>
                        )
                        }
                                <Form.Group controlId="first_name" className={'mb-3'}>
                                    <Form.Label>First Name</Form.Label>
                                    <Field type="text" name="first_name" as={Form.Control} placeholder="Enter First Name" />
                                    <ErrorMessage name="first_name" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="last_name" className={'mb-3'}>
                                    <Form.Label>Last Name</Form.Label>
                                    <Field type="text" name="last_name" as={Form.Control} placeholder="Enter Last Name" />
                                    <ErrorMessage name="last_name" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="mobile_number" className={'mb-3'}>
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Field type="tel" name="mobile_number" as={Form.Control} placeholder="Enter in the format of xxx-xxx-xxxx" />
                                    <ErrorMessage name="mobile_number" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="emergency_contact_name" className={'mb-3'}>
                                    <Form.Label>Emergency Contact Name</Form.Label>
                                    <Field type="text" name="emergency_contact_name" as={Form.Control} placeholder="Enter Emergency Contact Name" />
                                    <ErrorMessage name="emergency_contact_name" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="emergency_mobile_number" className={'mb-3'}>
                                    <Form.Label>Emergency Contact Phone Number</Form.Label>
                                    <Field type="tel" name="emergency_mobile_number" as={Form.Control} placeholder="Enter in the format of xxx-xxx-xxxx" />
                                    <ErrorMessage name="emergency_mobile_number" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="groups" className={'mb-3'}>
                                    <Form.Label>Permission Groups</Form.Label>
                                    <Select
                                        ref={groupsSelectRef}
                                        defaultValue={isUpdating? (initialValues.groups?.map(role => ({
                                            label: (role as UserGroup).name,
                                            value: (role as UserGroup).id,
                                        }))):[]}
                                        name="groups"
                                        options={roleList.map(entry => ({
                                            label: entry.name,
                                            value: entry.id,
                                        })).sort((a, b) => a.label.localeCompare(b.label))}
                                        isMulti
                                        onChange={(selectedOptions) => {
                                            const selectedValues = selectedOptions.map(option => option.value? option.value:option)
                                            console.log("GROUPS", selectedOptions)
                                            formik.setFieldValue('groups', selectedValues)
                                        }}
                                    />
                                    <ErrorMessage name="groups" component="div" className="text-danger" />
                                </Form.Group>

                                <Form.Group controlId="experience_status" className={'mb-3'}>
                                    <Form.Label>Experience Status</Form.Label>
                                    <Field as={Form.Select} name="experience_status">
                                        <option value="" disabled>Select Experience Status</option>
                                        <option value="trainer">Trainer</option>
                                        <option value="experienced">Experienced</option>
                                        <option value="senior">Senior</option>
                                    </Field>
                                    <ErrorMessage name="experience_status" component="div" className="text-danger" />
                                </Form.Group>
                                {
                                    checkIsAllowed(user.groups, [UserRoles.ownership]) ? (
                                    <Form.Group controlId="is_staff" className={'mb-3'}>
                                        <Form.Check
                                            type="checkbox"
                                            label="Backend Access (Only Provide To Administrative Users)"
                                            name="is_staff"
                                        />
                                    </Form.Group>): (<></>)
                                }

                                <div className="d-grid gap-2 col-8 mx-auto mt-2">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="btn btn-primary"
                                        disabled={formik.isSubmitting}
                                    >
                                        {isUpdating? "Update Employee":"Create Employee"}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default CreateEmployee