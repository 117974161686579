import Container from "react-bootstrap/Container";
import React, {useEffect, useMemo, useState} from "react";
import {ActionResponse, Quote, QuoteAction, QuoteStatus, UserQuote, UserRoles} from "../../types";
import {deleteQuote, getQuotePDF, getQuotes, getUserQuotes, postQuoteAction} from "../../api/dataService";
import {ButtonGroup, Stack} from "react-bootstrap";
import StackedContentContainer from "../ContentContainer";
import {
    checkIsAllowed,
    getDateTimeString,
    getQuoteStatusFromAction,
    handlePostError
} from "../../util/Helpers";
import {ColumnDef, Row} from "@tanstack/react-table";
import {Navigate, useNavigate} from "react-router-dom";
import SearchableTable from "../SearchableTable";
import Button from "react-bootstrap/Button";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxTypes";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import QuoteActionButtons from "../QuoteActionButtons";

interface QuoteStatusViewProps {
        label: string,
        filter: string
    }

const parentStyle = {
    padding: '2rem',
}

/**
* Similar to ManageQuotes expect this component renders a table of quotes based on quote status.
 *
 * @param label: Name of Quote Status to display (human-readable)
 * @param filter: Name of QuoteStatus value to filter by (server-side)
 * @constructor
 */
const QuoteStatusView: React.FC<QuoteStatusViewProps> = (props) => {
    const user = useSelector((state: RootState) => state.user)
    const [quoteData, setQuoteData] = useState<Quote[]>([])
    const [selectedRowToDelete, setSelectedRowToDelete] = useState<Row<Quote> | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userQuoteList, setUserQuoteList] = useState<UserQuote[]>([])
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()


    useEffect(() => {
        Promise.all([getQuotes(undefined,`?quote_status=${props.filter}`), getUserQuotes(undefined, `?user=${user.userid}`)])
            .then(([quoteResponse,userQuoteResponse]) => {
                if('redirectTo' in userQuoteResponse) { return <Navigate to={userQuoteResponse.redirectTo}/> }
                if('redirectTo' in quoteResponse) { return <Navigate to={quoteResponse.redirectTo}/> }

                if(quoteResponse && Array.isArray(quoteResponse) && userQuoteResponse && Array.isArray(userQuoteResponse)  ) {

                    // Quote Viewing Permission Logic
                    if(checkIsAllowed(user.groups,[UserRoles.management, UserRoles.ownership, UserRoles.sales])){
                        // Sort Quotes, so that most recent appear first
                        setQuoteData(quoteResponse.slice().reverse())
                    } else {
                        // Filter quotes so that only quotes the user wrote appear
                        // TODO: Remove as quotes should be available for READ_ONLY / This will never reached
                        setQuoteData(quoteResponse.filter((entry) => (userQuoteResponse.some(element=> element.quote === entry.id))
                            )
                        )
                    }
                    setIsLoading(false);
                } else {
                    setQuoteData([])
                }

                if(Array.isArray(userQuoteResponse)) {
                    setUserQuoteList(userQuoteResponse)
                }


            }).catch(error => {
                console.log("Error fetching data:",error);
                setIsLoading(false)
        })
    }, [props.filter,props.label]);


    const columns = useMemo<ColumnDef<Quote, any>[]>(
        () => [
            {
                accessorFn: row => `${row.id}`,
                id: 'quote_id',
                header: () => 'Quote ID',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.billing_client,
                id: 'jobsite_client',
                header: () => 'Billing Client',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.jobtype_set,
                id: 'job_type',
                header: () => 'Job Type',
                enableColumnFilter: false
            },
            {
                accessorFn: row => row.quote_status,
                id: 'status',
                header: () => 'Status',
                enableColumnFilter: false
            },
            {
                accessorFn: row => `${getDateTimeString(row.last_change_time)}`,
                id: 'last_change_time',
                header: () => 'Last Modified',
                enableColumnFilter: false
            },
            {
                id: "viewWorkOrder",
                cell: ({row}) => {
                    return  <Button className="btn-secondary btn-sm" onClick={() => handleOpenDetails(row)}>View</Button>
                },
            },
            {
                id: 'actions',
                header: () => 'Actions',
                cell: ({row}) => {

                    // Quote Actions permission logic
                    if(checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management])
                        || userQuoteList.some(element => element.quote === row.original.id)) {
                        if((row.original.quote_status === QuoteStatus.rejected || row.original.quote_status === QuoteStatus.opened)) {
                            return <div className=" d-flex justify-content-center"><QuoteActionButtons quote_status={row.original.quote_status} quote_id={row.original.id}/><Button className="btn-sm btn-danger" onClick={() => handleDeleteRow(row)}>Delete</Button></div>
                        }
                        return <QuoteActionButtons quote_status={row.original.quote_status} quote_id={row.original.id}/>
                    } else {
                        return (
                            <div className="d-flex justify-content-center">
                                <ButtonGroup className=" btn-group-sm">
                                    <Button
                                        onClick={() => handleQuoteAction(row.original.id, QuoteAction.save)}
                                        className="btn-primary"
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        Download PDF
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )
                    }

                }
            }
        ],
        [userQuoteList]
    )

    const handleQuoteAction = async (quote_ID: string, quoteAction: QuoteAction) => {
        try {

            if(quoteAction === QuoteAction.save) {
                await getQuotePDF(quote_ID)
            } else {
                const response = await postQuoteAction(quote_ID,quoteAction)

                let QuoteActionResponse = response as ActionResponse

                if (QuoteActionResponse && QuoteActionResponse.success) {

                    navigate(`/quotes/${getQuoteStatusFromAction(quoteAction) ? getQuoteStatusFromAction(quoteAction) : QuoteStatus.none}`)

                }
            }
        } catch (error) {
            setErrorMessages(handlePostError(error, 'QuoteAction'))
        }
    }

    const handleDeleteRow = (row: Row<Quote>) => {
        setSelectedRowToDelete(row);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        if (selectedRowToDelete) {
            deleteQuote(selectedRowToDelete.original.id.toString(), selectedRowToDelete.original).then(r => {
                    if(r && !Array.isArray(r)){
                        if(r.status >= 200 || r.status <= 300){
                            const dataCopy = [...quoteData]
                            dataCopy.splice(selectedRowToDelete.index, 1)
                            setQuoteData(dataCopy)
                        }
                    }
                }
            )
            // Close the delete modal
            setShowDeleteModal(false);
        }
    };

    const handleOpenDetails = (row: Row<Quote>) => {
        navigate(`/quotes/${row.original.id}`, {state: row.original})
    }
    
    return (
        quoteData.length === 0 ? (
            <Container>
                <Stack className="center" gap={5}>
                    <div className="text-center p-2"><h3>{props.label}</h3></div>
                    <div className="p-2">
                        <p className="text-lg-center"> No Quotes to Display </p>
                    </div>
                </Stack>
            </Container>
            ) : (
                    <div style={parentStyle}>
                        <StackedContentContainer title={props.label}>
                            <SearchableTable columns={columns} data={quoteData}/>
                        </StackedContentContainer>
                        <DeleteConfirmationModal
                            show={showDeleteModal}
                            onHide={() => setShowDeleteModal(false)}
                            onConfirm={handleConfirmDelete}
                        />
                    </div>
        )

    )
}

export default QuoteStatusView;