import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import React, {useRef, useState} from "react";
import {ResourceTypeArray, PartialResource, Resource,} from "../types";
import {Alert, InputGroup} from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import {ErrorMessage, Field, Formik} from "formik";
import {handlePostError} from "../util/Helpers";
import {postResource, updateResource} from "../api/dataService";
interface CreateResourceProps {
    resourceToUpdate? : PartialResource
    closeModal?: (values?: PartialResource) => void
    onCreate?: (createdObject: PartialResource) => void
    onUpdate?: (updatedObject: Resource) => void
}

const CreateResource: React.FC<CreateResourceProps> = (props) => {
    const isUpdating = !!props.resourceToUpdate
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [showSuccess, setShowSuccess] = useState(false)
    const formikRef = useRef<any>(null)
    const typeSelectRef = useRef<any>(null)
    const validationSchema = Yup.object().shape({
        resource_name: Yup.string().required('Resource Name is Required'),
        type: Yup.string().required('Resource Type is Required'),
        unit_billing_rate: Yup.number().required('Unit Billing Rate is Required'),
        hourly_billing_rate: Yup.number().required('Hourly Rate is Required'),
        daily_billing_rate: Yup.number().required('Daily Rate is Required'),
        weekly_billing_rate: Yup.number().required('Weekly Billing Rate is Required'),
        monthly_billing_rate: Yup.number().required('Monthly Billing Rate is Required'),
        stock: Yup.number().required('Current Stock Count is Required'),
        minimum_stock: Yup.number().required('Minimum Stock Count is Required')
    })
    const initialValues = props.resourceToUpdate || {
        resource_name: '',
        type: 'equipment',
        unit_billing_rate: 0,
        hourly_billing_rate:0,
        daily_billing_rate: 0,
        weekly_billing_rate: 0,
        monthly_billing_rate: 0,
        stock: 0,
        minimum_stock: 0
    }
    const handleSubmit = async(values: PartialResource) => {
        try {
            let response = undefined
            if(isUpdating){
                response = await updateResource(props.resourceToUpdate?.id? props.resourceToUpdate.id.toString(): '',values)
            }
            else {
                response = await postResource(values)
            }
            if (response && !Array.isArray(response)) {
                setErrorMessages([])
                setShowSuccess(true)
                if(typeSelectRef.current) typeSelectRef.current.clearValue()
                if (formikRef.current) formikRef.current.resetForm()
                if (props.closeModal) props.closeModal()
                if (props.onCreate) props.onCreate(response.data)
                if(isUpdating && props.onUpdate) props.onUpdate(response)

            }
        }
        catch (error) {
            setErrorMessages(handlePostError(error, 'Address'))
        }
    }



    return (
        <Container>
            <Row className={"justify-content-center"}>
                {errorMessages.length > 0 && (
                    <Alert variant={'danger'}>
                        <ul>
                            {errorMessages.map((message, index) => (
                                <li key={index}>{message}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                {showSuccess && (
                    <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        Resource created successfully!
                    </Alert>
                )}
            </Row>
            <Row className={'justify-content-center'}>
                <Formik
                    enableReinitialize={true}
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <Form onSubmit={formik.handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Resource Name</Form.Label>
                                    <Field name={'resource_name'} type={'text'} placeholder={"Resource Name"} as={Form.Control} />
                                    <ErrorMessage name={'resource_name'} component={'div'} className={'text-danger'}/>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Resource Type</Form.Label>
                                    <Select
                                        ref={typeSelectRef}
                                        name={`resource_type`}
                                        options={ResourceTypeArray.map((entry) => ({
                                            label: entry,
                                            value: entry,
                                        })).sort((a, b) => a.label.localeCompare(b.label))}

                                        defaultValue={isUpdating? ({
                                            label: (initialValues.type as string),
                                            value: (initialValues.type as string)
                                        }):undefined}

                                        onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                formik.setFieldValue('type', selectedOption.value)
                                            }
                                        }}
                                    />
                                    <ErrorMessage name={'resource_type'} component={'div'} className={'text-danger'}/>
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Field style={{ height: '100px' }} name={'description'} component={'textarea'} className={'form-control'} placeholder={'Resource Description'} as={Form.Control}/>
                            </Form.Group>

                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Current Stock</Form.Label>
                                    <Field
                                        name={'stock'}
                                        as={Form.Control}
                                        type="number"
                                        min={0}
                                        step={1}
                                        placeholder={'100'}
                                    />
                                    <ErrorMessage name={'stock'} component={'div'} className={'text-danger'}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="minimum_stock">
                                    <Form.Label>Minimum Stock</Form.Label>
                                    <Field
                                        name={'minimum_stock'}
                                        as={Form.Control}
                                        type={"number"}
                                        min={0}
                                        step={1}
                                        placeholder={'10'}
                                    />
                                    <ErrorMessage name={'minimum_stock'} component={'div'} className={'text-danger'}/>
                                </Form.Group>
                            </Row>
                            <Row className={'justify-content-center text-center'}>
                                <Form.Group as={Row} className="mb-3" controlId="unit_billing_rate">
                                    <Form.Label className={""} column xs={6}>Per Unit Billing Rate</Form.Label>
                                    <Col xs={4}>
                                        <InputGroup className="mb-2">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Field
                                                name={'unit_billing_rate'}
                                                as={Form.Control}
                                                type="number"
                                                min={0}
                                                step={0.01}
                                                placeholder={'100.00'}
                                            />
                                            <ErrorMessage name={'unit_billing_rate'} component={'div'} className={'text-danger'}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="hourly_billing_rate">
                                    <Form.Label column xs={6}>Per Hour Billing Rate</Form.Label>
                                    <Col xs={4}>
                                        <InputGroup className="mb-2">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Field
                                                name={'hourly_billing_rate'}
                                                as={Form.Control}
                                                type="number"
                                                min={0}
                                                step={0.01}
                                                placeholder={'80.00'}
                                            />
                                            <ErrorMessage name={'hourly_billing_rate'} component={'div'} className={'text-danger'}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="daily_billing_rate">
                                    <Form.Label column xs={6}>Per Day Billing Rate</Form.Label>
                                    <Col xs={4}>
                                        <InputGroup className="mb-2">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Field
                                                name={'daily_billing_rate'}
                                                as={Form.Control}
                                                type="number"
                                                min={0}
                                                step={0.01}
                                                placeholder={'60.00'}
                                            />
                                            <ErrorMessage name={'daily_billing_rate'} component={'div'} className={'text-danger'}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="weekly_billing_rate">
                                    <Form.Label column xs={6}>Per Week Billing Rate</Form.Label>
                                    <Col xs={4}>
                                        <InputGroup className="mb-2">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Field
                                                name={'weekly_billing_rate'}
                                                as={Form.Control}
                                                type="number"
                                                min={0}
                                                step={0.01}
                                                placeholder={'40.00'}
                                            />
                                            <ErrorMessage name={'weekly_billing_rate'} component={'div'} className={'text-danger'}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="monthly_billing_rate">
                                    <Form.Label column xs={6}>Per Month Billing Rate</Form.Label>
                                    <Col xs={4}>
                                        <InputGroup className="mb-2">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Field
                                                name={'monthly_billing_rate'}
                                                as={Form.Control}
                                                type="number"
                                                min={0}
                                                step={0.01}
                                                placeholder={'20.00'}
                                            />
                                            <ErrorMessage name={'monthly_billing_rate'} component={'div'} className={'text-danger'}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            </Row>
                            <Row className={'justify-content-center'} xs={5}>
                                <Button type={"submit"} disabled={formik.isSubmitting}>Submit</Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Row>
        </Container>
    );
}
export default CreateResource