import React from "react";
import Container from "react-bootstrap/Container";
import CreateEmployee from "../CreateEmployee";
import {ParentContainerStyle} from "../../types";

const CreateEventPage: React.FC = () => {
    return(
        <Container fluid style={ParentContainerStyle}>
            <CreateEmployee/>
        </Container>
    )
}


export default CreateEventPage