import {FilterFn} from "@tanstack/react-table";

// Custom filter functions for tables with start/end date values
export const startDateFilter: FilterFn<any> = (
    row,
    columnId: string,
    filterValue: Date
) => {
    return new Date(row.original[(columnId)]) >= filterValue
}

export const endDateFilter: FilterFn<any> = (
    row,
    columnId: string,
    filterValue: Date
) => {
    return new Date(row.original[(columnId)]) <= filterValue
}