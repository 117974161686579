// noinspection SpellCheckingInspection

export interface Client {
    id: string;
    client_name: string;
    client_po: string;
    contact: PartialContact;
    address: PartialAddress;
}

export type PartialClient = Partial<Client>

export interface Contact {
    id: string
    contact_name: string; //client name
    contact_phone_number: string; //valid phone number
    contact_phone_extension?:string;
    contact_email?:string; //valid email address
}

export type PartialContact = Partial<Contact>

export interface Address  {
    id: number;
    label?: string
    unit?: string;
    building?: string;
    street: string;
    city: string;
    province: string;
    postal_code: string;
    country: string;
}

export type PartialAddress = Partial<Address>

export function formatAddress(address: Address): string{

    const addressUnit = address && address.unit? `Unit ${address.unit}`:null
    const addressBuilding = address && address.building? `Building ${address.building}`: null
    return `${addressBuilding? addressBuilding:''} ${addressUnit? addressUnit:''}, ${address.street} ${address.city}, ${address.province} ${address?.postal_code}, ${address.country}`
    /*
    Old logic:
        return `${addressUnit? addressUnit:''}${addressBuilding? addressBuilding:''}${streetLine}${address?.postal_code}`
     */
}

export function getAddressLink(address: Address): string {
    return `https://www.google.com/maps?q=${encodeURI(formatAddress(address))}`
}

export interface Inventory {
    id: number;
    quantity: number;
    address: Address;
    resource: Resource;
}

export type PartialInventory = Partial<Inventory>

export interface Resource {
    id: string
    soft_hold: number
    hard_hold: number
    resource_name: string
    type: ResourceType
    description: string
    unit: string
    unit_billing_rate: number
    hourly_billing_rate: number
    daily_billing_rate: number
    weekly_billing_rate: number
    monthly_billing_rate: number
    stock: number
    minimum_stock: number
    events: string[]
}

export type ResourceType = 'equipment' | 'tool' | 'supplies' | 'employee' | 'vehicle'

export const ResourceTypeArray = ['equipment' , 'tool' , 'supplies' , 'employee' ,'vehicle']

export type PartialResource = Partial<Resource>

export interface EventResourceUse {
    id: string
    supplies_consumed: number
    number_reserved: number
    duration: number
    unit: ChargeRateUnitType // Rate type -> hour, week, day, etc
    resource: string | Resource
    event: string | WorkEvent
    rate_override_rate: number
}

export type PartialEventResourceUse = Partial<EventResourceUse>

export const getDefaultBillingRate = (resource: Resource, unit: ChargeRateUnitType) => {
    if (unit === "day") {
        return resource.daily_billing_rate
    } else if (unit === "hour") {
        return resource.hourly_billing_rate
    } else if (unit === "unit") {
        return resource.unit_billing_rate
    } else if (unit === "weekly") {
        return resource.weekly_billing_rate
    } else if (unit === "monthly") {
        return resource.monthly_billing_rate
    } else if (unit === "no charge rate") {
        return 0
    }
}

export const getDurationFromUnit = (unit: ChargeRateUnitType | undefined, start:string, end: string) => {
    const start_date = new Date(start)
    const end_date = new Date(end)
    const timeInMS = end_date.getTime() - start_date.getTime()
    switch(unit){
        case "day":
            return (timeInMS) / (1000 * 60 * 60 * 24)
        case "hour":
            return (timeInMS) / (1000 * 60 * 60)
        case "weekly":
            return (timeInMS) / (1000 * 60 * 60 * 24 * 7)
        case "monthly":
            // month = 30 days. Update if needed.
            return (timeInMS) / (1000 * 60 * 60 * 24 * 30)
        default:
            return 1
    }
}

export type ChargeRateUnitType = 'day' | 'hour' | 'unit' | 'weekly' | 'monthly' | 'no charge rate'

export const ChargeRateUnitArray = ['unit', 'hour','day' , 'weekly' , 'monthly' , 'no charge rate']

export interface WorkEvent {
    id: string,
    timesheetgeneral_set?: TimeSheet[],
    employeeuser_set?: User[],
    vehicle_set?: string[]
    event_name: string,
    event_description?: string,
    event_notes?: string,
    start_date: string,
    end_date: string,
    status: string | WorkEventStatus,
    work_order?: string | WorkOrder,
    quote?: string | Quote,
    invoice?: any | undefined,
    origin_address: Address,
    destination_address: Address,
    supervisor?: User,
    event_cost: number
    jobsite_client: string;
}

export interface FullWorkEvent {
    WorkEvent : WorkEvent,
    AssignedEmployeeList : string[],
    AssignedResourceList: EventResourceUse[],
    TimeSheetList : TimeSheet[],
    DriverTimeSheetList : DriverTimeSheet[]
}

export interface EventEmployeeAssignment{
    id?: string
    user: string
    event: string
}

export type PartialWorkEvent = Partial<WorkEvent>

export const WorkEventStatusArray = ['open', 'closed', 'in progress', 'deleted']

export interface WorkOrder extends QuoteWorkOrderCommon{
    workorder_status: WorkOrderStatus;
    start_date: string;
    end_date: string;
    safety_data_sheet: string;
    elevator_schedule: string;
    email_users: User[]; // Fk to emp table
    message_users: User[]; // Fk to emp table
}


export enum  WorkOrderStatus {
        open = "open",
        closed = "closed",
        activated = "active",
        complete = "job complete invoiced",
        deleted = "deleted",
        milestone = "milestone complete invoiced"
}
export enum WorkOrderActions {
    open = "open",
    save = "workorder_save",
    closed = "workorder_close",
    activated = "workorder_activate",
    invoice = "workorder_job_invoice",
    milestone = "workorder_milestone_invoice"
}

export const WorkOrderAndEventStatusArray = ["open for editing", "closed", "workorder activated", "job complete invoiced", "milestone complete invoiced", 'deleted']

export interface QuoteWorkOrderCommon {
    id: string;
    billing_client: string,
    jobsite_client: string,
    internal_feedback: string,
    last_change_time: string;
    last_change_by_user: User;
    jobtype_set: JobType[] | string[];
    job_notes: string;
    revision_number: number
    tools_required: string;
    special_skillsets: string;
    speciality_equipment_required: string;
    consumables_notes: string;
    email_users: User[]; // Fk to emp table
    message_users: User[]; // Fk to emp table
}

export interface Quote extends QuoteWorkOrderCommon{
    quoted_number: number,
    quote_status: QuoteStatus,
    employeeuser_set: User[],
    job_request_method: JobRequestMethod,
    presented_by: User
}

export interface JobType {
    id:string,
    name:string
}
export type PartialJobType = Partial<JobType>;

export interface UserQuote {
    id: string,
    email_user: boolean,
    message_user: boolean,
    user: string,
    quote: string
}


export interface QuoteBrief extends  QuoteWorkOrderCommon {
    billing_client: string, //FK to client table
    jobsite_client: string, //Fk to client table
    quoted_number: number,
    quote_status: QuoteStatus,
    job_request_method: JobRequestMethod,
    requested_events: [],
    revision_number: number,
    presented_by: string // FK to user table
}

export enum WorkEventStatus {
    none = "",
    opened = "open",
    closed = "closed",
    in_progress = "in progress",
    deleted = 'deleted'
}



export enum QuoteStatus {
    none = "",
    opened = "open",
    pushed = "pushed",
    submitted = "submitted",
    projectChangeOrder = "pco",
    accepted = "accepted",
    revision = "revision",
    deleted = "deleted",
    rejected= "rejected"
}

export enum QuoteAction {
    push = "push_quote",
    accept = "quote_accept",
    pco = "quote_pco",
    revision = "quote_revision",
    save = "quote_save",
    submit = "quote_submit",
    reject = "reject_quote",
    re_open = "quote_reopen"
}

export enum UserRoles {
    management = "Management",
    sales = "Sales",
    ownership = "Ownership_Group",
    officeAdmin = "Office_Admin",
    dispatch = "Dispatch",
    supervisor = 'Supervisor',
    driver = 'Driver',
    labour = 'Pusher'
}

export enum JobRequestMethod {
    none = '',
    email = "email",
    phone = "phone",
    text = "text",
    in_person = "in person"
}

export type PartialQuote = Partial<Quote>;

export type PartialQuoteBrief = Partial<QuoteBrief>;

export enum AvailableMoveInTimes {
    None = '',
    days = "Weekdays (08-16:30)",
    evenings = "Evenings (16:30-08:00",
    weekends = "Weekends (Friday 16:30 - Monday 08:00)"
}

export interface TimeSheet {
    id: string | number,
    employee: PartialUser
    event: PartialWorkEvent
    status: string
    start_date: string;
    end_date: string;
    shift_note: string;
}

export type PartialTimeSheet = Partial<TimeSheet>;

export interface DriverTimeSheet extends TimeSheet {
    vehicle: Vehicle,
    route_start_date: string,
    drive_time_to_location: string,
    drive_time_from_location: string,
    off_truck_time: string,
    pre_job_inspection_odometer: string,
    //Pre-Job Inspection Booleans
    pre_job_inspection_noise: boolean,
    pre_job_inspection_fuel_system: boolean,
    pre_job_inspection_oil: boolean,
    pre_job_inspection_antifreeze: boolean,
    pre_job_inspection_power_steering: boolean,
    pre_job_inspection_windshield_washer: boolean,
    pre_job_inspection_brake: boolean,
    pre_job_inspection_leaks_underneath_vehicle: InspectionVehicleLeaks,
    pre_job_inspection_tires_proper_inflation: boolean,
    pre_job_inspection_tires_adaquate_tread: boolean,
    pre_job_inspection_temperature: boolean,
    pre_job_inspection_dashboard_warning_light: boolean,
    pre_job_inspection_headlights: boolean,
    pre_job_inspection_brakelights: boolean,
    pre_job_inspection_turnlights: boolean,
    pre_job_inspection_hazzardlights: boolean,
    pre_job_inspection_reflectors: boolean,
    pre_job_inspection_fireextinguisher: boolean,
    pre_job_inspection_firstaidkit: boolean,
    pre_job_inspection_reflectivetriangles: boolean,
    pre_job_inspection_air_system_inspected_and_bled: boolean,
    pre_job_inspection_frame_and_suspension_system: boolean,
    pre_job_inspection_emergency_contact_info_upto_date: boolean,
    pre_job_inspection_two_way_radio_in_working_order: boolean,
    pre_job_inspection_seat_belts: boolean,
    pre_job_inspection_windshield_wipers: boolean,
    pre_job_inspection_heater_and_defroster: boolean,
    pre_job_inspection_brakes_including_parking_brake: boolean,
    pre_job_inspection_mirrors: boolean,
    pre_job_inspection_windshield_and_perimeter_windows: boolean,
    pre_job_inspection_horn: boolean,
    pre_job_inspection_exhaust_system: boolean,
    pre_job_safety_standard_truck_safety_discussed: boolean,
    pre_job_safety_standard_liftgate_operation_discussed: boolean,
    pre_job_safety_proper_ppe_worn: boolean,
    pre_job_safety_standard_loading_dock_and_building_discussed: boolean,
    pre_job_safety_standard_equipment_and_secure_strapment: boolean,
    pre_job_inspection_vehicle_condition_following_inspection: InspectionVehicleCondition,
    // Comments
    pre_job_inspection_comments: string,
    pre_job_safety_comments: string,
}

export type PartialDriverTimeSheet = Partial<DriverTimeSheet>

export type InspectionVehicleLeaks = 'oil' | 'other' | 'none'

export const InspectionVehicleLeakArray = ['oil','other','none']

export type InspectionVehicleCondition = 'acceptable' | 'requires attention' | 'requires immediate attention'

export const InspectionVehicleConditionArray= ['acceptable', 'requires attention', 'requires immediate attention']

export const InitialDriverTimesheetObject= {
    start_date: '',
    end_date: '',
    shift_note: '',
    route_start_date: '',
    drive_time_to_location: '00:00',
    drive_time_from_location: '00:00',
    off_truck_time: '00:00',
    pre_job_inspection_odometer: '',
    pre_job_inspection_noise: false,
    pre_job_inspection_fuel_system: false,
    pre_job_inspection_oil: false,
    pre_job_inspection_antifreeze: false,
    pre_job_inspection_power_steering: false,
    pre_job_inspection_windshield_washer: false,
    pre_job_inspection_brake: false,
    pre_job_inspection_leaks_underneath_vehicle: 'none',
    pre_job_inspection_tires_proper_inflation: false,
    pre_job_inspection_tires_adaquate_tread: false,
    pre_job_inspection_temperature: false,
    pre_job_inspection_dashboard_warning_light: false,
    pre_job_inspection_headlights: false,
    pre_job_inspection_brakelights: false,
    pre_job_inspection_turnlights: false,
    pre_job_inspection_hazzardlights: false,
    pre_job_inspection_reflectors: false,
    pre_job_inspection_fireextinguisher: false,
    pre_job_inspection_firstaidkit: false,
    pre_job_inspection_reflectivetriangles: false,
    pre_job_inspection_air_system_inspected_and_bled: false,
    pre_job_inspection_frame_and_suspension_system: false,
    pre_job_inspection_emergency_contact_info_upto_date: false,
    pre_job_inspection_two_way_radio_in_working_order: false,
    pre_job_inspection_seat_belts: false,
    pre_job_inspection_windshield_wipers: false,
    pre_job_inspection_heater_and_defroster: false,
    pre_job_inspection_brakes_including_parking_brake: false,
    pre_job_inspection_mirrors: false,
    pre_job_inspection_windshield_and_perimeter_windows: false,
    pre_job_inspection_horn: false,
    pre_job_inspection_exhaust_system: false,
    pre_job_safety_standard_truck_safety_discussed: false,
    pre_job_safety_standard_liftgate_operation_discussed: false,
    pre_job_safety_proper_ppe_worn: false,
    pre_job_safety_standard_loading_dock_and_building_discussed: false,
    pre_job_safety_standard_equipment_and_secure_strapment: false,
    pre_job_inspection_vehicle_condition_following_inspection: 'acceptable',
    pre_job_inspection_comments: '',
    pre_job_safety_comments: '',
}

export interface Vehicle {
    id: string,
    make: string,
    model: string,
    notes?: string,
    vin?: string,
    events? : PartialWorkEvent[]
}

export type PartialVehicle = Partial<Vehicle>

export type User = {
    id: string;
    password?: string;
    last_login: string;
    is_superuser: boolean;
    username: string;
    email: string;
    is_staff: boolean;
    is_active: boolean;
    date_joined: string;
    first_name: string;
    last_name: string;
    mobile_number: string;
    emergency_contact_name: string;
    emergency_mobile_number: string;
    experience_status: string;
    availability: string;
    groups: UserGroup[] | string[]; // Assuming it's an array of strings
    user_permissions: string[]; // Assuming it's an array of strings
    events: WorkEvent[];
    billing_rate: number;
}

export type PartialUser = Partial<User>

export interface UserGroup {
    id: string
    name: string
    permissions: string[]
}

export const LOGIN = 'LOGIN';

export const LOGOUT = 'LOGOUT';

export interface LoggedInUser {
    id: string;
    username: string
    roles: string[]
}

export interface RouteRedirect {
    redirectTo: string
    message: string
}


// CSS
export const ParentContainerStyle = {
    padding: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0.5rem 1rem rgba(0,0,0,0.1)',
    width: '80%'
}


export interface ActionResponse {
    success: boolean;
    smsNotifyStatus: boolean;
    emailNotifyStatus: boolean;
    // Add any other properties if needed
}


type ResponseStatus = "resolved" | "rejected";

export interface FileStorage  {
    id: string;
    file: string;
    quote_form: number;
    workorder_form: number | null;
    InvoiceForm_form: number | null;
}


export interface FileStorageResponse  {
    result: FileStorage;
    status: ResponseStatus;
}