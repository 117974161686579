import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import StackedContentContainer from "../ContentContainer";
import React, {useState} from "react";
import {login} from "../../api/api";
import {Alert} from "react-bootstrap";
import {useDispatch} from "react-redux";
import { useNavigate } from 'react-router-dom'
import {setUser} from "../../redux/actions/userActions";



function Login() {

    const [validated, setValidated] = useState(false);
    const [error, setError] = useState<String | null>(null)
    const [username, setUsername] = useState(''); // State for email
    const [password, setPassword] = useState(''); // State for password
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            return
        }
        setValidated(true);
        try {
            const results = await login(username, password)
            if (results != null && results.length > 1) {
                // results = [user-id, groups[]]
                dispatch(setUser(username, results[0], results[1]))
                navigate('/home')
            }
        } catch (error) {
            console.error('Invalid Credentials, Please try again.', error)
            setError(`${error}`)
            // dispatch(loginFailure('Invalid Credentials, Please try again.'))
        }
    };

    return (
        <StackedContentContainer title={"Login"}>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        required
                    />
                    <Form.Control.Feedback type="invalid">Please enter a valid username</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                    />
                    <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </StackedContentContainer>
    );
}

export default Login;