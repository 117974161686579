import '../types';
import {
    Client,
    WorkEvent,
    PartialTimeSheet,
    RouteRedirect,
    TimeSheet,
    User,
    WorkOrder,
    UserGroup,
    PartialUser,
    PartialWorkEvent,
    Quote,
    QuoteBrief,
    PartialQuote,
    PartialAddress,
    PartialClient,
    Contact,
    Address,
    PartialContact,
    EventEmployeeAssignment,
    Resource,
    EventResourceUse,
    PartialEventResourceUse,
    PartialResource,
    Vehicle,
    PartialDriverTimeSheet,
    DriverTimeSheet,
    PartialVehicle,
    FileStorage,
    UserQuote, PartialInventory, JobType, PartialJobType
} from "../types";
import {deleteObject, fetch, fetchFile, postAction, postData, update, uploadFile, uploadFileUpdate} from "./api";

async function handleFetch<T>(endpoint: string, id?:string, filters?:string): Promise< T[] | T | RouteRedirect> {
    try {
        const response = await fetch(endpoint, id, filters);
        return response !== null ? response : null;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
}

export async function getTimesheets(id?:string, filters?:string): Promise<TimeSheet[] | RouteRedirect | TimeSheet>{
     return await handleFetch('generaltimesheet', id? id:undefined, filters? filters:undefined)
}
export async function getDriverTimesheets(id?:string, filters?:string): Promise<DriverTimeSheet[] | DriverTimeSheet | RouteRedirect>{
    return await handleFetch('drivertimesheet', id? id:undefined, filters? filters:undefined )
}

export async function getTimesheetCSV(id?:string, filters?: string) {
    return await handleFetch('generaltimesheet-csv',id? id:undefined, filters? filters:undefined)
}

export async function getWorkOrders(id?: string, filters?: string): Promise<WorkOrder[] | RouteRedirect | WorkOrder> {
    return await handleFetch('workorder', id? id:undefined, filters? filters:undefined)
}

export async function getClients(id?: string, filters?: string): Promise<Client[] | RouteRedirect | Client> {
    return await handleFetch('client', id? id:undefined, filters? filters:undefined)
}

export async function getEvents(id?: string, filters?: string): Promise<WorkEvent[] | RouteRedirect | WorkEvent> {
    return await handleFetch('event', id? id:undefined, filters? filters:undefined)
}

export async function getJobTypes(id?: string, filters?: string): Promise<JobType[] | RouteRedirect | JobType> {
    return await handleFetch('job-type', id? id:undefined, filters? filters:undefined)
}

export async function getQuotes(id?: string, filters?: string): Promise<Quote[] | RouteRedirect | Quote> {
    return await handleFetch('quote', id? id:undefined, filters? filters:undefined)
}

export async function getQuotesBrief(id?: string, filters?: string): Promise<QuoteBrief[] | RouteRedirect | QuoteBrief> {
    return await handleFetch('quote-brief', id? id:undefined, filters? filters:undefined)
}

export async function getUsers(id?: string, filters?: string): Promise<User[] | RouteRedirect | User>{
    return await handleFetch('users', id? id:undefined, filters? filters:undefined)
}
export async function getUsersEvents (filters?: string): Promise<any[] | RouteRedirect | any>{
    return await handleFetch('event-user', undefined, filters? filters:undefined )
}



export async function getGroups(id?: string, filters?: string): Promise<UserGroup[] | UserGroup | RouteRedirect>{
    return await handleFetch('groups', id? id:undefined, filters? filters:undefined)
}

export async function getContacts(id?: string, filters?: string): Promise<Contact[] | Contact | RouteRedirect>{
    return await handleFetch('contact', id? id:undefined, filters? filters:undefined)
}

export async function getAddresses(id?: string, filters?: string): Promise<Address[] | Address | RouteRedirect>{
    return await handleFetch('address', id? id:undefined, filters? filters:undefined)
}

export async function getResources(id?: string, filters?: string): Promise<Resource[] | Resource | RouteRedirect >{
    return await handleFetch('resource', id? id:undefined, filters? filters:undefined)
}

export async function getResourcesBrief(id?: string, filters?: string): Promise<Resource[] | Resource | RouteRedirect >{
    return await handleFetch('resource-brief', id? id:undefined, filters? filters:undefined)
}

export async function getResourceUses(id?:string, filters?:string): Promise<EventResourceUse[] | EventResourceUse | RouteRedirect>{
    return await handleFetch('resource-event', id, filters)
}
//todo: Update when endpoint is ready
export async function getInventory(id?:string, filters?:string): Promise<any[] | any | RouteRedirect>{
    return await handleFetch('Inventory', id, filters)
}

export async function getVehicles(id?:string, filters?:string): Promise<Vehicle[] | Vehicle | RouteRedirect>{
    return await handleFetch('vehicle', id, filters)
}

export async function getFiles(id?:string, filters?:string): Promise<FileStorage[] | FileStorage | RouteRedirect> {
    return await handleFetch('form-files', id, filters) }

export async function getUserQuotes(id?: string, filters?:string): Promise<UserQuote[] | UserQuote | RouteRedirect> {
    return await handleFetch('user-quote',id,filters)
}

export async function getWorkOrderPDF(id?: string)  {
     fetchFile('workorder', id ? id : undefined, `?format=latex`);
}


export async function getQuotePDF(id?: string)  {
    fetchFile('quote', id ? id : undefined, `?format=latex`);
}
// POST REQUESTS:

export async function postTimesheet(data: PartialTimeSheet) {
    return await postData('generaltimesheet-brief',data)
}

export async function postDriverTimesheet(data: PartialDriverTimeSheet) {
    return await postData('drivertimesheet-brief',data)
}

export async function postUser(data: PartialUser){
    // Billing rate currently handled via resources, makes this field unnecessary at the moment
    data.billing_rate = 0.00

    return await postData('users-brief', data)
}

export async function postEvent (data:PartialWorkEvent, assignedEmployees?: string[], assignedResources?: PartialEventResourceUse[]){
    return await postData('event-brief', data).then(
        (results)=>{
            if(results && !Array.isArray(results)){
                const id = results.data.id
                console.log(assignedEmployees)
                if (assignedEmployees && assignedEmployees.length > 0) {
                    const employeeAssignments = assignedEmployees.map(value =>
                        ({
                            user: value,
                            event: id
                        })
                    )
                    postEventEmployee(employeeAssignments)
                }
                console.log('assigned resources', assignedResources)
                if (assignedResources && assignedResources.length > 0){

                    postEventResourceUse(assignedResources, id)
                }
            }
            return results
        }
    )
}

export async function postEventEmployee(data: EventEmployeeAssignment[]){
    // With bulk updates, this could be easier
    let results: any[] = []
    for await (const item of data){
        results = [...results, await postData('event-user', item)]
    }
    return results

}

export async function postEventResourceUse(data: PartialEventResourceUse[], eventId:string){
    let results: any[] = []
    for await (const item of data){
        let values:any = item
        values.event = eventId

        if(item.resource && item.unit && item.number_reserved){
            values.resource = (item.resource as Resource).id
            console.log(values.resource)
            console.log('values:',values,'item:',item)
            results = [...results, await postData('resource-event-brief', item)]
        }
    }
    return results
}

export async function postResource(data: PartialResource){
    return await postData('resource', data)
}



export async function postQuote (data: PartialQuote, eventData?: Array<[Partial<WorkEvent>, PartialEventResourceUse[]]>
){
    return await postData('quote', data).then(
        async (results) => {
            if (results && !Array.isArray(results)) {
                const id = results.data.id
                if (eventData && eventData.length > 0) {
                    for await (const workEvent of eventData) {
                        if (workEvent[0].quote === undefined) {
                            let event_brief: any = workEvent
                            if (workEvent[0].destination_address) event_brief[0].destination_address = workEvent[0].destination_address.id
                            if (workEvent[0].origin_address) event_brief[0].origin_address = workEvent[0].origin_address?.id
                            event_brief[0].quote = id
                            await postEvent(event_brief[0],undefined,event_brief[1])
                        }

                    }
                }
            }
            return results
        })
}

export async function postAddress (data: PartialAddress) {
    return await postData('address-brief', data)
}

export async function postClient(data: PartialClient){
    return await postData('client-brief', data)
}

export async function postContact(data: PartialContact){
    return await postData('contact-brief', data)
}

export async function postVehicle(data: PartialVehicle){
    return await postData('vehicle-brief', data)
}

export async function postJobType(data: PartialJobType){
    return await postData('job-type', data)
}


// ACTIONS

export async function setEventState(id: string, state:string, employeeuser_set:any) {
    return await update('event', id, {status: state, employeeuser_set:employeeuser_set})
}

export async function postQuoteAction(id: string, action: string) {
    return await postAction('quote',id,action)
}

export async function postWOAction(id: string, action: string) {
    return await postAction('workorder',id,action)
}

export async function getQuotePdf(id:string) {
    return await handleFetch('quote', id, '?format=latex')
}

export async function getWorkOrderPdf(id:string) {
    return await handleFetch('workorder', id, '?format=latex')
}

export async function getVehicleInspectionSheet(id:string) {
    return await handleFetch('drivertimesheet', id, '?format=latex')
}


// UPDATE REQUESTS

export async function updateQuote(id: string, data: PartialQuote, eventData: Array<[Partial<WorkEvent>, PartialEventResourceUse[]]>
) {

    for await (const workEvent of eventData ) {
        if(workEvent[0].quote === undefined) {
            let brief_event: any = workEvent
            brief_event[0].quote = data.id;
            brief_event[0].origin_address = workEvent[0].origin_address ? workEvent[0].origin_address.id : '';
            brief_event[0].destination_address = workEvent[0].destination_address ? workEvent[0].destination_address.id : '';
            await postEvent(brief_event[0],undefined,brief_event[1]);
        }
    }

    return await update('quote-brief', id, data)
}

export async function updateEvent(id: string, data:PartialWorkEvent, assignedEmployees?: string[], assignedResources?: PartialEventResourceUse[]){
    let values: any = data

    let employeeAssignments: any
    if(assignedEmployees){
        employeeAssignments = assignedEmployees.map(value =>
            ({
                user: value,
                event: id
            })
        )
    }
    return await update('event-brief',id , values).then(
        async (results) => {
            if (results && !Array.isArray(results)) {
                if(employeeAssignments){
                    const EmployeeAssociations = await handleFetch('event-user', undefined, `?event=${id}`)
                    for await (const item of (EmployeeAssociations as EventEmployeeAssignment[])){
                        await deleteObject('event-user', item.id)
                    }
                    await postEventEmployee(employeeAssignments)
                }
                if(assignedResources){
                    const ResourceAssociations = await handleFetch('resource-event', undefined, `?event=${id}`)
                    for await (const item of (ResourceAssociations as EventResourceUse[])){
                        await deleteObject('resource-event', item.id)
                    }
                    await postEventResourceUse(assignedResources, id)
                }
            }
            return results
        }
    )
}

export async function updateUser(id: string, data: PartialUser){
    // data.groups? (data.groups = data.groups.map((obj:any) => obj.id)):(data.groups=[]) // Is this still valid?
    return await update('users-brief',id , data)
}

export async function updateAddress(id: string, data: PartialAddress){
    return await update('address-brief',id , data)
}

export async function updateClient(id: string, data: PartialClient){
    return await update('client-brief', id, data)
}

export async function updateContact(id: string, data:PartialContact){
    return await update('contact-brief', id, data)
}

export async function updateTimeEntry(id: string, data:PartialTimeSheet) {
    return await update('generaltimesheet-brief',id, data)
}

export async function updateDriverTimeEntry(id:string, data:PartialDriverTimeSheet) {
    return await update('drivertimesheet-brief', id,data)
}

export async function updateResource(id: string, data: PartialResource) {
    return await update('resource-brief', id, data)
}

export async function uploadWorkOrderFiles(id:string,formData: FormData) {
    return await  uploadFileUpdate('workorder',id,formData)
}

export async function updateWorkOrder(id: string, data: Partial<WorkOrder>) {
    return await update('workorder',id,data)
}

export async function updateResourceUse(id: string, data: PartialEventResourceUse) {
    return await update ('resource-event', id, data)
}

export async function updateInventoryCount(id: string, data: PartialInventory){
    return await update('Inventory-brief',id , data)
}

export async function postFile( formData: FormData) {
    return await uploadFile('form-files-brief',formData)
}
export async function updateVehicle(id: string, data: PartialVehicle) {
    return await update('vehicle-brief', id, data)
}
export async function updateJobType(id: string, data: PartialJobType) {
    return await update('job-type', id, data)
}

// DELETION:
export async function deleteEvent(id: string, data: Partial<WorkEvent>) {
    return await update('event-brief', id , {status: "deleted", quote: null, work_order: null })
}

export async function deleteTimeSheet(id: string, data?: TimeSheet) {
    return await deleteObject('generaltimesheet', id)
}

export async function deleteDriverTimeSheet(id: string, data: TimeSheet) {
    return await update('drivertimesheet', id ,{status: "deleted"})
}

export async function deleteUser(id: string, data: User) {
    return await update('users', id ,{is_active: false})
}

export async function deleteQuote(id: string, data: Quote) {
    return await deleteObject('quote', id)
}

export async function deleteWorkOrder(id: string) {
    return await deleteObject('workorder', id)
}

export async function deleteClient(id: string){
    return deleteObject('client',id)
}

export async function deleteContact(id: string){
    return deleteObject('contact', id)
}

export async function deleteAddress(id:string) {
    return deleteObject('address', id)
}


export async function deleteFile(id:string) {
    return deleteObject('form-files', id)
}

export async function deleteResourceUses(id:string){
    // User resource notify may also need to be deleted with this...?
    let objectsToDelete = getResourceUses(undefined, `?resource=${id}`)
    let deleteResults:any[] = []
    if (Array.isArray(objectsToDelete)){
        for await (const item of objectsToDelete){
            deleteResults = [...deleteResults, await deleteObject('resource-event', item.id)]
        }
    }
    return deleteResults
}

export async function deleteResource(id:string){
    await deleteResourceUses(id)
    return await deleteObject('resource', id)

}

export async function deleteResourceUse(id: string){
    return await deleteObject('resource-event', id)
}

export async function deleteVehicle(id:string){
    return await deleteObject('vehicle', id)
}
export async function deleteJobType(id:string){
    return await deleteObject('job-type', id)
}
