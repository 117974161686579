import React, {useState} from 'react';
import {Row} from "@tanstack/react-table";
import {Button, ButtonGroup, Spinner} from "react-bootstrap";
import {WorkEvent, WorkEventStatus} from "../types";
import {setEventState} from "../api/dataService";
import {handlePostError} from "../util/Helpers";

interface EventActionButtonProps {
    event: WorkEvent,
    onUpdate?: (updatedObject: WorkEvent, previousObject: WorkEvent) => void,

}

const EventActionButtons: React.FC<EventActionButtonProps> = (props: EventActionButtonProps) => {

    const [isLoading, setLoading] = useState<boolean>(false)

    const handleEventAction = async (currentEvent: WorkEvent, newStatus: WorkEventStatus) => {

        try {
            setLoading(true)

            const response = await setEventState(currentEvent.id,
                newStatus,
                currentEvent.employeeuser_set
            )

            if(props.onUpdate) {
                props.onUpdate(response, props.event)
            }

            if (response && !Array.isArray(response)) {
                setLoading(false)
            }

        } catch(error) {
            console.log(handlePostError(error,'event'))
        }
    }

    let buttonsToRender;

    switch (props.event.status) {
        case WorkEventStatus.opened:
            buttonsToRender = (
                <ButtonGroup className="btn-group-sm">
                    <Button className="btn-success" onClick={() => handleEventAction(props.event,WorkEventStatus.in_progress)}>Activate</Button>
                </ButtonGroup>
            )
            break;

        case WorkEventStatus.in_progress:
            buttonsToRender = (
                <ButtonGroup className="btn-group-sm">
                    <Button className="btn-warning" onClick={() => handleEventAction(props.event, WorkEventStatus.closed)}>Invoice</Button>
                </ButtonGroup>
            )
            break;

        default:
            buttonsToRender = (
                <div>
                </div>
            )


    }

    return isLoading ? <center><Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner></center> : buttonsToRender

}

export default EventActionButtons