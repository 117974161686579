import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import {NavDropdown} from "react-bootstrap";
import {Link, Navigate, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser} from "../api/api";
import {logout} from "../redux/actions/userActions";
import {RootState} from "../redux/reduxTypes";
import {checkIsAllowed} from "../util/Helpers";
import {UserRoles} from "../types";

function Navigation() {
    const user = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    if(user.userid === null){
        return <Navigate to={"/auth/login/"}/>
    }
    const handleLogout = async () => {
        try {
            try {
                const result = await logoutUser()
                console.log(result)
                dispatch(logout())
                navigate('/auth/login/')
            }catch (error) {
                console.error("Error On Logout: ", error)
            }
        }catch (error) {
            console.error('Logout Error', error)
        }

    }
    return (
        <Navbar expand="md" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand as={Link} to="/home">Classic Office</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <NavbarCollapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/home">Home</Nav.Link>
                        {(
                            checkIsAllowed(user.groups,["Ownership_Group","Management","Supervisor","Dispatch","Driver","Office_Admin","Sales"]) && (
                                <Nav.Link as={Link} to="/personaltimesheet">My Events & Time</Nav.Link>
                            )
                        )}

                        {(
                            checkIsAllowed(user.groups,["Ownership_Group","Management","Supervisor","Dispatch","Driver","Office_Admin"]) && (
                                <Nav.Link as={Link} to="/dispatch/">Dispatch Calendar</Nav.Link>
                            ))}
                        {(checkIsAllowed(user.groups, ["Ownership_Group","Management","Sales"])) && (
                                <NavDropdown title={"Quotes"}>
                                    <NavDropdown.Item as={Link} to={'/quotes/'}>Manage Quotes</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/quotes/create/'}>Create Quote</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/quotes/open/'}>Opened Quotes</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/quotes/pushed/'}>Pushed Quotes</NavDropdown.Item>

                                    <NavDropdown.Item as={Link} to={'/quotes/submitted/'}>Submitted Quotes</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/quotes/accepted/'}>Accepted Quotes</NavDropdown.Item>

                                    <NavDropdown.Item as={Link} to={'/quotes/rejected/'}>Rejected Quotes</NavDropdown.Item>

                                </NavDropdown>
                            )}
                        {((checkIsAllowed(user.groups, ["Pusher","Driver","Supervisor","Office_Admin"]) &&
                        !checkIsAllowed(user.groups, ['Ownership_Group', 'Management'])) && (
                            <Nav>
                                <NavDropdown title={"View"}>
                                    {(checkIsAllowed(user.groups,["Driver","Supervisor","Office_Admin"])) && (
                                        <>
                                            <NavDropdown.Item as={Link} to={'/workorders/'}>View Work Orders</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/events/'}>View Events</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/vehicles/'}>View Vehicles</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/resources/'}>View Resources</NavDropdown.Item>
                                        </>
                                    )}
                                    <NavDropdown.Item as={Link} to={'/timesheets/'}>View Timesheets</NavDropdown.Item>
                                    {(checkIsAllowed(user.groups, ['Driver', 'Supervisor'])) && (
                                        <NavDropdown.Item as={Link} to={'/drivertimesheets/'}>View Driver Timesheets</NavDropdown.Item>
                                    )}
                                    {(checkIsAllowed(user.groups, ['Office_Admin'])) && (
                                        <>
                                            <NavDropdown.Item as={Link} to={'/employees/'}>View Employees</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/clients/'}>Manage Clients</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/jobtypes/'}>Manage Job Types</NavDropdown.Item>
                                        </>
                                    )}
                                </NavDropdown>
                                <Nav.Link as={Link} to={'/timesheets/create'}>Manual Time Entry</Nav.Link>
                            </Nav>
                        ))}

                        {(checkIsAllowed(user.groups, ["Ownership_Group","Management","Sales","Dispatch"])) && (
                            <Nav>
                                <NavDropdown title={"Manage"}>
                                    <NavDropdown.Item as={Link} to={'/timesheets/'}>Manage Time Entries</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/drivertimesheets/'}>Manage Driver Time Entries</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/vehicles/'}>Manage Vehicles</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/employees/'}>Manage Employees</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/workorders/'}>Manage Work Orders</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/events/'}>Manage Events</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/inventory/'}>Manage Inventory</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={'/resources/'}>Manage Resources</NavDropdown.Item>
                                    {(checkIsAllowed(user.groups,["Ownership_Group","Management","Sales"])) && (
                                        <>
                                            <NavDropdown.Item as={Link} to={'/quotes/'}>Manage Quotes</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/clients/'}>Manage Clients</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/contacts/'}>Manage Contacts</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/addresses/'}>Manage Addresses</NavDropdown.Item>
                                        </>
                                    )}
                                    {(checkIsAllowed(user.groups, [UserRoles.ownership, UserRoles.management])) && (
                                        <>
                                            <NavDropdown.Item as={Link} to={'/jobtypes/'}>Manage Job Types</NavDropdown.Item>
                                        </>
                                    )}
                                </NavDropdown>
                                <NavDropdown title={"Create"}>
                                    <NavDropdown.Item as={Link} to={'/timesheets/create'}>Create Time Entry</NavDropdown.Item>
                                    {(checkIsAllowed(user.groups,["Ownership_Group","Sales","Management"])) && (
                                        <>
                                            <NavDropdown.Item as={Link} to={'/quotes/create'}>Create New Quote</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/events/create'}>Create New Event</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/addresses/create'}>Create Address</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/clients/create'}>Create Client</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/contacts/create'}>Create Contact</NavDropdown.Item>
                                        </>
                                    )}

                                    {(checkIsAllowed(user.groups, ["Ownership_Group","Management"])) && (
                                        <>
                                            <NavDropdown.Item as={Link} to={'/drivertimesheets/create'}>Create Driver Time Entry</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/employees/create'}>Create Employee</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/resources/create'}>Create Resource</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/vehicles/create'}>Create Vehicle</NavDropdown.Item>
                                        </>
                                    )}

                                </NavDropdown>
                            </Nav>
                        )}
                    </Nav>

                    <Nav>
                        <NavDropdown
                            title={`Logged in as: ${user ? user.username : 'Unknown'}`}
                            id="basic-nav-dropdown"
                            align="end" // Align the dropdown at the bottom
                        >
                            <NavDropdown.Item onClick={() => handleLogout()}>Log Out</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>

                </NavbarCollapse>
            </Container>
        </Navbar>

    );
}

export default Navigation;